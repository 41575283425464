import { PlanoPagamentoParcela } from 'src/tipos'

export enum TiposPrecificacao {
  Precificacao = 'Precificacao',
  Campanha = 'Campanha'
}

export enum TiposPrecificacaoPorExtenso {
  Precificacao = 'Precificação',
  Campanha = 'Campanha'
}

export interface PrecificacaoItem {
  nome: string
  codigo: string
  cpf?: string
  dataInicio: Date
  dataFim: Date
  tipo: TiposPrecificacao
  valorBase?: boolean
  pctDesconto?: number
  planosPagamento?: PlanoPagamentoParcela[]
  cursoId?: Array<string>
  precificacaoBase?: boolean
}

export interface Precificacao {
  itens: Array<PrecificacaoItem>
  quantidade: number
  opcoesPaginacao: {
    pagina: number
    totalRegistros: number
  }
}
