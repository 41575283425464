import React, { useEffect, useMemo, useState } from 'react'

import {
  RotasAcademico,
  RotasApp,
  RotasRelatorios,
  RotasSistema
} from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { RotasProfessor } from 'src/rotas/professor'
import { RotasResponsavelFinanceiro } from 'src/rotas/responsavel-financeiro'
import { Api } from 'src/servicos'
import { Nacionalidade } from 'src/tipos'

import { AutenticacaoContainer, Visao } from '../autenticacao'
import { BarraLateral } from '../barra-lateral'
import {
  IconeHome,
  IconeFinanceiro,
  IconeAcademico,
  IconeSistema,
  IconeTurma,
  IconeRecibo,
  IconeRelogio,
  IconeMeusDocumentos,
  IconeMeusHistoricosDeclaracoes
} from '../icones'
import { Menu } from '../menu'
import { MenuItem } from '../menu/tipos'
import { Container } from './styles'

export const BarraLateralLayout: React.FC = () => {
  const { visao, usuario } = AutenticacaoContainer.useContainer()

  const menuIpgs = {
    Icone: IconeHome,
    Titulo: 'Início',
    Caminho: RotasApp.Academico,
    ComparacaoExata: true
  }

  const menuAluno = {
    Icone: IconeHome,
    Titulo: 'Início',
    Caminho: RotasApp.Aluno,
    ComparacaoExata: true
  }

  const menuProfessor = {
    Icone: IconeHome,
    Titulo: 'Início',
    Caminho: RotasApp.Professor,
    ComparacaoExata: true
  }

  const menuParceiro = {
    Icone: IconeHome,
    Titulo: 'Início',
    Caminho: RotasApp.Academico,
    ComparacaoExata: true
  }

  const menuResponsavelFinanceiro = {
    Icone: IconeHome,
    Titulo: 'Início',
    Caminho: RotasApp.ResponsavelFinanceiro,
    ComparacaoExata: true
  }

  const documentosEHistoricos = useMemo(() => {
    if (usuario.matriculaPos) {
      return [
        {
          Icone: IconeMeusDocumentos,
          Titulo: 'Documentos',
          IconeComStroke: true,
          Visao: Visao.Aluno,
          Caminho:
            usuario.nacionalidade === Nacionalidade[0]
              ? RotasAluno.MeusDocumentosBrasileiro
              : RotasAluno.MeusDocumentosEstrangeiro,
          ComparacaoExata: true
        },
        {
          Icone: IconeMeusHistoricosDeclaracoes,
          IconeComStroke: true,
          Titulo: 'Históricos e Declarações',
          Visao: Visao.Aluno,
          Caminho: RotasAluno.HistoricoCursos,
          ComparacaoExata: true
        }
      ]
    } else {
      return [
        {
          Icone: IconeMeusHistoricosDeclaracoes,
          IconeComStroke: true,
          Titulo: 'Históricos e Declarações',
          Visao: Visao.Aluno,
          Caminho: RotasAluno.HistoricoCursos,
          ComparacaoExata: true
        }
      ]
    }
  }, [usuario.matriculaPos])

  const itens = useMemo(
    () => [
      visao === Visao.IPGS && menuIpgs,
      visao === Visao.Aluno && menuAluno,
      visao === Visao.Professor && menuProfessor,
      visao === Visao.Parceiro && menuParceiro,
      visao === Visao.ResponsavelFinanceiro && menuResponsavelFinanceiro,
      {
        Icone: IconeAcademico,
        Titulo: 'Acadêmico',
        Visao: Visao.IPGS,
        SubItens: [
          {
            Titulo: 'Alunos',
            Caminho: RotasAcademico.Alunos,
            PerfisComAcesso: ['Academico', 'Gerencial', 'Parceiro']
          },
          {
            Titulo: 'Professores',
            Caminho: RotasAcademico.Professores
          },
          {
            Titulo: 'Cursos',
            Caminho: RotasAcademico.Cursos
          },
          {
            Titulo: 'Disciplinas',
            Caminho: RotasAcademico.Disciplinas,
            PerfisComAcesso: ['Academico', 'Gerencial']
          },
          {
            Titulo: 'Turmas',
            Caminho: RotasAcademico.Turmas,
            PerfisComAcesso: ['Comercial', 'Academico', 'Gerencial']
          },
          {
            Titulo: 'Pesquisas de Satisfação',
            Caminho: RotasAcademico.PesquisaSatisfacao,
            PerfisComAcesso: ['Comercial', 'Academico', 'Gerencial']
          }
        ]
      },
      {
        Icone: IconeFinanceiro,
        Titulo: 'Financeiro',
        Visao: Visao.IPGS,
        PerfisComAcesso: ['Comercial', 'Financeiro', 'Gerencial'],
        SubItens: [
          {
            Titulo: 'Cupons',
            Caminho: RotasAcademico.Cupons,
            PerfisComAcesso: ['Comercial', 'Financeiro', 'Gerencial']
          },
          {
            Titulo: 'Parceiros Split',
            Caminho: RotasAcademico.SplitPagamento,
            PerfisComAcesso: ['Financeiro', 'Gerencial']
          },
          {
            Titulo: 'Precificação',
            Caminho: RotasAcademico.Precificacao,
            PerfisComAcesso: ['Financeiro', 'Gerencial']
          }
        ]
      },
      {
        Icone: IconeFinanceiro,
        Titulo: 'Financeiro',
        Visao: Visao.Aluno,
        SubItens: [
          {
            Titulo: 'Painel Financeiro',
            Caminho: RotasAluno.PainelFinanceiro
          }
        ]
      },
      {
        Icone: IconeRecibo,
        Titulo: 'Relatórios',
        Visao: Visao.IPGS,
        SubItens: [
          {
            Titulo: 'Relatórios Acadêmicos',
            Visao: Visao.IPGS,
            ehSubMenu: true,
            SubItens: [
              {
                Titulo: 'Documentos',
                Caminho: RotasRelatorios.DocumentosAluno,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Notas',
                Caminho: RotasRelatorios.Notas,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Envio e Notas de TCC',
                Caminho: RotasRelatorios.EnviosNotasTCC,
                PerfisComAcesso: [
                  'Academico',
                  'Comercial',
                  'Financeiro',
                  'Gerencial'
                ]
              },
              {
                Titulo: 'Finalização',
                Caminho: RotasRelatorios.Finalizacao,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Histórico Escolar',
                Caminho: RotasRelatorios.HistoricoEscolar,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Pesquisa de Satisfação',
                Caminho: RotasRelatorios.PesquisaSatisfacao,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Prorrogação',
                Caminho: RotasRelatorios.Prorrogacao,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              }
            ]
          },
          {
            Titulo: 'Relatórios Financeiros',
            Visao: Visao.IPGS,
            ehSubMenu: true,
            SubItens: [
              {
                Titulo: 'Recorrência',
                Caminho: RotasRelatorios.Recorrencia,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Situação Financeira',
                Caminho: RotasRelatorios.SituacaoFinanceiraAlunos,
                PerfisComAcesso: ['Academico', 'Gerencial']
              },
              {
                Titulo: 'Situação Financeira Geral',
                Caminho: RotasRelatorios.SituacaoFinanceiraAlunosGeral,
                PerfisComAcesso: ['Academico', 'Gerencial']
              }
            ]
          },
          {
            Titulo: 'Relatórios Gerais',
            Visao: Visao.IPGS,
            ehSubMenu: true,
            SubItens: [
              {
                Titulo: 'Cupons de Desconto',
                Caminho: RotasRelatorios.CuponsDesconto,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Cursos Livres',
                Caminho: RotasRelatorios.MatriculadosCursoLivre,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              },
              {
                Titulo: 'Turmas',
                Caminho: RotasRelatorios.MatriculadosTurma,
                PerfisComAcesso: [
                  'Academico',
                  'Gerencial',
                  'Financeiro',
                  'Comercial'
                ]
              }
            ]
          },
          {
            Titulo: 'Relatórios Comercial',
            Visao: Visao.IPGS,
            ehSubMenu: true,
            SubItens: [
              {
                Titulo: 'Relatórios Comercial',
                Caminho: RotasRelatorios.RelatorioComercial,
                PerfisComAcesso: ['Gerencial', 'Financeiro', 'Comercial']
              }
            ]
          }
        ]
      },
      {
        Icone: IconeSistema,
        IconeComStroke: true,
        Titulo: 'Sistema',
        Visao: Visao.IPGS,
        SubItens: [
          {
            Titulo: 'Usuários',
            Caminho: RotasSistema.Usuarios
          }
        ]
      },
      {
        Icone: IconeTurma,
        IconeComStroke: true,
        Titulo: 'Minhas Turmas',
        Visao: Visao.Professor,
        Caminho: RotasAcademico.Turmas,
        ComparacaoExata: true
      },
      ...documentosEHistoricos,
      {
        Icone: IconeMeusDocumentos,
        Titulo: 'Documentos',
        IconeComStroke: true,
        Visao: Visao.Professor,
        Caminho:
          usuario.nacionalidade === Nacionalidade[0]
            ? RotasProfessor.MeusDocumentosBrasileiro
            : RotasProfessor.MeusDocumentosEstrangeiro,
        ComparacaoExata: true
      },
      {
        Icone: IconeAcademico,
        Titulo: 'Acadêmico',
        Visao: Visao.Parceiro,
        SubItens: [
          {
            Titulo: 'Alunos',
            Caminho: RotasAcademico.Alunos,
            PerfisComAcesso: ['Parceiro']
          },
          {
            Titulo: 'Turmas',
            Caminho: RotasAcademico.Turmas,
            PerfisComAcesso: ['Parceiro']
          }
        ]
      },
      {
        Icone: IconeAcademico,
        Titulo: 'Contratos',
        Visao: Visao.Aluno,
        Caminho: RotasAluno.MeusContratos,
        ComparacaoExata: true
      },
      // Removido do menu por tempo indeterminado, até ser implementado mais opções
      // Foi comentado a rota, src/rotas/aluno.tsx
      // {
      //   Icone: IconeBook,
      //   Titulo: 'Requerimentos',
      //   Visao: Visao.Aluno,
      //   Caminho: RotasAluno.Requerimentos,
      //   ComparacaoExata: true
      // },
      {
        Icone: IconeRelogio,
        Titulo: 'Filas de Trabalho',
        Visao: Visao.IPGS,
        Caminho: RotasAcademico.FilasTrabalho,
        ComparacaoExata: true,
        PerfisComAcesso: ['Academico', 'Gerencial', 'Financeiro', 'Comercial']
      },
      {
        Icone: IconeMeusDocumentos,
        Titulo: 'Documentos',
        IconeComStroke: true,
        Visao: Visao.ResponsavelFinanceiro,
        Caminho: RotasResponsavelFinanceiro.Documentos,
        ComparacaoExata: true
      }
    ],
    [visao]
  )

  return (
    <Container>
      <BarraLateral data-testid="barra_lateral">
        <Menu Itens={itens as MenuItem[]} />
      </BarraLateral>
    </Container>
  )
}
