/* eslint-disable no-console */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Api } from 'src/servicos'

import { Container } from './styled'
import { ItemNotificacaoProps } from './tipos'

import { NotificacoesContainer } from '..'

export const ItemNotificacao: React.FC<ItemNotificacaoProps> = ({
  className,
  unread: initialUnread,
  title,
  date,
  description,
  redirecionamento,
  idUsuario,
  marcarComoLida
}) => {
  const { remover } = NotificacoesContainer.useContainer()
  const [unread, setUnread] = useState(initialUnread)

  const removerNotificacao = () => {
    setUnread(false)
    if (title === 'Dúvida Respondida') {
      remover(title)
    }
  }

  const marcaNotificacaoComoLida = async () => {
    marcarComoLida(title)
    await Api.MarcarLidaNotificacoesAluno(idUsuario, title)
    remover(title)
  }

  return (
    <Container className={className} naoLido={unread}>
      {redirecionamento ? (
        <Link to={redirecionamento} onClick={removerNotificacao}>
          <h4>{title}</h4>
          <time>{date}</time>
          <p>{description}</p>
        </Link>
      ) : (
        <>
          <h4>{title}</h4>
          <time>{date}</time>
          <p>{description}</p>
        </>
      )}
      <button onClick={marcaNotificacaoComoLida}>Lido</button>{' '}
    </Container>
  )
}
