import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import { TextArea } from 'src/componentes'
import { sanitizeUtil } from 'src/componentes/utils'
import { v4 } from 'uuid'

import { PerguntaProps } from '../tipos'
import { Container, Enunciado, Conteudo, Erro } from './styles'

export const PerguntaParagrafo: React.FC<PerguntaProps> = ({
  pergunta,
  name
}) => {
  const inputRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement)
  const { fieldName, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container id={pergunta.id} theme={{ ComErro: !!error }}>
      <Enunciado>
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizeUtil(pergunta.texto.replaceAll('<p></p>', '<br />'))
          }}
        />
      </Enunciado>

      <Conteudo>
        <TextArea
          ref={inputRef}
          key={pergunta.id}
          id={`${v4()}`}
          label={'Resposta'}
          name={fieldName}
        />
      </Conteudo>
      <Erro id={`erro-${pergunta.id}`}>{error}</Erro>
    </Container>
  )
}
