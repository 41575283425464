import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ModalIncluirTesteRef,
  ModalTesteProps
} from 'src/paginas/ipgs/cursos/cadastro/componentes/tipos'

import {
  Botao,
  FormUnform,
  MoedaInputUnform,
  NumeroInputUnform,
  Spacer
} from '../../../../../componentes'
import { ModalBase } from '../../../../../componentes/modal-base'
import { PlanoPagamentoParcelas } from '../../../../../tipos'
import { Corpo, ContainerBotoes } from '../styles'

const ModalEditar: React.ForwardRefRenderFunction<
  ModalIncluirTesteRef,
  ModalTesteProps
> = ({ backdrop, acaoPrimaria }, ref) => {
  const [pronto, definirPronto] = useState(false)
  const [aberta, definirAberta] = useState(false)
  const [, definirDados] = useState<PlanoPagamentoParcelas>(null)
  const [
    planoPagamento,
    definirPlanoPagamento
  ] = useState<PlanoPagamentoParcelas>(null)

  const fechar = () => {
    definirPronto(false)
    definirAberta(false)
  }

  const abrir = planoPagamento => {
    definirPlanoPagamento(planoPagamento)
    definirPronto(true)
    definirAberta(true)
  }

  useImperativeHandle<ModalIncluirTesteRef, ModalIncluirTesteRef>(ref, () => ({
    fechar,
    abrir
  }))

  const acaoSucesso = async (dados: PlanoPagamentoParcelas) => {
    const parcelaEditada = {
      ...planoPagamento,
      quantidade: dados.quantidade,
      valorParcela: dados.valorParcela
    }

    definirDados(dados)
    acaoPrimaria(parcelaEditada)
    fechar()
  }

  return (
    <>
      <ModalBase aberta={aberta} backdrop={backdrop}>
        <Corpo>
          <h5 style={{ margin: 'auto' }}> Editar Parcela </h5>
          <Spacer padding="10px 0px" />
          {pronto && (
            <FormUnform
              dadosIniciais={planoPagamento}
              acaoSucesso={acaoSucesso}
            >
              <NumeroInputUnform
                name="quantidade"
                max={9999}
                maxLength={4}
                label="Quantidade de parcelas"
              />
              <MoedaInputUnform name="valorParcela" label="Valor da parcela" />
              <Spacer padding="10px 0px" />
              <ContainerBotoes>
                <Botao
                  type="button"
                  id="btn-cancelar"
                  texto="Cancelar"
                  tema="Secundario"
                  onClick={() => {
                    fechar()
                  }}
                />
                <Botao id="btn-salvar" texto="Salvar" type="submit" />
              </ContainerBotoes>
            </FormUnform>
          )}
        </Corpo>
      </ModalBase>
    </>
  )
}
export const ModalEditarParcela = forwardRef(ModalEditar)
