import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import {
  Botao,
  Botao as FormBotao,
  ContainerAcoes,
  FormRef,
  FormRow,
  FormUnform,
  IconeAdicionar,
  IconeLixeira,
  InputUnform,
  ModalBase,
  ConteudoBotao,
  SelectUnform,
  NumeroInputUnform,
  CheckboxUnform
} from 'src/componentes'
import { focarPrimeiroCampoComErro } from 'src/componentes/funcoes'
import { EditorUnform } from 'src/componentes/unform/editor'
import {
  PerguntasPesquisaAlternativa,
  PerguntasPesquisaSatisfacao,
  TipoPesquisaSatisfacaoPergunta
} from 'src/tipos'
import { v4 } from 'uuid'

import {
  ContainerBotoes,
  ModalCorpo,
  SubtituloModal,
  ContainerAlternativas
} from '../styles'
import { ModalIncluirPerguntaRef, ModalIncluirPerguntaProps } from '../tipos'
import { schema } from './schema'

const Componente: ForwardRefRenderFunction<
  ModalIncluirPerguntaRef,
  ModalIncluirPerguntaProps
> = ({ backdrop, acaoPrimaria, aberta }, ref) => {
  const formRef = useRef<FormRef>()

  const [aberto, definirAberto] = useState(aberta)
  const [pergunta, definirPergunta] = useState<PerguntasPesquisaSatisfacao>(
    null
  )
  const [alternativas, definirAlternativas] = useState<
    PerguntasPesquisaAlternativa[]
  >([])
  const [referencia, definirReferencia] = useState<string>(null)
  const [
    tipoPergunta,
    definirTipoPergunta
  ] = useState<TipoPesquisaSatisfacaoPergunta>(
    TipoPesquisaSatisfacaoPergunta.EscalaLinear
  )

  const [modoEdicao, definirModoEdicao] = useState<boolean>(false)
  const [addNaoSeAplica, definirAddNaoSeAplica] = useState(false)

  const dadosIniciais = useMemo(() => {
    const ordem =
      pergunta?.alternativas?.sort(x => x.ordem).map(x => x.ordem) ?? []
    return {
      ...pergunta,
      alternativas: pergunta?.alternativas?.map(x => x.texto ?? ''),
      inicioEscalaLinear: ordem[0],
      finalEscalaLinear: ordem[ordem.length - 1]
    }
  }, [aberta, pergunta])

  const adicionarAlternativa = () =>
    definirAlternativas([...alternativas, {} as PerguntasPesquisaSatisfacao])

  const removerAlternativa = (index: number) => {
    formRef?.current?.clearField(`alternativa[${index}]`)
    definirAlternativas(alternativas.filter((_, key) => key !== index))
  }

  const abrir = (
    perguntaAtual: PerguntasPesquisaSatisfacao,
    referencia?: string
  ) => {
    definirModoEdicao(!!perguntaAtual)
    definirPergunta(perguntaAtual)
    definirAlternativas([
      ...(perguntaAtual?.alternativas ?? ([] as PerguntasPesquisaSatisfacao[]))
    ])
    definirReferencia(referencia)
    definirAberto(true)
    definirAddNaoSeAplica(perguntaAtual?.addNaoSeAplica ?? false)
  }

  const fechar = () => {
    formRef.current?.reset()
    definirPergunta(null)
    definirAlternativas([])
    definirReferencia(null)
    definirAberto(false)
  }

  const acaoSucesso = dados => {
    const retorno = {
      ...dados,
      id: pergunta?.id ?? v4(),
      tipo: tipoPergunta,
      alternativas: alternativas,
      ordem: 1
    } as PerguntasPesquisaSatisfacao

    acaoPrimaria(retorno, referencia)
    fechar()
  }

  useEffect(() => {
    if (tipoPergunta !== TipoPesquisaSatisfacaoPergunta.EscalaLinear) {
      definirAddNaoSeAplica(false)
    }
  }, [tipoPergunta])

  useImperativeHandle<ModalIncluirPerguntaRef, ModalIncluirPerguntaRef>(
    ref,
    () => ({
      fechar,
      abrir
    })
  )

  const renderizarAlternativas = () =>
    alternativas.map((value, key) => (
      <FormRow key={key}>
        <InputUnform
          className="lg"
          name={`alternativas[${key}]`}
          type="text"
          obrigatorio
          label={`Alternativa ${key + 1}`}
          defaultValue={value?.texto}
          onBlur={e => {
            const novasAlternativas = alternativas
            const alternativaIndex = novasAlternativas[key]
            const novaAlternativa = {
              id: alternativaIndex.id,
              texto: e.target.value,
              ordem: key + 1
            }
            novasAlternativas.splice(key, 1, novaAlternativa)
            definirAlternativas(novasAlternativas)
          }}
        />
        {key > 1 && (
          <ContainerAcoes>
            <button
              type="button"
              data-tip="Excluir"
              onClick={() => removerAlternativa(key)}
            >
              {IconeLixeira}
            </button>
          </ContainerAcoes>
        )}
      </FormRow>
    ))

  return (
    <ModalBase aberta={aberto} backdrop={backdrop} semLimiteAltura>
      <ModalCorpo>
        <SubtituloModal>Incluir Pergunta</SubtituloModal>
        {aberto && (
          <FormUnform
            acaoSucesso={acaoSucesso}
            acaoFalha={focarPrimeiroCampoComErro}
            dadosIniciais={dadosIniciais}
            schema={schema}
            ref={formRef}
          >
            <EditorUnform label="Texto" name="texto" obrigatorio />
            <SelectUnform
              id="slct_tipo_pergunta"
              name="tipo"
              label="Tipo de pergunta"
              valorAlterado={valor => {
                definirTipoPergunta(valor.id as TipoPesquisaSatisfacaoPergunta)
              }}
              opcoes={[
                {
                  id: TipoPesquisaSatisfacaoPergunta.EscalaLinear,
                  texto: 'Linear'
                },
                {
                  id: TipoPesquisaSatisfacaoPergunta.Paragrafo,
                  texto: 'Paragráfo'
                },
                {
                  id: TipoPesquisaSatisfacaoPergunta.RespostaUnica,
                  texto: 'Resposta Única'
                }
              ]}
              disabled={modoEdicao}
              obrigatorio={!modoEdicao}
            />
            <ContainerAlternativas>
              {tipoPergunta !== TipoPesquisaSatisfacaoPergunta.EscalaLinear &&
                renderizarAlternativas()}
            </ContainerAlternativas>
            {tipoPergunta === TipoPesquisaSatisfacaoPergunta.RespostaUnica && (
              <Botao
                type="button"
                onClick={adicionarAlternativa}
                tema="Link"
                elemento={
                  <ConteudoBotao>
                    {IconeAdicionar} Incluir nova alternativa
                  </ConteudoBotao>
                }
              />
            )}
            {tipoPergunta === TipoPesquisaSatisfacaoPergunta.EscalaLinear && (
              <>
                <NumeroInputUnform
                  name={'inicioEscalaLinear'}
                  label={'Início'}
                  obrigatorio
                  disabled={modoEdicao}
                />
                <NumeroInputUnform
                  name={'finalEscalaLinear'}
                  label={'Final'}
                  obrigatorio
                  disabled={modoEdicao}
                />
                <CheckboxUnform
                  id="chk_incluir_naoseaplica"
                  name="addNaoSeAplica"
                  texto={'Adicionar opção "Não se aplica"'}
                  checked={addNaoSeAplica}
                  onChange={() => definirAddNaoSeAplica(old => !old)}
                  disabled={modoEdicao}
                />
              </>
            )}
            <ContainerBotoes>
              <FormBotao
                type="button"
                texto="Cancelar"
                tema="Secundario"
                onClick={fechar}
              />
              <FormBotao type="submit" texto="Salvar" className="btn-salvar" />
            </ContainerBotoes>
          </FormUnform>
        )}
      </ModalCorpo>
    </ModalBase>
  )
}

export const ModalIncluirPergunta = forwardRef(Componente)
