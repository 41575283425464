import { AxiosRequestConfig } from 'axios'
import {
  BoletoParaPagamentoDoCurso,
  PagamentoComCartaoDeCredito,
  CompraComCartaoDeCredito,
  Recibo,
  PagamentoComCartaoCreditoCarrinhoCompra
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

const baseUrl = '/pagamentos'

export const ObterBoletoParaPagamentoDoCurso = async (
  matriculaId: string,
  cobrancaId: string,
  opcaoPix?: string
): Promise<BoletoParaPagamentoDoCurso> => {
  let url = `${baseUrl}/matricula/${matriculaId}/obter-boleto-pagamento-curso/${cobrancaId}`

  if (opcaoPix) {
    url += `?opcaoPix=${encodeURIComponent(opcaoPix)}`
  }

  const { data } = await gatewayApi.get<BoletoParaPagamentoDoCurso>(url)

  return data
}

export const EfetuarPagamentoDoCursoComCartaoDeCredito = async (
  data: PagamentoComCartaoDeCredito
): Promise<CompraComCartaoDeCredito> => {
  const url = `${baseUrl}/matricula/efetuar-pagamento-cartao-credito-curso`
  const response = await gatewayApi.post<CompraComCartaoDeCredito>(url, data)

  return response.data
}

export const ObterInformacoesDoRecibo = async (
  matriculaId: string,
  cobrancaId: string
): Promise<Recibo> => {
  const url = `${baseUrl}/matricula/${matriculaId}/obter-recibo/${cobrancaId}`
  const response = await gatewayApi.get<Recibo>(url)

  return response.data
}

export const ObterBoletoParaPagamentoCarrinhoCompraCursoLivre = async (
  carrinhoCompraCursoLivre: string
): Promise<BoletoParaPagamentoDoCurso> => {
  const url = `${baseUrl}/matricula/obter-boleto-pagamento-carrinho-compra/${carrinhoCompraCursoLivre}`
  const { data } = await gatewayApi.get<BoletoParaPagamentoDoCurso>(url)

  return data
}
export const EfetuarPagamentoCarrinhoCompraComCartaoDeCredito = async (
  data: PagamentoComCartaoCreditoCarrinhoCompra
): Promise<CompraComCartaoDeCredito> => {
  const url = `${baseUrl}/matricula/efetuar-pagamento-cartao-credito-carrinho-compra`
  const response = await gatewayApi.post<CompraComCartaoDeCredito>(url, data)

  return response.data
}
export const ObterInformacoesDoReciboCarrinhoCompra = async (
  cobrancaCarrinhoCompraId: string
): Promise<Recibo> => {
  const url = `${baseUrl}/matricula/obter-recibo/carrinho-compra/${cobrancaCarrinhoCompraId}`
  const response = await gatewayApi.get<Recibo>(url)

  return response.data
}

export const ObterArquivoRecibo = async (
  matriculaId: string,
  cobrancaId: string
): Promise<string> => {
  const rota = `${baseUrl}/matricula/${matriculaId}/obter-recibo/${cobrancaId}/arquivo`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null
  return URL.createObjectURL(resposta.data)
}

export const ObterArquivoReciboCarrinhoCompra = async (
  carrinhoCompraId: string
): Promise<string> => {
  const rota = `${baseUrl}/matricula/obter-recibo/carrinho-compra/${carrinhoCompraId}/arquivo`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null
  return URL.createObjectURL(resposta.data)
}

export const ObterArquivoReciboAnual = async (
  matriculaId: string,
  ano: number
): Promise<string> => {
  const rota = `${baseUrl}/matricula/${matriculaId}/obter-recibo-anual/${ano}/arquivo`
  const config = { responseType: 'blob' } as AxiosRequestConfig
  const resposta = await gatewayApi.get(rota, config)

  if (resposta?.status !== 200) return null
  return URL.createObjectURL(resposta.data)
}
