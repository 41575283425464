import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Breadcrumb,
  Cabecalho,
  IconeBack,
  Link,
  ModalIncluirMaterial,
  ModalMaterialRef,
  Navegacao,
  ErroContainerLayout,
  ErroLayoutContainer,
  Carregando,
  ModalExcluirMaterial,
  ModalExcluirMaterialRef
} from 'src/componentes'
import {
  ModalIncluirPergunta,
  ModalIncluirPerguntaRef
} from 'src/componentes/perguntas'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import { DisciplinaComplementar } from 'src/servicos/api/tipos'
import { Material } from 'src/tipos'
import { v4 } from 'uuid'

import {
  ModalIncluirEncontroPresencial,
  ModalRemoverEncontro,
  Formulario
} from './componentes'
import { ModalIncluirEncontroRef } from './componentes/presencial/modal-incluir-encontro/tipos'
import { ModalRemoverEncontroRef } from './componentes/presencial/modal-remover-encontro/tipos'
import PaginaCadastroTurmaContainer from './container'
import {
  Container,
  ContainerMain,
  Conteudo,
  ContainerLateral,
  ContainerLink
} from './styles'
import {
  EncontroPresencialTurma,
  MaterialTurma,
  Navegacoes,
  PaginaTurmaProps,
  PerguntaTurma
} from './tipos'

const CadastroTurma: React.FC = () => {
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()
  const {
    modalAdicionarEncontroRef,
    modalRemoverEncontroRef,
    modalIncluirMaterialRef,
    modalIncluirMaterialTurmaRef,
    modalExcluirMaterialRef,
    modalIncluirPerguntaRef,
    materiais,
    turma,
    perguntas,
    ehModoCadastro,
    ehModalidadeEad,
    definirTurma,
    definirCursoId,
    definirEncontros,
    adicionarEncontro,
    definirMateriais,
    definirPerguntas,
    removerMaterial,
    adicionarAlteracaoMaterial,
    definirDisciplinaComplementar
  } = PaginaCadastroTurmaContainer.useContainer()
  const { erro, definirErro, limparErro } = ErroLayoutContainer.useContainer()

  const [pronto, definirPronto] = useState(false)
  const [titulo] = useState(
    `${ehModoCadastro ? 'Inclusão' : 'Edição'} de Turma`
  )

  const manipularMaterial = (
    data: Material,
    index?: number,
    idDisciplina?: string
  ) => {
    const dado = { ...data, idDisciplina }
    adicionarAlteracaoMaterial(idDisciplina, dado.tipoMaterialComplementar)
    if (idDisciplina) {
      if (dado.tipoMaterialComplementar) {
        const parametroSorte = prop => (v1, v2) =>
          v1[prop] === v2[prop] ? 0 : v1[prop] < v2[prop] ? -1 : 1

        const restMateriais = materiais.filter(
          m => m.tipoMaterialComplementar !== idDisciplina
        )
        const novosMateriaisComplementares = materiais.filter(
          m => m.tipoMaterialComplementar === idDisciplina
        )

        const indice = novosMateriaisComplementares
          .sort(parametroSorte('ordem'))
          .findIndex(m => m.id === data.id)

        if (indice > -1) {
          novosMateriaisComplementares[indice] = dado
        } else {
          novosMateriaisComplementares.push({ ...dado, id: v4() })
        }

        definirMateriais([...restMateriais, ...novosMateriaisComplementares])
      } else {
        const restMateriais = materiais.filter(
          m => m.idDisciplina !== idDisciplina
        )
        const novosMateriais = materiais.filter(
          m => m.idDisciplina === idDisciplina
        )
        const indice = novosMateriais.findIndex(m => m.id === data.id)

        if (indice > -1) {
          novosMateriais[indice] = dado
        } else {
          novosMateriais.push({ ...dado, id: v4() })
        }

        definirMateriais([...restMateriais, ...novosMateriais])
      }
    } else {
      definirMateriais([...materiais, { ...dado, id: v4() }])
    }
  }

  const manipularPergunta = (data: PerguntaTurma) => {
    const restPerguntas = perguntas.filter(
      m => m.idReferencia !== data.idReferencia
    )
    const novosPerguntas = perguntas.filter(
      m => m.idReferencia === data.idReferencia
    )
    const indice = novosPerguntas.findIndex(m => m.id === data.id)

    if (indice > -1) {
      novosPerguntas[indice] = data
    } else {
      novosPerguntas.push({ ...data, id: v4() })
    }

    definirPerguntas([...restPerguntas, ...novosPerguntas])
  }

  const carregarTurma = async () => {
    try {
      limparErro()
      const novaTurma = await Api.RequisitarTurmaPorId(id)
      const perguntasTurma: PerguntaTurma[] = []
      const encontrosTurma: EncontroPresencialTurma[] = []
      const materiaisTurma: MaterialTurma[] = [...novaTurma.materiaisTurma]
      const disciplinasComplementares: DisciplinaComplementar[] = [
        ...novaTurma.disciplinasComplementares
      ]

      novaTurma.modulos
        .sort((a, b) => a.ordem - b.ordem)
        .forEach(m => {
          // Disciplinas
          m.disciplinas.forEach(d => {
            // Avaliação
            if (d.avaliacao) {
              d.avaliacao.perguntas.forEach(p =>
                perguntasTurma.push({
                  id: p.id,
                  pergunta: p.texto,
                  explicacao: p.explicacao,
                  respostas: p.respostas.map(r => {
                    return {
                      id: r.id,
                      texto: r.texto,
                      correta: r.correta
                    }
                  }),
                  idReferencia: d.disciplinaId
                })
              )
            }

            // Encontros
            d.encontros.forEach(e =>
              encontrosTurma.push({
                ...e,
                idDisciplina: d.disciplinaId,
                id: v4()
              })
            )

            d.materiais.forEach(mat =>
              materiaisTurma.push({
                ...mat,
                tipo: mat.tipoMaterial,
                arquivo: mat.nomeArquivo ?? null,
                idDisciplina: d.disciplinaId
              })
            )
          })

          // Avaliação Módulo
          if (m.avaliacao) {
            m.avaliacao.perguntas.forEach(p =>
              perguntasTurma.push({
                id: p.id,
                pergunta: p.texto,
                explicacao: p.explicacao,
                respostas: p.respostas.map(r => {
                  return {
                    id: r.id,
                    texto: r.texto,
                    correta: r.correta
                  }
                }),
                idReferencia: m.moduloId
              })
            )
          }
        })

      novaTurma.moduloTCC?.disciplinas?.forEach(d => {
        d.materiais?.forEach(m => {
          materiaisTurma.push({
            ...m,
            tipo: m.tipoMaterial,
            arquivo: m.nomeArquivo ?? null,
            idDisciplina: d.disciplinaId
          })
        })
      })

      novaTurma?.materiaisComplementares?.forEach(m => {
        materiaisTurma.push({
          ...m,
          tipo: m.tipoMaterial,
          arquivo: m.nomeArquivo ?? null
        })
      })

      definirTurma({ id, ...novaTurma })
      definirCursoId(novaTurma.cursoId)
      definirMateriais(materiaisTurma)
      definirPerguntas(perguntasTurma)
      definirEncontros(encontrosTurma)
      disciplinasComplementares?.forEach(x =>
        definirDisciplinaComplementar(x.tipo, x.nome)
      )
    } catch (error) {
      definirErro({
        mensagens:
          'Ops! Houve algum problema no carregamento dos dados. Por favor, volte na pesquisa ou tente novamente.',
        textoConfirmar: 'Tentar de novo',
        acaoConfirmar: carregarTurma
      })
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    if (!ehModoCadastro) {
      carregarTurma()
    } else {
      definirPronto(true)
    }
  }, [ehModoCadastro])

  const itensNavegacao = useMemo(() => {
    const itens = [
      {
        link: Navegacoes.DadosTurma,
        nome: 'Dados Turma'
      },
      {
        link: Navegacoes.Curso,
        nome: 'Curso'
      },
      !ehModalidadeEad && {
        link: Navegacoes.Localizacao,
        nome: 'Localização'
      },
      {
        link: Navegacoes.GrupoDiscussao,
        nome: 'Fórum de dúvidas'
      },
      {
        link: Navegacoes.MateriaisCurso,
        nome: 'Materiais do Curso'
      },
      {
        link: Navegacoes.PesquisaSatisfacao,
        nome: 'Pesquisa de Satisfação'
      },
      {
        link: Navegacoes.MaterialComplementar,
        nome: 'Material Complementar'
      }
    ]

    return itens
  }, [turma.modalidadeCurso, ehModalidadeEad])

  return (
    <>
      {pronto && !erro ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.Turmas}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Acadêmico',
                    acao: () => history.goBack()
                  },
                  {
                    texto: 'Turmas',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <Formulario />
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao itens={itensNavegacao} />
          </ContainerLateral>
          <ModalIncluirEncontroPresencial
            backdrop
            ref={modalAdicionarEncontroRef}
            acaoPrimaria={adicionarEncontro}
          />
          <ModalRemoverEncontro ref={modalRemoverEncontroRef} backdrop />
          <ModalIncluirPergunta
            backdrop
            ref={modalIncluirPerguntaRef}
            acaoPrimaria={(dados, referencia) =>
              manipularPergunta({ ...dados, idReferencia: referencia })
            }
            turma={true}
          />
          <ModalIncluirMaterial
            backdrop
            ref={modalIncluirMaterialRef}
            exibirTipoMaterial
            acaoPrimaria={manipularMaterial}
          />
          <ModalIncluirMaterial
            backdrop
            ref={modalIncluirMaterialTurmaRef}
            exibirTipoMaterial={false}
            acaoPrimaria={manipularMaterial}
          />
          <ModalExcluirMaterial
            backdrop
            ref={modalExcluirMaterialRef}
            acaoPrimaria={removerMaterial}
          />
        </Container>
      ) : !pronto && !erro ? (
        <Carregando />
      ) : (
        <ErroContainerLayout />
      )}
    </>
  )
}

export const PaginaCadastroTurma: React.FC<PaginaTurmaProps> = ({ modo }) => {
  const modalIncluirPerguntaRef = useRef<ModalIncluirPerguntaRef>(null)
  const modalIncluirMaterialRef = useRef<ModalMaterialRef>()
  const modalIncluirMaterialTurmaRef = useRef<ModalMaterialRef>()
  const modalExcluirMaterialRef = useRef<ModalExcluirMaterialRef>()
  const modalAdicionarEncontroRef = useRef<ModalIncluirEncontroRef>(null)
  const modalRemoverEncontroRef = useRef<ModalRemoverEncontroRef>(null)

  return (
    <PaginaCadastroTurmaContainer.Provider
      initialState={{
        modo,
        modalIncluirPerguntaRef,
        modalIncluirMaterialRef,
        modalIncluirMaterialTurmaRef,
        modalExcluirMaterialRef,
        modalAdicionarEncontroRef,
        modalRemoverEncontroRef
      }}
    >
      <ErroLayoutContainer.Provider>
        <CadastroTurma />
      </ErroLayoutContainer.Provider>
    </PaginaCadastroTurmaContainer.Provider>
  )
}
