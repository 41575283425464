import React, { useRef, useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import ReactSelect from 'react-select'

import { useField } from '@unform/core'
import classNames from 'classnames'
import deburr from 'lodash/deburr'
import PropTypes from 'prop-types'

import { Botao } from '../../botao'
import { IconeCopiar, IconeFechar } from '../../icones'
import {
  Componente,
  Label,
  Erro,
  Grupo,
  Composicao,
  CampoReadOnly,
  Clipboard,
  Obrigatorio
} from './styles'
import { SelectProps, SelectOpcao } from './tipos'

export const SelectUnform: React.FC<SelectProps> = ({
  id,
  name,
  label,
  placeholder,
  opcoes,
  multiplo,
  alturaMaxima,
  menuAberto,
  readOnly,
  disabled,
  valorAlterado,
  maxWidthInput,
  tamanho,
  maxWidthMenu,
  posicao,
  comCopia,
  obrigatorio,
  iconeFechar,
  className,
  margemInferior = true,
  deveLimpar,
  valorAlteradoMulti,
  ...rest
}) => {
  const selectRef = useRef(null)
  const primeiroCarregamento = useRef(true)
  const [temValor, definirTemValor] = useState<boolean>(false)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)
  const [valorTexto, definirValorTexto] = useState<any>()

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => {
        if (multiplo) {
          if (!selectRef.current?.state.value) {
            return []
          }
          return selectRef.current?.state.value.map(
            (option: SelectOpcao) => option.id
          )
        }
        if (!selectRef.current?.state.value) {
          return ''
        }
        return selectRef.current?.state.value.id
      },
      setValue: (_: any, value: any) => {
        const opcoesFind = selectRef.current?.props?.options.find(
          o => o.id === value
        )
        selectRef.current?.select.setValue(opcoesFind)
      }
    })
  }, [fieldName, registerField, multiplo, opcoes])

  useEffect(() => {
    if (!defaultValue) return

    selectRef.current?.select?.setValue(
      multiplo
        ? opcoes.filter(
            o => defaultValue.filter(i => i.toString() === o.id).length > 0
          )
        : opcoes.find(o => o.id === defaultValue)
    )

    definirValorTexto(
      multiplo
        ? opcoes
            .filter(
              o => defaultValue.filter(i => i.toString() === o.id).length > 0
            )
            .map((v, i) => `${i > 0 ? '/' : <></>} ${v.texto}`)
        : opcoes.find(o => o.id === defaultValue)?.texto
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (primeiroCarregamento.current || !deveLimpar) return
    if (deveLimpar()) selectRef.current?.select.clearValue()
  }, [deveLimpar])

  useEffect(() => {
    primeiroCarregamento.current = false
  })

  const renderizarIconeFechar = () => (
    <span
      className="icone_fechar"
      onClick={() => {
        selectRef.current?.select.clearValue()
      }}
    >
      {IconeFechar}
    </span>
  )

  return (
    <Componente
      erro={error}
      readOnly={readOnly}
      disabled={disabled}
      tamanho={tamanho}
      posicao={posicao}
      maxWidthMenu={maxWidthMenu}
      className={className}
      margemInferior={margemInferior}
      {...rest}
    >
      {label && (
        <Label readOnly={readOnly} htmlFor={id}>
          {obrigatorio ? <Obrigatorio>*</Obrigatorio> : <></>}
          {label}
        </Label>
      )}
      {comCopia && readOnly && (
        <Composicao>
          <CampoReadOnly id={`sp-${id}`} temValor={valorTexto}>
            {valorTexto}
          </CampoReadOnly>
          <Clipboard>
            <Botao
              id={`btn_clip-board-sp-${id}`}
              tooltip={{ id: 'tooltip-top', texto: 'Copiar', lado: 'right' }}
              tema="Outro"
              elemento={IconeCopiar}
              onClick={() => {
                const valor = document.getElementById(
                  `sp-${id}`
                ) as HTMLDivElement
                navigator.clipboard.writeText(valor.textContent)
              }}
            />
          </Clipboard>
        </Composicao>
      )}
      <Grupo
        maxWidthInput={maxWidthInput}
        readOnly={readOnly}
        comCopia={comCopia}
        id={id}
      >
        <ReactSelect
          menuIsOpen={readOnly || disabled ? false : menuAberto}
          id={id}
          ref={selectRef}
          className={classNames({ error })}
          classNamePrefix="react-select"
          isClearable={!readOnly && !disabled && iconeFechar}
          noOptionsMessage={() => 'Sem opções'}
          backspaceRemovesValue={false}
          menuPlacement="auto"
          onChange={valor => {
            clearError()
            definirTemValor(!!valor)
            if (valor) {
              if (multiplo) {
                const valores = Array.from(valor as Iterable<SelectOpcao>)
                valorAlteradoMulti && valorAlteradoMulti([...valores])
                return
              } else {
                const { id, texto } = { ...valor }
                valorAlterado && valorAlterado({ id, texto })
                return
              }
            }

            valorAlteradoMulti && valorAlteradoMulti([])
            valorAlterado && valorAlterado(null)
          }}
          placeholder={!readOnly ? placeholder || 'Selecione ...' : ''}
          options={opcoes}
          getOptionLabel={(opcao: SelectOpcao) => opcao.texto}
          formatOptionLabel={({ texto }, { inputValue }) => (
            <Highlighter
              searchWords={[inputValue]}
              textToHighlight={texto}
              sanitize={deburr}
            />
          )}
          getOptionValue={(opcao: SelectOpcao) => opcao?.id ?? null}
          maxMenuHeight={alturaMaxima}
          isSearchable={!readOnly}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9 }),
            dropdownIndicator: old =>
              readOnly || disabled ? { ...old, display: 'none' } : old,
            valueContainer: base => ({
              ...base,
              maxHeight: '100px',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            })
          }}
          isMulti={multiplo}
        />
        {temValor && !readOnly && iconeFechar && renderizarIconeFechar()}
      </Grupo>

      <Erro id={`erro-${id}`} className="erro">
        {error}
      </Erro>
    </Componente>
  )
}

SelectUnform.defaultProps = {
  label: undefined,
  placeholder: 'Selecione ...',
  multiplo: false,
  'data-testid': undefined,
  className: undefined,
  alturaMaxima: undefined,
  menuAberto: undefined,
  readOnly: false,
  comCopia: false,
  disabled: false,
  valorAlterado: undefined,
  maxWidthInput: null,
  tamanho: null
}

SelectUnform.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  opcoes: PropTypes.arrayOf(
    PropTypes.exact({
      texto: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  multiplo: PropTypes.bool,
  menuAberto: PropTypes.bool,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  alturaMaxima: PropTypes.number,
  readOnly: PropTypes.bool,
  comCopia: PropTypes.bool,
  disabled: PropTypes.bool,
  valorAlterado: PropTypes.func,
  maxWidthInput: PropTypes.string,
  tamanho: PropTypes.oneOf(['S', 'M', 'L']),
  posicao: PropTypes.oneOf(['direita', 'esquerda']),
  maxWidthMenu: PropTypes.string
}
