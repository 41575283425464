import queryString from 'query-string'
import { OpcoesPaginacao, RespostaPadraoAPI, TipoOrdenacao } from 'src/tipos'
import { Precificacao, PrecificacaoItem } from 'src/tipos/precificacao'

import { gatewayApi } from '../instancias-api'

const urlBase = '/precificacao'

export const InserirPrecificacao = async (
  payload: PrecificacaoItem
): Promise<{ success: boolean; idPrecificacao?: string }> => {
  const { data } = await gatewayApi.post<{ success: boolean; id?: string }>(
    `${urlBase}/cadastrar-precificacao`,
    payload
  )

  return data
}

export const EditarPrecificacao = async (
  id: string,
  payload: PrecificacaoItem
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(
    `${urlBase}/editar-precificacao/${id}`,
    payload
  )

  return success
}

export const ObterDadosPrecificacao = async (
  id: string
): Promise<PrecificacaoItem> => {
  const { data } = await gatewayApi.get<PrecificacaoItem>(
    `${urlBase}/obter-detalhes/${id}`
  )

  return data
}

export const ObterDetalhesPrecificacao = async (
  nome: string,
  codigo: string,
  tipo: string,
  pagina?: number,
  quantidadeRegistros?: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: Array<PrecificacaoItem>
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    const ordem = chaveOrdenacao
      ? `${chaveOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nome,
      codigo,
      tipo,
      quantidadeRegistros,
      pagina,
      ordem
    })

    const {
      data: { itens, quantidade }
    } = await gatewayApi.get<Precificacao>(
      `${urlBase}/precificacoes?${filtros}`
    )

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }

    return new Promise(resolve =>
      resolve({ registros: itens, opcoesPaginacao })
    )
  } catch {
    return null
  }
}
