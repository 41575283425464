import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  FormUnform,
  Botao,
  Breadcrumb,
  Cabecalho,
  focarPrimeiroCampoOuEditorComErro,
  IconeBack,
  Link,
  Modal,
  ModalRef,
  Navegacao,
  SelectOpcao,
  SeletorUnform,
  SubTitulo,
  InputUnform,
  Spacer,
  DataInputUnform
} from 'src/componentes'
import { EditorUnform } from 'src/componentes/unform/editor'
import { SelectUnform } from 'src/componentes/unform/select'
import { SELECT_MODALIDADES_PESQUISA_SATISFACAO } from 'src/dados-estaticos'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import {
  ModalidadeCurso,
  TipoPesquisaSatisfacao,
  SituacaoPesquisaSatisfacao,
  PesquisaSatisfacao,
  PerguntasPesquisaSatisfacao,
  ModalidadeCursoPorExtenso
} from 'src/tipos'
import { ModoPaginaCadastro } from 'src/tipos/genericos'
import { v4 } from 'uuid'

import {
  ListaPerguntas,
  ModalIncluirPergunta,
  ModalIncluirPerguntaRef
} from '../componentes/perguntas'
import { schema } from './schema'
import {
  Container,
  ContainerMain,
  ContainerDadosMedio,
  Conteudo,
  ContainerDados,
  ContainerDadosPequeno,
  ContainerNavegacao,
  ContainerAcoes,
  FormContainer,
  ContainerLink,
  SecaoPrimaria,
  ContainerDadosGrande
} from './styles'
import { PaginaPesquisaSatisfacaoProps } from './tipos'

const PaginaPesquisaSatisfacao: React.FC<PaginaPesquisaSatisfacaoProps> = ({
  match,
  modo
}) => {
  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de Pesquisa de Satisfação`
  )

  const [pronto, definirPronto] = useState(false)

  enum Navegacoes {
    DadosPesquisaSatisfacao = 'dados-pesquisa-satisfacao'
  }

  const modalRef = useRef<ModalRef>(null)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { state } = useLocation<{ modalidade: ModalidadeCurso }>()
  const modalPerguntaRef = useRef<ModalIncluirPerguntaRef>(null)
  const [, definirModalidade] = useState<string>(state?.modalidade)
  const [pesquisaSatisfacao, definirPesquisaSatisfacao] = useState<
    PesquisaSatisfacao | undefined
  >(undefined)
  const [perguntas, definirPerguntas] = useState<PerguntasPesquisaSatisfacao[]>(
    []
  )
  const [tipoPesquisa, definirTipoPesquisa] = useState<TipoPesquisaSatisfacao>(
    null
  )

  const PaginaEmModoEdicao = modo === ModoPaginaCadastro.Edicao

  const alterarCampoForm = (campo: string, valor: any) => {
    formRef?.current?.setFieldError(campo, null)
    formRef?.current?.setFieldValue(campo, valor)
  }

  const modalidadeAlterada = (opcao: SelectOpcao) => {
    if (opcao) {
      alterarCampoForm('tipo', null)
    }

    definirModalidade(opcao?.id)
  }

  const tipoAlterado = (opcao?: SelectOpcao) => {
    if (opcao?.id) {
      const novoTipo = (opcao.id as unknown) as TipoPesquisaSatisfacao
      if (novoTipo === TipoPesquisaSatisfacao.Institucional) {
        alterarCampoForm('modalidade', null)
      }
      definirTipoPesquisa(novoTipo)
    } else {
      definirTipoPesquisa(null)
    }
  }

  const dadosIniciais = useMemo(() => {
    return pesquisaSatisfacao
  }, [pesquisaSatisfacao])

  const bloqueioPerguntas = useMemo(() => {
    if (!PaginaEmModoEdicao || !pesquisaSatisfacao) return false

    return pesquisaSatisfacao.possuiResposta ?? false
  }, [pesquisaSatisfacao])

  const opcoesModalidade = useMemo(() => {
    if (tipoPesquisa === TipoPesquisaSatisfacao.Institucional) {
      return []
    }

    if (tipoPesquisa === TipoPesquisaSatisfacao.Professores) {
      return [
        {
          id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEad],
          texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEad]
        },
        {
          id: ModalidadeCurso[ModalidadeCurso.PosGraduacaoEadFull],
          texto: ModalidadeCursoPorExtenso[ModalidadeCurso.PosGraduacaoEadFull]
        }
      ]
    }

    return SELECT_MODALIDADES_PESQUISA_SATISFACAO
  }, [SELECT_MODALIDADES_PESQUISA_SATISFACAO, tipoPesquisa])

  const acaoSucesso = async (dados: PesquisaSatisfacao) => {
    try {
      const request = { ...dados, perguntas: perguntas }
      definirCarregando(true)
      if (modo === ModoPaginaCadastro.Inclusao) {
        await Api.InserirPesquisaSatisfacao(request)
      } else {
        await Api.AtualizarPesquisaSatisfacao(match.params.id, request)
      }

      toast(
        `Pesquisa de Satisfação ${
          modo === ModoPaginaCadastro.Inclusao ? 'cadastrada' : 'editada'
        } com sucesso!`,
        {
          type: 'success'
        }
      )

      history.push(RotasAcademico.PesquisaSatisfacao)
    } catch (error) {
      toast('Algo deu errado. Tente novamente!', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const abrirModalAdicionarPergunta = (pergunta: PerguntasPesquisaSatisfacao) =>
    modalPerguntaRef?.current?.abrir(pergunta)

  const adicionarPergunta = (pergunta: PerguntasPesquisaSatisfacao) => {
    const novaPergunta = {
      ...pergunta,
      id: pergunta.id ?? v4()
    }

    const perguntaIndex = perguntas.findIndex(x => x.id === pergunta?.id)
    let perguntasEditadas: PerguntasPesquisaSatisfacao[] = []
    if (perguntaIndex >= 0) {
      const editadas = [...perguntas]
      editadas.splice(perguntaIndex, 1, novaPergunta)
      perguntasEditadas = editadas
    } else {
      perguntasEditadas = [...perguntas, novaPergunta]
    }

    perguntasEditadas = perguntasEditadas
      ?.sort(x => x.ordem)
      .map((p, index) => {
        return {
          ...p,
          ordem: index + 1
        }
      })

    definirPerguntas(perguntasEditadas)
  }

  const removerPergunta = (pergunta: PerguntasPesquisaSatisfacao) => {
    definirPerguntas(oldPerguntas => {
      const perguntasFiltradas = oldPerguntas.filter(x => x.id !== pergunta.id)
      return perguntasFiltradas
        ?.sort(x => x.ordem)
        .map((p, index) => {
          return {
            ...p,
            ordem: index + 1
          }
        })
    })
  }

  const reordenarPerguntas = (
    perguntasOrdenadas: PerguntasPesquisaSatisfacao[]
  ) => {
    definirPerguntas(() =>
      perguntasOrdenadas.map((x, i) => ({ ...x, ordem: i + 1 }))
    )
  }
  const carregarPesquisaSatisfacao = async () => {
    try {
      if (modo === ModoPaginaCadastro.Edicao) {
        const id = match.params.id
        const dados = await Api.RequisitarPesquisaSatisfacao(id)
        definirPesquisaSatisfacao(dados)
        definirPerguntas(dados.perguntas)
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados da pesquisa de satisfação.', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    carregarPesquisaSatisfacao()
  }, [])

  const focarNoPrimeiroErro = (id?: string) => {
    const selecaoDoEditor = '#informacoes-container > #erro-informacoes'
    return focarPrimeiroCampoOuEditorComErro(selecaoDoEditor)(id)
  }

  return (
    <>
      {pronto && (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.PesquisaSatisfacao}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Acadêmico',
                    acao: () => history.goBack()
                  },
                  {
                    texto: 'Pesquisas de Satisfação',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormContainer>
                <FormUnform
                  schema={schema}
                  dadosIniciais={dadosIniciais}
                  acaoSucesso={acaoSucesso}
                  acaoFalha={focarNoPrimeiroErro}
                >
                  <SecaoPrimaria id={Navegacoes.DadosPesquisaSatisfacao}>
                    <SubTitulo texto="Dados da Pesquisa de Satisfação" />
                    <InputUnform
                      name="nome"
                      label="Nome da Pesquisa de Satisfação"
                      obrigatorio
                      maxLength={200}
                    />
                    <ContainerDados>
                      <ContainerDadosMedio>
                        <InputUnform
                          disabled={PaginaEmModoEdicao}
                          name="codigo"
                          label="Código da Pesquisa de Satisfação"
                          maxLength={20}
                          obrigatorio
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="slct_tipo_pesquisa"
                          name="tipo"
                          label="Tipo"
                          placeholder="Selecione"
                          valorAlterado={tipoAlterado}
                          opcoes={[
                            {
                              id:
                                TipoPesquisaSatisfacao[
                                  TipoPesquisaSatisfacao.Curso
                                ],
                              texto: 'Curso'
                            },
                            {
                              id:
                                TipoPesquisaSatisfacao[
                                  TipoPesquisaSatisfacao.Institucional
                                ],
                              texto: 'Institucional'
                            },
                            {
                              id:
                                TipoPesquisaSatisfacao[
                                  TipoPesquisaSatisfacao.Professores
                                ],
                              texto: 'Professores'
                            }
                          ]}
                          disabled={PaginaEmModoEdicao}
                          obrigatorio
                        />
                      </ContainerDadosMedio>
                    </ContainerDados>
                    <ContainerDados>
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="slct_modalidade"
                          name="modalidade"
                          label="Modalidade do Curso"
                          opcoes={opcoesModalidade}
                          placeholder="Selecione"
                          obrigatorio={
                            tipoPesquisa !==
                            TipoPesquisaSatisfacao.Institucional
                          }
                          valorAlterado={modalidadeAlterada}
                          disabled={
                            tipoPesquisa ===
                              TipoPesquisaSatisfacao.Institucional ||
                            modo === ModoPaginaCadastro.Edicao
                          }
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosPequeno>
                        <DataInputUnform
                          name="dataInicioPesquisaSatisfacao"
                          label="Data Início"
                          disabled={
                            tipoPesquisa !==
                            TipoPesquisaSatisfacao.Institucional
                          }
                          obrigatorio={
                            tipoPesquisa ===
                            TipoPesquisaSatisfacao.Institucional
                          }
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosPequeno>
                        <DataInputUnform
                          name="dataFimPesquisaSatisfacao"
                          label="Data Fim"
                          disabled={
                            tipoPesquisa !==
                            TipoPesquisaSatisfacao.Institucional
                          }
                          obrigatorio={
                            tipoPesquisa ===
                            TipoPesquisaSatisfacao.Institucional
                          }
                        />
                      </ContainerDadosPequeno>
                    </ContainerDados>

                    {tipoPesquisa === TipoPesquisaSatisfacao.Institucional && (
                      <>
                        <SubTitulo texto="Informações da pesquisa" />
                        <EditorUnform
                          label="Essas informações serão exibidas para o aluno no momento em que ele estiver respondendo à pesquisa"
                          name="descricao"
                          obrigatorio
                        />
                      </>
                    )}

                    <ContainerDados>
                      <ContainerDadosMedio>
                        <SeletorUnform
                          name="situacao"
                          tipo="radio"
                          label="Situação"
                          seletores={[
                            {
                              id:
                                SituacaoPesquisaSatisfacao[
                                  SituacaoPesquisaSatisfacao.Ativo
                                ],
                              texto: 'Ativo'
                            },
                            {
                              id:
                                SituacaoPesquisaSatisfacao[
                                  SituacaoPesquisaSatisfacao.Inativo
                                ],
                              texto: 'Inativo'
                            }
                          ]}
                        />
                      </ContainerDadosMedio>
                    </ContainerDados>
                    <ContainerDados>
                      <ContainerDadosGrande>
                        <ListaPerguntas
                          items={perguntas ?? []}
                          adicionar={abrirModalAdicionarPergunta}
                          remover={removerPergunta}
                          reordenar={reordenarPerguntas}
                          disabled={bloqueioPerguntas}
                        />
                      </ContainerDadosGrande>
                    </ContainerDados>
                  </SecaoPrimaria>
                  <Spacer padding="10px 0px" />
                  <ContainerAcoes>
                    <Botao
                      texto="Cancelar"
                      tema="Secundario"
                      type="button"
                      disabled={carregando}
                      onClick={() => modalRef.current?.abrir()}
                    />
                    <Botao
                      type="submit"
                      texto="Salvar"
                      carregando={carregando}
                    />
                  </ContainerAcoes>
                </FormUnform>
              </FormContainer>
            </Conteudo>
          </ContainerMain>
          <ContainerNavegacao>
            <div>
              <Navegacao
                itens={[
                  {
                    link: Navegacoes.DadosPesquisaSatisfacao,
                    nome: 'Dados da Pesquisa de Satisfação'
                  }
                ]}
              />
            </div>
          </ContainerNavegacao>
          <Modal
            ref={modalRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            icone={<></>}
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
          <ModalIncluirPergunta
            backdrop
            ref={modalPerguntaRef}
            acaoPrimaria={adicionarPergunta}
          />
        </Container>
      )}
    </>
  )
}

export default withRouter(PaginaPesquisaSatisfacao)
