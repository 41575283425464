import { mensagemPadrao } from 'src/dados-estaticos'
import { TiposPrecificacao } from 'src/tipos/precificacao'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  nome: Yup.string().required(mensagemPadrao),
  codigo: Yup.string().required(mensagemPadrao),
  cpf: Yup.string().notRequired(),
  dataInicio: Yup.string().when('precificacaoBase', {
    is: true,
    then: schema => schema.notRequired(),
    otherwise: schema => schema.required(mensagemPadrao)
  }),
  dataFim: Yup.string().when('precificacaoBase', {
    is: true,
    then: schema => schema.notRequired(),
    otherwise: schema => schema.required(mensagemPadrao)
  }),
  valorBase: Yup.string().when(['tipoPrecificacao', 'precificacaoBase'], {
    is: (tipoPrecificacao, precificacaoBase) =>
      tipoPrecificacao === TiposPrecificacao.Campanha && precificacaoBase,
    then: schema => schema.required(mensagemPadrao),
    otherwise: schema => schema.notRequired()
  }),
  pctDesconto: Yup.string().when(['tipoPrecificacao', 'precificacaoBase'], {
    is: (tipoPrecificacao, precificacaoBase) =>
      tipoPrecificacao === TiposPrecificacao.Campanha && !precificacaoBase,
    then: schema => schema.required(mensagemPadrao),
    otherwise: schema => schema.notRequired()
  }),
  cursoId: Yup.string().required(mensagemPadrao)
})
