import React, { forwardRef } from 'react'

import { TipoPesquisaSatisfacao } from 'src/tipos'

import { Barra, Container, ContainerFora } from './styles'
import { RadioProps } from './tipos'

export const RadioPesquisaSatisfacao: React.ForwardRefExoticComponent<RadioProps> = forwardRef<
  HTMLInputElement,
  RadioProps
>(
  (
    {
      id,
      texto,
      tamanho,
      readOnly,
      tema = 'Padrao',
      estilizarTexto,
      valorAlterado,
      dezAlternativas,
      tipoPesquisa,
      ...rest
    },
    ref?: any
  ) => {
    let corBarra: 'vermelha' | 'amarela' | 'verde' = 'vermelha'
    let borderRight: string | undefined

    if (
      texto === '1' ||
      texto === '2' ||
      texto === '3' ||
      texto === '4' ||
      texto === '5' ||
      texto === '6'
    ) {
      corBarra = 'vermelha'
      borderRight = texto === '6' ? '10px solid white' : undefined
    } else if (texto === '7' || texto === '8') {
      corBarra = 'amarela'
      borderRight = texto === '8' ? '10px solid white' : undefined
    } else if (texto === '9' || texto === '10') {
      corBarra = 'verde'
      borderRight = texto === '10' ? '10px solid white' : undefined
    }

    const ehPesquisaInstitucional =
      tipoPesquisa === TipoPesquisaSatisfacao.Institucional

    return (
      <>
        <ContainerFora>
          <Container
            id={`radio_${id}`}
            className="radio-input"
            estilizarTexto={estilizarTexto}
            tema={tema}
            tamanho={tamanho}
            readOnly={readOnly}
          >
            <input
              {...rest}
              ref={ref}
              type="radio"
              id={id}
              onChange={e => {
                if (valorAlterado) valorAlterado(e.target.value)
              }}
            />
            {texto === 'Não se aplica' ? (
              <>
                <label htmlFor={id}>
                  <span className="seleciona-radio">
                    <span className="icone-radio"></span>
                  </span>
                </label>
                {texto && (
                  <label htmlFor={id} className="texto-radio">
                    {texto}
                  </label>
                )}
              </>
            ) : (
              <label htmlFor={id}>
                <span className="seleciona-radio">
                  <span className="icone-radio">
                    {texto && <span className="texto-radio">{texto}</span>}
                  </span>
                </span>
              </label>
            )}
          </Container>
          {dezAlternativas && corBarra && ehPesquisaInstitucional && (
            <Barra cor={corBarra} borda={borderRight} />
          )}
        </ContainerFora>
      </>
    )
  }
)
