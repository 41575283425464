import queryString from 'query-string'
import { SelectOpcao } from 'src/componentes'
import {
  FormaPagamentoCobranca,
  ModalidadeCurso,
  PlanoPagamentoParcelas
} from 'src/tipos'

import { gatewayApi } from '../instancias-api'

export interface PlanoPagamentoParams {
  quantidadeParcelas: string
  valorParcela: string
  cursoId?: string
  id?: string
  formaPagamento: string
  precificacaoId?: string
  precificacaoBase?: boolean
}

export interface PesquisaPlanoPagamento {
  itens: PlanoPagamentoListagem[]
  temProximaPagina: boolean
  quantidade: number
}

interface PlanoPagamentoListagem {
  curso?: string
  formaPagamento?: string
  id?: string
  modalidade?: ModalidadeCurso
  quantidade?: number
  valorParcela?: number
  valorTotalParcelado?: number
}

interface PlanoPagamentoResposta {
  id: string
  precoBase: string
  quantidadeParcelas: string
  valorParcela: string
  cursoId: string
  formaPagamento: string
  precificacaoId: string
}

export enum TipoAcao {
  Edicao = 'Edicao',
  Exclusao = 'Exclusao'
}

export const SalvarPlanoPagamento = async ({
  quantidadeParcelas,
  valorParcela,
  cursoId,
  formaPagamento,
  precificacaoId,
  precificacaoBase
}: PlanoPagamentoParams): Promise<PlanoPagamentoResposta> => {
  const url = '/planoPagamento'

  const resposta = await gatewayApi.post<PlanoPagamentoResposta>(url, {
    quantidadeParcelas,
    valorParcela,
    cursoId,
    formaPagamento,
    precificacaoId,
    precificacaoBase
  })

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const EditarPlanoPagamento = async (
  cursoId: string,
  precificacaoId: string,
  acao: TipoAcao,
  planosPagamento?: PlanoPagamentoParcelas[]
): Promise<PlanoPagamentoResposta> => {
  const url = '/planoPagamento'

  const resposta = await gatewayApi.put<PlanoPagamentoResposta>(url, {
    cursoId,
    precificacaoId,
    acao,
    planosPagamento
  })

  if (resposta?.status !== 200) return null

  return resposta.data
}

export const RequisitarListaPlanoPagamentoFiltro = async (
  texto: string
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({ texto: texto })

    const { data } = await gatewayApi.get<SelectOpcao[]>(
      `/PlanoPagamento/filtro?${filtros}`
    )

    return data
  } catch {
    return null
  }
}

export const RequisitarPlanoPagamentosCursos = async (
  cursosId: string[],
  formaPagamento?: FormaPagamentoCobranca
): Promise<SelectOpcao[]> => {
  try {
    const filtros = queryString.stringify({
      cursoId: cursosId ? [...cursosId] : null,
      formaPagamento: formaPagamento
    })

    const { data } = await gatewayApi.get<SelectOpcao[]>(
      `/PlanoPagamento/curso?${filtros}`
    )
    return data
  } catch {
    return null
  }
}
