import { LinkRodape, LinkRodapeProps } from 'src/componentes/link-rodape'
import { Cores, Medias } from 'src/componentes/styles'
import { Linha } from 'src/paginas/matricula/styles'
import styled, { css } from 'styled-components'

export const TituloCursosCarrinho = styled.h5`
  &:not(:last-of-type) {
    height: 40px;
  }
`

export const Container = styled.div`
  width: 31%;
  border-radius: 3px;
  padding: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  position: relative;

  @media ${Medias.MOBILE} {
    width: 100%;
    margin-right: 0px;
  }

  p {
    font-size: 14px;

    @media ${Medias.MOBILE} {
      font-size: 12px;
      :nth-child(even) {
        margin-bottom: 14px;
      }
    }
  }

  & > div {
    border-left: 4px solid;
    padding-left: 17px;

    p {
      :nth-child(even) {
        margin-bottom: 12px;
      }
    }

    h4 {
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;

      @media ${Medias.MOBILE} {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }

    h5 {
      height: 70px;
      font-size: 16px;
      font-weight: 300;

      :not(:last-child) {
        margin-bottom: 14px;
      }

      @media ${Medias.MOBILE} {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        height: 60px;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: 300;

      @media ${Medias.MOBILE} {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        height: 60px;
      }

      :first-child {
        color: ${Cores.SECUNDARIA_2};
        font-weight: 500;
        height: 25px;
      }
    }

    a {
      font-size: 16px;
      font-weight: 500;
      color: ${Cores.SECUNDARIA_2_ESCURO};

      @media ${Medias.MOBILE} {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  b {
    font-weight: 500;
  }

  div {
    border-color: ${Cores.SECUNDARIA_2};

    h4 {
      color: ${Cores.SECUNDARIA_2};
    }
  }
`
export const ContainerLink = styled.div`
  margin-top: 25px;
`
export const IconeLabel = styled.div`
  position: absolute;
  left: 90%;
  top: 0;
  @media ${Medias.MOBILE} {
    left: 95%;
  }
  svg {
    min-width: 12px;
    min-height: 12px;
  }
`
export const LinhaBandeiras = styled(Linha)`
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
`

export const ContainerBandeiras = styled(Linha)<{ selecionado: boolean }>`
  width: 25%;
  transition: filter 0.2s;
  svg {
    box-shadow: 1px 1px 10px ${Cores.CINZA_1_CLARO};
    transition: box-shadow 0.2s;
  }

  ${({ selecionado }) =>
    !selecionado &&
    css`
      filter: grayscale(100%);

      svg {
        box-shadow: none;
      }
    `}
`
export const Corpo = styled.div`
  width: 500px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${Medias.MOBILE} {
    width: 90%;
  }
  @media ${Medias.MOBILE} {
    height: 600px;
  }
  &:first-child {
    @media ${Medias.MOBILE} {
      margin-top: 0px;
    }
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    @media ${Medias.MOBILE} {
      font-size: 20px;
    }
  }

  form {
    width: 100%;
  }
`

export const CorpoPix = styled.div`
  width: 800px;
  background: ${Cores.BRANCO};
  border: 1px solid ${Cores.BRANCO};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${Medias.MOBILE} {
    width: 90%;
  }
  @media ${Medias.MOBILE} {
    height: 600px;
  }
  &:first-child {
    @media ${Medias.MOBILE} {
      margin-top: 0px;
    }
  }

  h5 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
    @media ${Medias.MOBILE} {
      font-size: 20px;
    }
  }

  form {
    width: 100%;
  }
`

export const CorpoModal = styled(Corpo)`
  height: 640px;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
`

export const CorpoModalPix = styled(CorpoPix)`
  height: 640px;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  @media ${Medias.MOBILE} {
    min-width: 90%;
  }
`

export const ContainerModalPix = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  @media ${Medias.MOBILE} {
    min-width: 90%;
  }
`

export const ContainerPix = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  @media ${Medias.MOBILE} {
    min-width: 90%;
  }
`

export const Titulo = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 6px;
`

export const TextoCopiaECola = styled.p`
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  margin: 3px 0;
`

export const ContainerCarregando = styled.div`
  background: ${Cores.BRANCO};
  padding: 25px 50px;
  border-radius: 8px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  color: ${Cores.PRETO};

  svg {
    circle {
      stroke: ${Cores.PRETO};
    }
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    color: ${Cores.PRETO};
  }
`

export const ContainerBotao = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

export const Margin = styled.div`
  margin: 0 5px;
`

export const ContainerDadosMedio = styled.div`
  width: 50%;
  @media ${Medias.MOBILE} {
    min-width: 100%;
  }
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDadosMedioSelect = styled.div`
  width: 60%;
  @media ${Medias.MOBILE} {
    min-width: 100%;
  }
  &:not(:last-child) {
    margin-right: 19px;
  }
`

export const ContainerDados = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }
`
export const ContainerAcoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${Medias.MOBILE} {
    flex-direction: column-reverse;
  }

  & > button:last-child {
    margin-left: 20px;
    @media ${Medias.MOBILE} {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }
  button {
    @media ${Medias.MOBILE} {
      width: 100%;
    }
  }
`

export const TextoDecorativo = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: black;
  box-sizing: border-box;
  padding-top: 24px;
`

export const OcorrenciasNaoEncontradas = styled(TextoDecorativo)`
  color: ${Cores.SECUNDARIA_2_ESCURO};
`

export const LinkRodapeContainer = styled.div`
  max-width: '97%';
`

export const LinkRodapeComEstilos = styled(LinkRodape)<LinkRodapeProps>`
  & button > span {
    @media ${Medias.MOBILE} {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
    }
  }
`
export const TextoSituacaoFinanceira = styled.p`
  color: ${Cores.SECUNDARIA_2};
`

export const TextoSituacaoResponsavelFinanceiro = styled.p`
  margin-top: 25px;
  font-size: 16px !important;
  font-weight: 500;
  color: ${Cores.SECUNDARIA_2};
`

export const AvisoModalQuantidaeParcelas = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${Cores.PERIGO_ERRO};
`
