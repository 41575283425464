import React, { useRef, useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import Select from 'react-select/async'

import { useField } from '@unform/core'
import classNames from 'classnames'
import debounce from 'debounce-promise'
import deburr from 'lodash/deburr'
import PropTypes from 'prop-types'

import { Botao } from '../../botao'
import { IconeCopiar, IconeCarregando, IconeFechar } from '../../icones'
import {
  Componente,
  Label,
  Grupo,
  Erro,
  CampoReadOnly,
  Clipboard,
  Composicao,
  Obrigatorio
} from './styles'
import { SelectAsyncProps, SelectOpcao } from './tipos'

export const SelectAsyncUnform: React.FC<SelectAsyncProps> = ({
  id,
  name,
  label,
  placeholder,
  buscarPorId,
  buscarPorTexto,
  multiplo,
  alturaMaxima,
  menuAberto,
  readOnly,
  disabled,
  valorAlterado,
  idDependencia,
  maxWidthInput,
  tamanho,
  maxWidthMenu,
  posicao,
  comCopia,
  obrigatorio,
  valorAlteradoMulti,
  ...rest
}) => {
  const cancelado = React.useRef(false)
  const selectRef = useRef(null)
  const [carregando, definirCarregando] = useState<boolean>(false)
  const [temValor, definirTemValor] = useState<boolean>(false)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)
  const primeiroCarregamento = useRef(true)
  const [valorTexto, definirValorTexto] = useState<any>()

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => {
        if (multiplo) {
          if (!selectRef.current.select.state.value) {
            return []
          }
          return selectRef.current.select.state.value.map(
            (option: SelectOpcao) => option.id
          )
        }
        if (!selectRef.current.select.state?.value) {
          return ''
        }
        return selectRef.current.select.state.value?.id ?? null
      },
      setValue: async (_: any, value: any) => {
        const opcoes = await buscarPorId(value)

        selectRef.current.select.select.setValue(multiplo ? opcoes : opcoes[0])
      }
    })
  }, [fieldName, registerField, multiplo])

  useEffect(() => {
    if (primeiroCarregamento.current) return
    selectRef.current.select.select.clearValue()
  }, [idDependencia])

  useEffect(() => {
    if (!defaultValue) return

    const carregarDadosIniciais = async () => {
      const opcoes = await buscarPorId(defaultValue)
      definirValorTexto(opcoes[0])
      if (!cancelado.current) {
        selectRef.current?.select?.select.setValue(
          multiplo ? opcoes : opcoes[0]
        )
      }
    }

    carregarDadosIniciais()

    return () => {
      cancelado.current = true
    }
  }, [])

  useEffect(() => {
    primeiroCarregamento.current = false
  })

  const carregarOpcoes = (texto: string) => {
    definirCarregando(true)
    return new Promise(resolve => {
      buscarPorTexto(texto)
        .then(resolve)
        .catch(() => resolve([]))
        .finally(() => {
          if (!cancelado.current) definirCarregando(false)
        })
    })
  }

  const carregarOpcoesDebounce = debounce(carregarOpcoes, 500)

  const renderizaComCopia = () => {
    return (
      <Composicao>
        <CampoReadOnly id={`sp-${id}`} temValor={!!valorTexto?.texto}>
          {valorTexto?.texto ?? ''}
        </CampoReadOnly>

        {carregando && (
          <span className="icone_carregando">{IconeCarregando}</span>
        )}
        <Clipboard>
          <Botao
            id={`btn_clip-board-sp-${id}`}
            tooltip={{ id: 'tooltip-top', texto: 'Copiar', lado: 'right' }}
            tema="Outro"
            elemento={IconeCopiar}
            onClick={() => {
              const valor = document.getElementById(
                `sp-${id}`
              ) as HTMLDivElement
              navigator.clipboard.writeText(valor.textContent)
            }}
          />
        </Clipboard>
      </Composicao>
    )
  }

  const renderizarIconeFechar = () => (
    <span
      className="icone_fechar"
      onClick={() => {
        selectRef.current.select.select.clearValue()
      }}
    >
      {IconeFechar}
    </span>
  )

  const renderizarIconeCarregando = () => (
    <span
      className="icone_fechar"
      onClick={() => {
        selectRef.current.select.select.clearValue()
      }}
    >
      {IconeCarregando}
    </span>
  )

  return (
    <Componente
      erro={error}
      readOnly={readOnly}
      disabled={disabled}
      tamanho={tamanho}
      maxWidthMenu={maxWidthMenu}
      posicao={posicao}
      {...rest}
    >
      {label && (
        <Label readOnly={readOnly} htmlFor={id}>
          {obrigatorio ? <Obrigatorio>*</Obrigatorio> : <></>}
          {label}
        </Label>
      )}
      <Grupo
        maxWidthInput={maxWidthInput}
        readOnly={readOnly}
        comCopia={comCopia}
        id={id}
      >
        <Select
          menuIsOpen={readOnly ? false : menuAberto}
          cacheOptions
          defaultOptions
          id={id}
          key={`${id}${!idDependencia ? '' : idDependencia}`}
          ref={selectRef}
          className={classNames({ error })}
          classNamePrefix="react-select"
          isClearable={!readOnly && !disabled}
          noOptionsMessage={() => 'Nenhuma opção para pesquisa'}
          loadingMessage={() => 'Carregando ...'}
          backspaceRemovesValue={!readOnly}
          menuPlacement="auto"
          loadOptions={carregarOpcoesDebounce}
          onChange={valor => {
            clearError()
            definirTemValor(!!valor)
            if (valor) {
              if (multiplo) {
                const valores = Array.from(valor as Iterable<SelectOpcao>)
                valorAlteradoMulti && valorAlteradoMulti([...valores])
                return
              } else {
                valorAlterado && valorAlterado({ ...valor } as SelectOpcao)
                return
              }
            }

            valorAlteradoMulti && valorAlteradoMulti([])
            valorAlterado && valorAlterado(null)
          }}
          placeholder={!readOnly ? placeholder || 'Selecione ...' : ''}
          isMulti={multiplo}
          getOptionLabel={(opcao: SelectOpcao) => opcao.texto}
          formatOptionLabel={({ texto }, { inputValue }) => (
            <Highlighter
              searchWords={[inputValue]}
              textToHighlight={texto}
              sanitize={deburr}
            />
          )}
          getOptionValue={(opcao: SelectOpcao) => opcao.id}
          maxMenuHeight={alturaMaxima}
          isSearchable={!readOnly}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9 }),
            dropdownIndicator: old =>
              readOnly || disabled ? { ...old, display: 'none' } : old,
            valueContainer: base => ({
              ...base,
              maxHeight: '100px',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            })
          }}
        />
        {(carregando || !temValor) && renderizarIconeCarregando()}
        {temValor &&
          !readOnly &&
          !disabled &&
          !carregando &&
          renderizarIconeFechar()}
        {temValor && readOnly && comCopia && !carregando && renderizaComCopia()}
      </Grupo>

      <Erro id={`erro-${id}`} className="erro">
        {error}
      </Erro>
    </Componente>
  )
}

SelectAsyncUnform.defaultProps = {
  label: undefined,
  placeholder: 'Selecione ...',
  multiplo: false,
  'data-testid': undefined,
  className: undefined,
  alturaMaxima: undefined,
  menuAberto: undefined,
  readOnly: false,
  disabled: false,
  valorAlterado: undefined,
  idDependencia: null,
  maxWidthInput: null,
  tamanho: null,
  comCopia: false
}

SelectAsyncUnform.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  buscarPorId: PropTypes.func.isRequired,
  buscarPorTexto: PropTypes.func.isRequired,
  multiplo: PropTypes.bool,
  menuAberto: PropTypes.bool,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  alturaMaxima: PropTypes.number,
  readOnly: PropTypes.bool,
  comCopia: PropTypes.bool,
  disabled: PropTypes.bool,
  valorAlterado: PropTypes.func,
  idDependencia: PropTypes.string,
  maxWidthInput: PropTypes.string,
  tamanho: PropTypes.oneOf(['S', 'M', 'L']),
  posicao: PropTypes.oneOf(['direita', 'esquerda']),
  maxWidthMenu: PropTypes.string
}
