import { toast } from 'react-toastify'

import jwtDecode from 'jwt-decode'
import queryString from 'query-string'
import {
  ObterUsuarioDTO,
  PesquisaUsuarioDTO,
  PesquisaUsuariosResposta
} from 'src/dto'
import {
  OpcoesPaginacao,
  RespostaCriarUsuarioAPI,
  PainelInicialDoAluno,
  RespostaPadraoAPI,
  RespostaPadraoDadosAPI,
  TipoOrdenacao,
  Usuario,
  UsuarioPerfil,
  UsuarioSituacao,
  RespostaCarregarFoto,
  CpfCnpjUsuario,
  NotificacoesDoAluno,
  MarcaLidoNotificacoesDoAluno
} from 'src/tipos'

import { gatewayApi, getApiError } from '../instancias-api'
import { UsuarioAlteracaoEmail } from './tipos'

const urlBase = '/usuario'

export const RequisitarListaUsuarios = async (
  nome: string,
  email: string,
  situacao: keyof typeof UsuarioSituacao,
  cpf: string,
  docIdentidade: string,
  perfis: Array<keyof typeof UsuarioPerfil>,
  pagina: number,
  quantidadeRegistros: number,
  chaveOrdenacao?: string,
  tipoOrdenacao?: TipoOrdenacao
): Promise<{
  registros: PesquisaUsuarioDTO[]
  opcoesPaginacao: OpcoesPaginacao
}> => {
  try {
    let campoOrdenacao = chaveOrdenacao

    if (chaveOrdenacao === 'nomeApresentacao') campoOrdenacao = 'nome'

    const ordem = chaveOrdenacao
      ? `${campoOrdenacao}${
          tipoOrdenacao === TipoOrdenacao.Crescente ? '' : '_Desc'
        }`
      : undefined

    const filtros = queryString.stringify({
      nome,
      email,
      cpf,
      docIdentidade,
      perfis,
      situacao: situacao === 'Todas' ? null : situacao,
      quantidadeRegistros,
      pagina,
      ordem
    })

    const {
      data: {
        data: { itens, quantidade }
      }
    } = await gatewayApi.get<PesquisaUsuariosResposta>(`${urlBase}?${filtros}`)

    const opcoesPaginacao = {
      pagina,
      tamanhoPagina: quantidadeRegistros,
      totalRegistros: quantidade,
      totalPaginas: Math.ceil(quantidade / quantidadeRegistros)
    }
    return { registros: itens, opcoesPaginacao }
  } catch {
    return null
  }
}

export const RequisitarUsuario = async (id: string): Promise<Usuario> => {
  const {
    data: { data }
  } = await gatewayApi.get<RespostaPadraoDadosAPI<Usuario>>(`${urlBase}/${id}`)

  return data
}

export const RequisitarUsuarioPorCpf = async (
  cpf: string
): Promise<ObterUsuarioDTO> => {
  try {
    const { data: dados } = await gatewayApi.get<
      RespostaPadraoDadosAPI<ObterUsuarioDTO>
    >(`${urlBase}/${cpf}`)

    return dados.data
  } catch (_) {
    return null
  }
}

export const RequisitarUsuarioPorEmail = async (
  email: string
): Promise<Usuario> => {
  const { data: dados } = await gatewayApi.get<RespostaPadraoDadosAPI<Usuario>>(
    `${urlBase}/email/${email}`
  )

  return dados.data
}

export const InserirUsuario = async (
  payload: Usuario
): Promise<RespostaCriarUsuarioAPI> => {
  const {
    data: { success, data }
  } = await gatewayApi.post<RespostaCriarUsuarioAPI>(`${urlBase}/`, payload)

  return { success, data }
}

export const EditarUsuario = async (
  id: string,
  payload: Usuario
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(`${urlBase}/${id}`, payload)

  return success
}

export const ObterIdUsuario = async (token: string): Promise<string> => {
  try {
    const { sub: id } = jwtDecode<{
      sub: string
    }>(token)
    return id
  } catch (_) {
    return undefined
  }
}

export const ObterPainelInicialDoAluno = async (
  idDoUsuario: string
): Promise<PainelInicialDoAluno> => {
  const url = `${urlBase}/${idDoUsuario}/painel-inicial-aluno`
  const { data } = await gatewayApi.get<PainelInicialDoAluno>(url)

  return data
}

export const ObterNotificacoesAluno = async (
  idDoUsuario: string
): Promise<NotificacoesDoAluno> => {
  const url = `${urlBase}/${idDoUsuario}/notificacoes`
  const { data } = await gatewayApi.get<NotificacoesDoAluno>(url)

  return data
}

export const MarcarLidaNotificacoesAluno = async (
  idDoUsuario: string,
  titulo: string
): Promise<MarcaLidoNotificacoesDoAluno> => {
  const url = `${urlBase}/${idDoUsuario}/notificacoes/marcar-lido`

  const { data } = await gatewayApi.post<MarcaLidoNotificacoesDoAluno>(url, {
    titulo
  })

  return data
}

export const ObterFotoUsuario = async (
  idUsuario: string
): Promise<string | void> => {
  const url = `${urlBase}/${idUsuario}/foto`
  const resposta = await gatewayApi.get<RespostaCarregarFoto>(url)
  if (resposta.status !== 200) return null
  return resposta.data.foto
}

export const VerificarUsuarioPorEmail = async (
  email: string
): Promise<boolean> => {
  try {
    const { data: dados } = await gatewayApi.get<
      RespostaPadraoDadosAPI<boolean>
    >(`${urlBase}/email-existente/${email}`)

    return dados.data
  } catch {
    return false
  }
}
export const VerificarUsuarioPorCpf = async (cpf: string): Promise<boolean> => {
  try {
    const { data: dados } = await gatewayApi.get<
      RespostaPadraoDadosAPI<boolean>
    >(`${urlBase}/cpf-existente/${cpf}`)

    return dados.data
  } catch {
    return false
  }
}

export const VerificarUsuarioResponsavel = async (
  identificador: string
): Promise<ObterUsuarioDTO> => {
  try {
    const { data: dados } = await gatewayApi.get<ObterUsuarioDTO>(
      `${urlBase}/responsavel-financeiro/validar-usuario-pessoa/${identificador}`
    )

    return dados
  } catch (_) {
    return null
  }
}

export const VerificarUsuarioResponsavelPJ = async (
  cnpj: string
): Promise<ObterUsuarioDTO> => {
  try {
    const { data: dados } = await gatewayApi.get<ObterUsuarioDTO>(
      `${urlBase}/responsavel-financeiro/validar-usuario-empresa/${cnpj}`
    )

    return dados
  } catch (_) {
    return null
  }
}

export const ObterDadosUsuario = async (): Promise<Usuario> => {
  const { data: dados } = await gatewayApi.get<RespostaPadraoDadosAPI<Usuario>>(
    `${urlBase}/dados`
  )

  return dados.data
}

export const EnviarDadosUsuario = async (usuario: Usuario): Promise<void> => {
  await gatewayApi.put(`${urlBase}/dados`, usuario)
}

export const AtualizarCpfCnpjUsuario = async (
  payload: CpfCnpjUsuario
): Promise<boolean> => {
  const {
    data: { success }
  } = await gatewayApi.put<RespostaPadraoAPI>(
    `${urlBase}/atualizar-cpf-cnpj`,
    payload
  )

  return success
}

export const AcessoAlteracaoSenha = async (
  idUsuario: string
): Promise<UsuarioAlteracaoEmail> => {
  try {
    const { data } = await gatewayApi.get<UsuarioAlteracaoEmail>(
      `${urlBase}/${idUsuario}/alterar-email/acesso`
    )

    return data
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }

    return null
  }
}

export const RequisitarDadosAlteracao = async (
  idUsuario: string,
  tokenAcesso: string
): Promise<any> => {
  try {
    const { data } = await gatewayApi.get<any>(
      `${urlBase}/alterar-dados-usuario?UsuarioId=${idUsuario}&TokenAcesso=${tokenAcesso}`
    )

    return data
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }

    return null
  }
}

export const RequisitarAlteracaoDados = async (
  idUsuario: string,
  codigo: string,
  tokenAcesso: string,
  tokenEmail: string
): Promise<any> => {
  try {
    const { data } = await gatewayApi.put<any>(
      `${urlBase}/${idUsuario}/alterar-dados-usuario`,
      {
        usuarioId: idUsuario,
        tokenAcesso: tokenAcesso,
        tokenSMS: codigo,
        tokenEmail: tokenEmail
      }
    )

    return data
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }

    return null
  }
}

export const AlterarEmail = async (
  idUsuario: string,
  senha: string,
  token: string
): Promise<{ sucesso: boolean; tentativasRestantes: number }> => {
  try {
    const { data } = await gatewayApi.put<{
      sucesso: boolean
      tentativasRestantes: number
    }>(`${urlBase}/${idUsuario}/alterar-email`, {
      senha,
      token
    })

    return data
  } catch (ex) {
    if (ex.response?.status === 401) {
      getApiError(ex).then(result => {
        const mensagens = result.join('\n')

        toast(mensagens, {
          type: 'error'
        })
      })
    }
    return null
  }
}

export const RenovarToken = async (usuarioId: string): Promise<any> => {
  try {
    const dados = await gatewayApi.put(`${urlBase}/${usuarioId}/reenviar-token`)

    return dados
  } catch (_) {
    return null
  }
}
