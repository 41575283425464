import React, { useRef, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  Botao,
  Carregando,
  FormRef,
  FormUnform,
  IconeAtencao,
  PerguntaLinear,
  PerguntaParagrafo,
  PerguntaRespostaUnica,
  validarPesquisa
} from 'src/componentes'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import { Api } from 'src/servicos'
import {
  PesquisaSatisfacao,
  PesquisaSatisfacaoSalaAulaLivreResposta,
  PesquisaSatisfacaoRespostaPergunta,
  TipoPesquisaSatisfacaoPergunta
} from 'src/tipos'

import {
  Container,
  ContainerBotaoPesquisaSatisfacao
} from '../../shared/styles'
import SalaAulaCursoLivreContainer from '../container'
import { ContainerPergunta, Conteudo, SubTitulo, Titulo } from './styles'

export const PesquisaSatisfacaoSalaAulaLivre: React.FC = () => {
  const formRef = useRef<FormRef>()
  const [
    pesquisaSatisfacao,
    definirPesquisaSatisfacao
  ] = useState<PesquisaSatisfacao>({} as PesquisaSatisfacao)
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const [carregando, definirCarregando] = useState<boolean>(false)
  const {
    salaAula,
    definirAtualizarSala
  } = SalaAulaCursoLivreContainer.useContainer()

  const acaoSucesso = async (dados: any) => {
    try {
      definirCarregando(true)

      if (!validarPesquisa(formRef, dados)) return

      const respostas = pesquisaSatisfacao.perguntas.map(item => {
        if (item.tipo === TipoPesquisaSatisfacaoPergunta.Paragrafo) {
          return {
            perguntaId: item.id,
            respostaParagrafo: dados.perguntaParagrafo[item.id] ?? null
          } as PesquisaSatisfacaoRespostaPergunta
        } else {
          return {
            perguntaId: item.id,
            alternativaId: dados.pergunta[item.id]
          } as PesquisaSatisfacaoRespostaPergunta
        }
      })

      const payload = {
        pesquisaSatisfacaoId: pesquisaSatisfacao.id,
        salaAulaLivreId: salaAula.id,
        respostas: respostas
      } as PesquisaSatisfacaoSalaAulaLivreResposta
      await Api.EnviarRespostaPesquisaSatisfacaoSalaAulaLivre(payload)
      definirAtualizarSala(true)
    } catch {
      toast('Erro ao salvar resposta pesquisa satisfação', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const carregarPesquisaSatisfacao = async () => {
    try {
      definirPronto(false)
      definirPesquisaSatisfacao({} as PesquisaSatisfacao)
      const dados = await Api.RequisitarPesquisaSatisfacao(
        salaAula.pesquisaSatisfacaoId
      )
      definirPesquisaSatisfacao(dados)
    } catch {
      definirErro('Erro ao carregar pesquisa satisfação')
    } finally {
      definirPronto(true)
    }
  }

  useEffect(() => {
    carregarPesquisaSatisfacao()
  }, [salaAula.pesquisaSatisfacaoId])

  return (
    <>
      {pronto && !erro ? (
        <>
          <Container>
            <Titulo>Pesquisa de Satisfação</Titulo>
            <SubTitulo>{pesquisaSatisfacao.nome}</SubTitulo>
            <FormUnform ref={formRef} acaoSucesso={acaoSucesso}>
              {pesquisaSatisfacao?.perguntas?.map(pergunta => (
                <ContainerPergunta
                  key={pergunta.id}
                  className={`pergunta[${pergunta.id}]`}
                >
                  {pergunta.tipo ===
                    TipoPesquisaSatisfacaoPergunta.Paragrafo && (
                    <PerguntaParagrafo
                      pergunta={pergunta}
                      name={`perguntaParagrafo[${pergunta.id}]`}
                    />
                  )}
                  {pergunta.tipo ===
                    TipoPesquisaSatisfacaoPergunta.EscalaLinear && (
                    <PerguntaLinear
                      pergunta={pergunta}
                      name={`pergunta[${pergunta.id}]`}
                      tipo={pesquisaSatisfacao.tipo}
                    />
                  )}
                  {pergunta.tipo ===
                    TipoPesquisaSatisfacaoPergunta.RespostaUnica && (
                    <PerguntaRespostaUnica
                      pergunta={pergunta}
                      name={`pergunta[${pergunta.id}]`}
                    />
                  )}
                </ContainerPergunta>
              ))}
              <ContainerBotaoPesquisaSatisfacao>
                <Botao
                  type="submit"
                  id="btn-salvar"
                  texto="Enviar"
                  carregando={carregando}
                />
              </ContainerBotaoPesquisaSatisfacao>
            </FormUnform>
          </Container>
        </>
      ) : !pronto && !erro ? (
        <Conteudo>
          <Carregando texto="Carregando Pesquisa de Satisfação ..." />
        </Conteudo>
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={carregarPesquisaSatisfacao}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}
    </>
  )
}
