import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { toast } from 'react-toastify'

import { Api } from 'src/servicos'
import { BoletoGerado } from 'src/servicos/api/tipos'

import {
  Botao,
  IconeCarregando,
  ModalBase,
  PdfViewer
} from '../../../../../src/componentes'
import { ModalPagamentoRefPix, ModalPagamentoPropsPix } from '../tipos'
import {
  ContainerBotao,
  ContainerCarregando,
  ContainerModalPix,
  ContainerPix,
  CorpoModalPix,
  Margin,
  TextoCopiaECola,
  Titulo
} from './styles'

const ModalPagamentoPix: React.ForwardRefRenderFunction<
  ModalPagamentoRefPix,
  ModalPagamentoPropsPix
> = ({ backdrop }, ref) => {
  const [aberta, definirAberta] = useState(false)
  const [boleto, definirBoleto] = useState<BoletoGerado | undefined>(undefined)
  const [pixCopiaECola, definirPixCopiaECola] = useState('')
  const [pdfKey, definirPdfKey] = useState(0)

  const [pronto, definirPronto] = useState(false)

  const fechar = () => {
    definirAberta(false)
    definirPronto(false)
    definirBoleto(undefined)
    definirPdfKey(0)
    definirPixCopiaECola('')
  }

  const recarregarPdf = () => {
    definirPdfKey(prevKey => prevKey + 1)
  }

  const gerarPix = async (cobrancaId: string, matricula: string) => {
    try {
      definirPronto(false)

      const resposta = await Api.ObterBoletoParaPagamentoDoCurso(
        matricula,
        cobrancaId,
        'pix'
      )
      if (resposta) {
        definirBoleto(resposta)
      }
    } catch (erro) {
      definirPronto(true)
      toast('Houve um problema ao obter o boleto', {
        type: 'error'
      })
    }
  }

  const abrir = (cobrancaId: string, matricula: any) => {
    definirAberta(true)

    if (!boleto) {
      gerarPix(cobrancaId, matricula)
    }
  }

  useImperativeHandle<ModalPagamentoRefPix, ModalPagamentoRefPix>(ref, () => ({
    fechar,
    abrir
  }))

  const getBoletoUrl = (url: string) => {
    const sliceCounter =
      url.indexOf('?formato=') > 0 ? url.indexOf('?formato=') : url.length
    return `${url.slice(0, sliceCounter)}?formato=pix`
  }

  useEffect(() => {
    if (boleto && boleto.boletoId) {
      const pegarQrCode = async () => {
        if (boleto.boletoId !== undefined) {
          const resposta = await Api.pegarQrCodeBoleto(boleto.boletoId)
          if (resposta.qrCode) {
            definirPixCopiaECola(resposta.qrCode)
          }

          definirPronto(true)
        }
      }

      pegarQrCode()
    }
  }, [boleto, recarregarPdf])

  useEffect(() => {
    if (pixCopiaECola) {
      definirBoleto(prevBoleto => ({
        ...prevBoleto,
        pixCopiaECola
      }))
    }
  }, [pixCopiaECola])

  return (
    <ModalBase id="modal-pagamento" aberta={aberta} backdrop={backdrop}>
      <CorpoModalPix>
        <ContainerModalPix>
          {pronto ? (
            <>
              <ContainerPix>
                <Titulo>
                  PIX gerado com sucesso!
                  <br />
                  Realize o pagamento via Pix em qualquer banco em até 2 horas.
                </Titulo>
                {!pixCopiaECola ? (
                  <TextoCopiaECola>
                    Se o código Copia e Cola ou o QR Code não apareceram para
                    você, espere alguns instantes e clique em atualizar!
                  </TextoCopiaECola>
                ) : (
                  <TextoCopiaECola>{pixCopiaECola}</TextoCopiaECola>
                )}
                <Botao
                  tema="Link"
                  texto={'Copiar Código do PIX'}
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(pixCopiaECola)
                  }}
                />
                {!!boleto?.linkBoleto && (
                  <PdfViewer
                    key={pdfKey}
                    url={getBoletoUrl(boleto?.linkBoleto)}
                    altura="450px"
                  />
                )}
              </ContainerPix>
              <ContainerBotao>
                <Botao
                  texto="Atualizar"
                  tema="Primario"
                  type="button"
                  onClick={() => {
                    recarregarPdf()
                  }}
                />
                <Margin />
                <Botao
                  texto="Fechar"
                  tema="Secundario"
                  type="button"
                  onClick={() => {
                    fechar()
                  }}
                />
              </ContainerBotao>
            </>
          ) : (
            <ContainerCarregando>
              {IconeCarregando}
              <p>Gerando seu pix...</p>
            </ContainerCarregando>
          )}
        </ContainerModalPix>
      </CorpoModalPix>
    </ModalBase>
  )
}

export const ModalPagamentoParaPix = forwardRef(ModalPagamentoPix)
