import React, { useMemo } from 'react'

import { ModalidadeCurso } from 'src/tipos'

import { FuncoesDataHora } from '../funcoes'
import { IconeFechar } from '../icones'
import { Container, ContainerNomeBotao } from './styles'
import { ResumoCursoProps } from './tipos'

export const ResumoCurso: React.FC<ResumoCursoProps> = ({
  curso,
  eventoExcluirCarrinho
}) => {
  const descricao = useMemo(() => {
    const descs = []

    if (curso?.codigoTurma) descs.push(`Turma ${curso?.codigoTurma}`)
    if (
      curso?.dataInicioTurma &&
      curso?.modalidade !== ModalidadeCurso.PosGraduacaoEadFull
    ) {
      descs.push(
        `Data de Início: ${FuncoesDataHora.novaData(curso?.dataInicioTurma)}`
      )
    }
    if (
      curso?.dataFimTurma &&
      curso?.modalidade !== ModalidadeCurso.PosGraduacaoEadFull
    ) {
      descs.push(
        `Data de Fim: ${FuncoesDataHora.novaData(curso?.dataFimTurma)}`
      )
    }

    return descs.join(' - ')
  }, [curso])

  return (
    <Container>
      <ContainerNomeBotao>
        <h4>{curso?.nome}</h4>
        {eventoExcluirCarrinho && (
          <button
            type="button"
            data-tip="Remover"
            data-for={`${curso?.id}-tooltip-remover-carrinho`}
            onClick={() => eventoExcluirCarrinho(curso?.id)}
          >
            {IconeFechar}
          </button>
        )}
      </ContainerNomeBotao>

      {!!curso?.cargaHoraria && (
        <p>{`Carga Horária: ${curso?.cargaHoraria}h`}</p>
      )}
      {!!curso?.duracao && (
        <p>{`Duração: ${curso?.duracao} ${
          curso?.duracao > 1 ? 'meses' : 'mês'
        }`}</p>
      )}
    </Container>
  )
}
