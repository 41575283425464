import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { hotjar } from 'react-hotjar'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Botao,
  Breadcrumb,
  Carregando,
  ContainerAcoes,
  ErroContainerLayout,
  ErroLayoutContainer,
  IconeGerarBoleto,
  IconeInformacao,
  IconeModalSucesso,
  IconePagamentoMatricula,
  IconeRecibo,
  IconeDeAtualizacaoDePlanoDePagamento,
  Modal,
  ModalRef,
  Tabela,
  TabelaDados,
  TabelaRef,
  TabelaResposta,
  TemaLinha,
  Tooltip,
  AutenticacaoContainer,
  IconeFinanceiro,
  ModalPagamentoCartaoRecorrente,
  Visao,
  Accordion,
  Spacer,
  ModalVisualizacaoPdfRef,
  ModalVisualizacaoPdf,
  formatarCpf,
  formatarCEP,
  formatarTelefone,
  formatarCNPJ,
  IconePix
} from 'src/componentes'
import { Linha } from 'src/paginas/matricula/styles'
import { RotasAluno } from 'src/rotas/aluno'
import { RotasResponsavelFinanceiro } from 'src/rotas/responsavel-financeiro'
import { Api } from 'src/servicos'
import { DadosCartao } from 'src/servicos/api/tipos'
import { getApiError } from 'src/servicos/instancias-api'
import {
  BaixaManualCobranca,
  DadosFinanceirosDoCurso,
  DetalheDaParcela,
  ModalidadeCurso,
  SituacaoDaCompraComCartaoDeCredito,
  SituacaoParcelaPosVencimento,
  AlterarCobranca,
  BaixaManualTaxaMatricula,
  SituacaoFinanceira,
  BaixaManualTaxaTrocaCurso,
  TipoCobranca,
  MetodosPagamento,
  SituacaoResponsavelFinanceiro,
  SituacaoResponsavelFinanceiroPorExtenso,
  SituacaoUpload,
  NacionalidadeFormatada,
  PaisResidenciaFormatado,
  StatusRecorrencia,
  DetalheDaParcelaDaRecuperacao,
  BaixaManualTaxaReingresso
} from 'src/tipos'
import {
  SituacaoParcela,
  SituacaoParcelaFiltro
} from 'src/tipos/enums/situacaoParcela'

import { ModalPagamentoCartao } from '../componentes'
import { ModalPagamentoParaPix } from '../componentes/modal-pagamento-pix'
import {
  ContainerBotao,
  ContainerBotaoVoltar,
  ContainerInfo,
  ConteudoBotao,
  CursoContainer,
  IconeTabela,
  ContainerNovaCompra,
  ContainerBotaoAlteracaoCobranca,
  ContainerResponsavel,
  ContainerBotoesResponsavel,
  AvisoResponsavel
} from '../styles'
import {
  DadosNovaCompra,
  FinanceiroProps,
  ModalPagamentoRef,
  ModalPagamentoRefPix,
  TipoCobrancaAcao
} from '../tipos'
import {
  ModalAtualizarDataCobranca,
  ModalAtualizarDataCobrancaDados,
  ModalAtualizarPlanoDePagamento,
  ModalBaixaManualCobranca,
  ModalBaixaManualCobrancaRef,
  ModalRenegociacao,
  ModalNovaCompra,
  ModalNovaCompraRef,
  ModalCancelarRecorrencia,
  ModalBaixaManualTaxaTrocaCursoRef,
  ModalBaixaManualTaxaTrocaCurso,
  ModalAlterarObservacao
} from './componentes'
import { ModalAceiteResponsavel } from './componentes/modal-aceite-responsavel'
import { ModalAlterarObservacaoRef } from './componentes/modal-alterar-observacao/tipos'
import {
  ModalAtualizarPlanoDePagamentoDados,
  ModalAtualizarPlanoDePagamentoRef
} from './componentes/modal-atualizar-plano-de-pagamento/modal-atualizar-plano-de-pagamento.component'
import {
  ModalBaixaManualTaxaMatricula,
  ModalBaixaManualTaxaMatriculaRef
} from './componentes/modal-baixa-manual-taxa-matricula'
import {
  ModalBaixaManualTaxaReingresso,
  ModalBaixaManualTaxaReingressoRef
} from './componentes/modal-baixa-manual-taxa-reingresso'
import { ModalCancelarCobranca } from './componentes/modal-cancelar-cobranca'
import {
  ModalReciboAnual,
  ModalReciboAnualRef
} from './componentes/modal-recibo-anual'
import { ModalRecusaResponsavel } from './componentes/modal-recusa-responsavel'
import { ModalReembolsoCobranca } from './componentes/modal-reembolso-parcela'
import { ModalRenegociacaoRef } from './componentes/modal-renegociacao/tipos'
import { formatadores } from './formatadores'
import { FormularioDePagamentoMapper } from './formulario-de-pagamento-mapper'
import {
  ContainerItemAcoes,
  ContainerParcelas,
  ContainerTabela,
  ParagrafoComEstilos,
  ImprimirReciboContainer,
  ParagrafoDoBotaoDeAcao,
  ContainerTitulo,
  TabelaComEstilos,
  ParagrafoDeAviso,
  TooltipParcelaEmAtrasoComEstilos,
  TooltipParcelaObservacaoComEstilos,
  ContainerDoValorDaTabela,
  BotaoDeAtualizacaoDoPlanoDePagamento,
  ParagrafoDoSaldoDevedor,
  Titulo,
  ContainerBotaoCartaoRecorrencia,
  TextoRecorrenciaFinalizada
} from './styles'

const mensagemDeErroDoCartaoDeCredito =
  'Não foi possível efeturar o pagamento, tente novamente.'

const mensagemDeErroRecibo = 'Erro ao obter recibo'

const mensagemDeErroCobrarMatriculaPorBoleto =
  'Houve um problema ao cobrar a taxa da matrícula por boleto'

const mensagemDeErroCobrarReingressoPorBoleto =
  'Houve um problema ao cobrar a taxa do reingresso por boleto'

const erroAoAtualizarPlanoDePagamento =
  'Não foi possível atualizar o plano de pagamento.'

const erroAoAlterarParcelas = 'Não foi possível alterar a(s) parcelas.'

const erroNovaCompra = 'Não foi possível criar nova compra.'

const mensagemErroCartaoCredito =
  'Erro ao efetivar pagamento recorrente, informe os dados do cartão novamente.'

const Outros: FC<FinanceiroProps> = ({ match }) => {
  const { matricula, modalidade, nomeDoCurso } = match.params

  const history = useHistory()
  const { limparErro, definirErro, erro } = ErroLayoutContainer.useContainer()
  const { perfil, visao } = AutenticacaoContainer.useContainer()

  const tabelaRef = useRef<TabelaRef>()
  const tabelaRecupRef = useRef<TabelaRef>()
  const tabelaTCCRef = useRef<TabelaRef>()
  const tabelaProrrogacaoRef = useRef<TabelaRef>()
  const modalRef = useRef<ModalPagamentoRef>(null)
  const modalRefPix = useRef<ModalPagamentoRefPix>(null)

  const modalSucesso = useRef<ModalRef>(null)
  const modalAtualizarPlanoDePagamentoRef = useRef<ModalAtualizarPlanoDePagamentoRef>(
    null
  )
  const modalAtualizarDataCobrancaRef = useRef<ModalRef>(null)
  const modalBaixaManualCobrancaRef = useRef<ModalBaixaManualCobrancaRef>(null)
  const modalBaixaManualTaxaMatriculaRef = useRef<ModalBaixaManualTaxaMatriculaRef>(
    null
  )
  const modalBaixaManualTaxaReingressoRef = useRef<ModalBaixaManualTaxaReingressoRef>(
    null
  )
  const modalBaixaManualTaxaTrocaCursoRef = useRef<ModalBaixaManualTaxaTrocaCursoRef>(
    null
  )
  const modalRenegociacaoRef = useRef<ModalRenegociacaoRef>(null)
  const modalCancelarCobranca = useRef<ModalRef>(null)
  const modalReembolsoCobranca = useRef<ModalRef>(null)
  const modalNovaCompraRef = useRef<ModalNovaCompraRef>(null)
  const modalCartaoRecorrenciaRef = useRef<ModalRef>(null)
  const modalCancelarRecorrenciaRef = useRef<ModalRef>(null)
  const modalAlterarObservacaoRef = useRef<ModalAlterarObservacaoRef>(null)
  const modalVisualizacaoRef = useRef<ModalVisualizacaoPdfRef>(null)
  const modalRecusaResponsavelRef = useRef<ModalRef>(null)
  const modalAceiteResponsavelRef = useRef<ModalRef>(null)
  const modalReciboAnualRef = useRef<ModalReciboAnualRef>(null)

  const [
    cenarioFinanceiro,
    definirCenarioFinanceiro
  ] = useState<DadosFinanceirosDoCurso>()
  const [cobranca, definirCobranca] = useState<string>()
  const [pronto, definirPronto] = useState(false)
  const [itensCursoSelecionados, definirItensCursosSelecionados] = useState<
    string[]
  >([])
  const [itensTCCSelecionados, definirItensTCCSelecionados] = useState<
    string[]
  >([])
  const [
    situacaoItensTCCSelecionados,
    definirSituacaoItensTCCSelecionados
  ] = useState<string[]>([])
  const [
    situacaoItensProrrogacaoSelecionados,
    definirSituacaoItensProrrogacaoSelecionados
  ] = useState<string[]>([])
  const [
    situacaoItensCursoSelecionados,
    definirSituacaoItensCursoSelecionados
  ] = useState<string[]>([])
  const [
    itensProrrogacaoSelecionados,
    definirItensProrrogacaoSelecionados
  ] = useState<string[]>([])
  const [
    itensRecuperacaoSelecionados,
    definirItensRecuperacaoSelecionados
  ] = useState<string[]>([])
  const [
    situacaoItensRecuperacaoSelecionados,
    definirSituacaoItensRecuperacaoSelecionados
  ] = useState<string[]>([])
  const [
    tipoCobrancaAcao,
    definirTipoCobrancaAcao
  ] = useState<TipoCobrancaAcao>(null)

  const [carregando, definirCarregando] = useState<boolean>(false)

  const naoEhLivre = useMemo(() => modalidade !== ModalidadeCurso.Livre, [
    modalidade
  ])

  const ehPerfilFinanceiro = useMemo(
    () => [...perfil?.perfis].findIndex(p => p === 'Financeiro') > -1,
    [perfil?.perfis]
  )

  const ehRecorrente = useMemo(
    () => cenarioFinanceiro?.detalhesCurso?.pagamentoRecorrente,
    [cenarioFinanceiro]
  )

  const aceiteResponsavel = useMemo(
    () =>
      cenarioFinanceiro?.detalhesResponsavelFinanceiro?.situacaoResponsavel ===
        SituacaoResponsavelFinanceiroPorExtenso.Aceito ||
      cenarioFinanceiro?.detalhesResponsavelFinanceiro?.situacaoResponsavel ===
        SituacaoResponsavelFinanceiroPorExtenso.Recusado,
    [cenarioFinanceiro?.detalhesResponsavelFinanceiro]
  )

  const documentoResponsavelRecusado = useMemo(
    () =>
      cenarioFinanceiro?.detalhesResponsavelFinanceiro?.documentoResponsavel
        ?.situacaoUpload === SituacaoUpload.Recusado,
    [cenarioFinanceiro?.detalhesResponsavelFinanceiro]
  )

  const documentoResponsavelAnalise = useMemo(
    () =>
      cenarioFinanceiro?.detalhesResponsavelFinanceiro?.documentoResponsavel
        ?.situacaoUpload === SituacaoUpload.AguardandoAnalise,
    [cenarioFinanceiro?.detalhesResponsavelFinanceiro]
  )

  const dataLimiteEnvio = formatadores?.dataLocaleString(
    cenarioFinanceiro?.dataLimiteEnvioDocumento
  )

  const visaoAluno = useMemo(() => visao === Visao.Aluno, [visao])
  const visaoResponsavel = useMemo(
    () => visao === Visao.ResponsavelFinanceiro,
    [visao]
  )
  const visaoIPGS = useMemo(() => visao === Visao.IPGS, [visao])

  const parcelasCursoSelecionadas = useMemo(
    () => itensCursoSelecionados?.length > 0,
    [itensCursoSelecionados]
  )

  const parcelasRecuperacaoSelecionadas = useMemo(
    () => itensRecuperacaoSelecionados?.length > 0,
    [itensRecuperacaoSelecionados]
  )

  const parcelasTCCSelecionadas = useMemo(
    () => itensTCCSelecionados?.length > 0,
    [itensTCCSelecionados]
  )

  const parcelasProrrogacaoSelecionadas = useMemo(
    () => itensProrrogacaoSelecionados?.length > 0,
    [itensProrrogacaoSelecionados]
  )

  const parcelasSelecionadas = useMemo(() => {
    return (
      parcelasCursoSelecionadas ||
      parcelasTCCSelecionadas ||
      parcelasProrrogacaoSelecionadas ||
      parcelasRecuperacaoSelecionadas
    )
  }, [
    parcelasCursoSelecionadas,
    parcelasTCCSelecionadas,
    parcelasProrrogacaoSelecionadas,
    parcelasRecuperacaoSelecionadas
  ])

  const itensSelecionados = useMemo(() => {
    if (parcelasCursoSelecionadas) {
      return [...itensCursoSelecionados]
    }

    if (parcelasRecuperacaoSelecionadas) {
      return [...itensRecuperacaoSelecionados]
    }

    if (parcelasTCCSelecionadas) {
      return [...itensTCCSelecionados]
    }

    if (parcelasProrrogacaoSelecionadas) {
      return [...itensProrrogacaoSelecionados]
    }

    return []
  }, [
    parcelasCursoSelecionadas,
    parcelasTCCSelecionadas,
    parcelasProrrogacaoSelecionadas,
    parcelasRecuperacaoSelecionadas,
    itensCursoSelecionados,
    itensTCCSelecionados,
    itensProrrogacaoSelecionados,
    itensRecuperacaoSelecionados
  ])

  const mensagemSemResultados = useMemo(() => {
    return cenarioFinanceiro?.detalhesTransferencia
      ? `Matrícula e parcelas transferidas para a turma ${cenarioFinanceiro?.detalhesTransferencia.nomeTurma}`
      : 'Nenhum resultado encontrado.'
  }, [cenarioFinanceiro])

  const validarBaixaManualParcelasCursoRecorrencia = (
    cobrancasSelecionadas: DetalheDaParcela[]
  ) => {
    if (
      cobrancasSelecionadas?.some(
        x => x.pagamentoRecorrente && !x.maximoTentativasRecorrencia
      )
    ) {
      toast(
        'Não é possível fazer baixa manual de parcelas com recorrencia em aberto',
        { type: 'error' }
      )
      return false
    }

    return true
  }
  const obterParcelasSelecionadasCenario = () => {
    let cobrancasSelecionadas: DetalheDaParcela[] = []

    if (parcelasCursoSelecionadas) {
      cobrancasSelecionadas = cenarioFinanceiro.detalheParcelas.filter(
        cobranca => itensCursoSelecionados.includes(cobranca.cobrancaId)
      )
    }

    if (parcelasRecuperacaoSelecionadas) {
      cobrancasSelecionadas = cenarioFinanceiro.detalhesParcelasRecuperacao.filter(
        cobranca => itensRecuperacaoSelecionados.includes(cobranca.cobrancaId)
      )
    }

    if (parcelasTCCSelecionadas) {
      cobrancasSelecionadas = cenarioFinanceiro.detalhesParcelasTCC.filter(
        cobranca => itensTCCSelecionados.includes(cobranca.cobrancaId)
      )
    }

    if (parcelasProrrogacaoSelecionadas) {
      cobrancasSelecionadas = cenarioFinanceiro.detalhesParcelasProrrogacao.filter(
        cobranca => itensProrrogacaoSelecionados.includes(cobranca.cobrancaId)
      )
    }
    return cobrancasSelecionadas
  }

  const obterCenarioFinanceiro = async (
    idDaMatricula: string
  ): Promise<void> => {
    try {
      definirPronto(false)
      limparErro()
      const resposta = await Api.ObterDadosFinanceirosDoCurso(idDaMatricula)
      definirCenarioFinanceiro(resposta)
    } catch (erro) {
      const mensagens = 'Ops! Houve algum problema no carregamento dos dados.'
      const acaoVoltar = () => history.push(RotasAluno.FinanceiroCursoOutro)
      definirErro({ mensagens, acaoVoltar })
    } finally {
      definirPronto(true)
    }
  }

  const gerarBoleto = async (cobrancaId: string) => {
    try {
      definirPronto(false)
      const resposta = await Api.ObterBoletoParaPagamentoDoCurso(
        matricula,
        cobrancaId
      )

      if (resposta) {
        window.open(resposta.linkBoleto)
      }
      definirPronto(true)
    } catch (erro) {
      definirPronto(true)
      toast('Houve um problema ao obter o boleto', {
        type: 'error'
      })
    }
  }

  const obterDiaDoVencimento = (): number => {
    const data = new Date().setDate(new Date().getDate() + 2)
    return new Date(data).getDate()
  }

  const cobrarTaxaDeMatriculaPorBoleto = async (): Promise<void> => {
    try {
      const cupomId = cenarioFinanceiro.detalhesMatricula.cupomId
      const diaDoVencimento = obterDiaDoVencimento()
      const pix =
        cenarioFinanceiro.detalhesMatricula?.formaPagamento === 'Boleto'
          ? 'pix-e-boleto'
          : 'pix'

      const resposta = await Api.CobrarTaxaDeMatriculaPorBoleto(
        matricula,
        cupomId,
        diaDoVencimento,
        pix
      )

      if (resposta) {
        window.open(resposta.linkBoleto)
      }
    } catch (erro) {
      toast(mensagemDeErroCobrarMatriculaPorBoleto, { type: 'error' })
    }
  }

  const cobrarTaxaReingressoPorBoleto = async (): Promise<void> => {
    try {
      const resposta = await Api.GerarBoletoMatriculaReingresso(matricula)

      if (resposta) {
        window.open(resposta.linkBoleto)
      }
    } catch (erro) {
      toast(mensagemDeErroCobrarReingressoPorBoleto, { type: 'error' })
    }
  }

  const cobrarTaxaTrocaDeCursoPorBoleto = async (): Promise<void> => {
    try {
      const diaDoVencimento = obterDiaDoVencimento()
      const resposta = await Api.GerarBoletoTaxaTrocaCurso({
        idMatricula: matricula,
        diaVencimento: diaDoVencimento.toString()
      })

      if (resposta) {
        window.open(resposta.linkBoleto)
      }
    } catch (erro) {
      toast(mensagemDeErroCobrarMatriculaPorBoleto, { type: 'error' })
    }
  }
  const pagamentoComCartaoDeCredito = async (dadosDoCartao: {
    [key: string]: string
  }) => {
    try {
      definirPronto(false)
      const pagamento = FormularioDePagamentoMapper(
        matricula,
        cobranca,
        dadosDoCartao
      )

      const {
        situacao,
        valorPago
      } = await Api.EfetuarPagamentoDoCursoComCartaoDeCredito(pagamento)

      const pagamentoAprovado =
        situacao === SituacaoDaCompraComCartaoDeCredito.Ativo && valorPago > 0

      pagamentoAprovado
        ? modalSucesso?.current?.abrir()
        : toast(mensagemDeErroDoCartaoDeCredito, { type: 'error' })

      definirPronto(true)
      obterCenarioFinanceiro(matricula)
    } catch (erro) {
      definirPronto(true)
      toast(mensagemDeErroDoCartaoDeCredito, { type: 'error' })
    }
  }

  const obterRecibo = async (cobrancaId: string) => {
    try {
      definirCarregando(true)
      const arquivo = await Api.ObterArquivoRecibo(matricula, cobrancaId)
      if (arquivo) {
        modalVisualizacaoRef?.current?.abrir(arquivo)
      }
    } catch (erro) {
      toast(mensagemDeErroRecibo, { type: 'error' })
      const acaoVoltar = () => history.push(RotasAluno.FinanceiroCursoOutro)
      definirErro({ mensagens: mensagemDeErroRecibo, acaoVoltar })
    } finally {
      definirCarregando(false)
    }
  }

  const obterReciboAnual = async (ano: number) => {
    try {
      definirCarregando(true)
      const arquivo = await Api.ObterArquivoReciboAnual(matricula, ano)
      if (arquivo) {
        modalVisualizacaoRef?.current?.abrir(arquivo)
      }
    } catch (erro) {
      toast(mensagemDeErroRecibo, { type: 'error' })
      const acaoVoltar = () => history.push(RotasAluno.FinanceiroCursoOutro)
      definirErro({ mensagens: mensagemDeErroRecibo, acaoVoltar })
    } finally {
      definirCarregando(false)
    }
  }

  const parcelaEstaEmAtraso = (parcela: DetalheDaParcela) =>
    parcela.informacoesParcelaAposVencimento.situacaoParcela ===
    SituacaoParcelaPosVencimento.ParcelaEmAtraso

  const parcelaAtiva = (parcela: DetalheDaParcela) =>
    parcela.situacaoParcela !== SituacaoParcela.Cancelado &&
    parcela.situacaoParcela !== SituacaoParcela.Renegociado

  const exibirInfoParcelaAtraso = (parcela: DetalheDaParcela) =>
    parcelaEstaEmAtraso(parcela) && parcelaAtiva(parcela)

  const obterDadosTabelaHistorico = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: cenarioFinanceiro?.detalheParcelas.filter(
        result =>
          result.situacaoParcela === 'Renegociado' ||
          result.situacaoParcela === 'Cancelado' ||
          result.situacaoParcela === 'Reembolso'
      )
    } as TabelaResposta
  }
  const obterDadosTabelaParcelasAtivas = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: cenarioFinanceiro?.detalheParcelas.filter(
        result =>
          result.situacaoParcela === 'Pago' ||
          result.situacaoParcela === 'AguardandoPagamentoAutomatico' ||
          result.situacaoParcela === 'Vencido' ||
          result.situacaoParcela === 'Negativado' ||
          result.situacaoParcela === 'AVencer'
      )
    } as TabelaResposta
  }

  const totalParcelas = cenarioFinanceiro?.detalheParcelas.filter(
    result =>
      result.situacaoParcela === 'Pago' ||
      result.situacaoParcela === 'AguardandoPagamentoAutomatico' ||
      result.situacaoParcela === 'Vencido' ||
      result.situacaoParcela === 'Negativado' ||
      result.situacaoParcela === 'AVencer'
  )

  const parcelasNaoPagas = cenarioFinanceiro?.detalheParcelas.filter(
    result =>
      result.situacaoParcela === 'AguardandoPagamentoAutomatico' ||
      result.situacaoParcela === 'Vencido' ||
      result.situacaoParcela === 'Negativado' ||
      result.situacaoParcela === 'AVencer'
  )
  const parcelasNegativadas = cenarioFinanceiro?.detalheParcelas.filter(
    result =>
      result.situacaoParcela === 'Negativado' ||
      result.situacaoParcela === 'Vencido'
  )

  const valorCalculoJuros = cenarioFinanceiro?.detalheParcelas
    .filter(cobranca => parcelaEstaEmAtraso(cobranca))
    .map(
      cobranca =>
        (cobranca.informacoesParcelaAposVencimento?.valorJuros ?? 0) +
        (cobranca.informacoesParcelaAposVencimento?.valorMulta ?? 0)
    )
  const valoresSomadoComjuros = valorCalculoJuros?.length
    ? valorCalculoJuros?.reduce((a, b) => a + b)
    : null

  const valorComDesconto =
    cenarioFinanceiro?.detalhesCurso.valorTotalCurso -
    cenarioFinanceiro?.detalhesCurso.valorCupomDesconto

  const parcelaPaga = cenarioFinanceiro?.detalheParcelas
    .filter(result => result.situacaoParcela === 'Pago')
    .map(x => x.valor)

  const valorSomado =
    parcelaPaga && parcelaPaga?.length > 0
      ? parcelaPaga?.reduce((a, b) => a + b)
      : null

  const saldoDevedorSemJuros = cenarioFinanceiro?.detalhesCurso.saldoDevedor

  const SaldoDevedorSemJurosMenor =
    saldoDevedorSemJuros > 0 ? saldoDevedorSemJuros : 0

  const saldoDevedorComJuros = saldoDevedorSemJuros + valoresSomadoComjuros

  const saldoDevedorComJurosMenorQue =
    saldoDevedorSemJuros > 0 ? saldoDevedorComJuros : 0

  const ultimaNegocicao = cenarioFinanceiro?.detalheParcelas
    .filter(result => result.situacaoParcela === 'Renegociado')
    .map(x => x.dataAlteracao)

  const ultimoNegocicao =
    ultimaNegocicao && ultimaNegocicao?.length > 0 ? ultimaNegocicao : '-'

  const pegarUltimoArray =
    ultimoNegocicao.length > 0 && ultimoNegocicao && ultimoNegocicao?.length - 1

  const conversaoDataUltimaaNegociacao = formatadores.dataLocaleString(
    ultimoNegocicao[pegarUltimoArray]?.toString()
  )

  const tratamentoConversao =
    conversaoDataUltimaaNegociacao === 'Invalid Date'
      ? '-'
      : conversaoDataUltimaaNegociacao
  const obterDadosTCC = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: cenarioFinanceiro?.detalhesParcelasTCC
    } as TabelaResposta
  }

  const obterDadosRecuperacao = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: cenarioFinanceiro?.detalhesParcelasRecuperacao
    } as TabelaResposta
  }

  const obterDadosProrrogacao = async (): Promise<TabelaResposta | null> => {
    return {
      Dados: cenarioFinanceiro?.detalhesParcelasProrrogacao
    } as TabelaResposta
  }
  const atualizarPlanoDePagamento = async ({
    planoDePagamento,
    diaDePagamento
  }: ModalAtualizarPlanoDePagamentoDados): Promise<void> => {
    try {
      const resposta = await Api.AlterarPlanoDePagamento(
        matricula,
        planoDePagamento,
        +diaDePagamento
      )

      if (resposta) {
        await obterCenarioFinanceiro(matricula)
      } else {
        throw new Error(erroAoAtualizarPlanoDePagamento)
      }
    } catch (erro) {
      toast(erroAoAtualizarPlanoDePagamento, { type: 'error' })
    }
  }

  const atualizarDataCobranca = async ({
    dataCobranca
  }: ModalAtualizarDataCobrancaDados): Promise<void> => {
    try {
      const resposta = await Api.AlterarDataCobranca(
        matricula,
        dataCobranca,
        itensSelecionados
      )

      if (resposta) {
        await obterCenarioFinanceiro(matricula)
        toast('Parcelas alteradas com sucesso.', { type: 'success' })
      } else {
        throw new Error(erroAoAlterarParcelas)
      }
    } catch (erro) {
      toast(erroAoAlterarParcelas, { type: 'error' })
    }
  }

  const baixaManualTaxaReingresso = async (
    dados: BaixaManualTaxaReingresso
  ): Promise<void> => {
    try {
      const resposta = await Api.BaixaTaxaReingresso(
        dados,
        cenarioFinanceiro.detalhesReingresso.cobrancaId
      )

      if (!resposta) {
        throw new Error(
          'Não foi possível realizar a baixa da taxa do reingresso'
        )
      }

      await obterCenarioFinanceiro(matricula)
      toast('Baixa da taxa do reingresso realizada com sucesso.', {
        type: 'success'
      })
    } catch (erro) {
      const mensagem =
        getApiError(erro) ||
        'Não foi possível realizar a baixa da taxa do reingresso.'

      toast(mensagem, { type: 'error' })
    }
  }
  const baixaManualTaxaMatricula = async (
    dados: BaixaManualTaxaMatricula
  ): Promise<void> => {
    try {
      const resposta = await Api.BaixaManualMatricula(
        dados,
        cenarioFinanceiro.detalhesMatricula.cobrancaId
      )

      if (!resposta) {
        throw new Error(
          'Não foi possível realizar a baixa da taxa da matrícula'
        )
      }

      await obterCenarioFinanceiro(matricula)
      toast('Baixa da taxa da matrícula realizada com sucesso.', {
        type: 'success'
      })
    } catch (erro) {
      const mensagem =
        getApiError(erro) ||
        'Não foi possível realizar a baixa da taxa da matrícula.'

      toast(mensagem, { type: 'error' })
    }
  }

  const baixaManualTaxaTrocaCurso = async (
    dados: BaixaManualTaxaTrocaCurso
  ): Promise<void> => {
    try {
      const resposta = await Api.BaixaManualTrocaCurso(
        dados,
        cenarioFinanceiro.detalhesCobrancaTransferencia.cobrancaId
      )

      if (!resposta) {
        throw new Error(
          'Não foi possível realizar a baixa da taxa da matrícula'
        )
      }

      await obterCenarioFinanceiro(matricula)
      toast('Baixa da taxa da matrícula realizada com sucesso.', {
        type: 'success'
      })
    } catch (erro) {
      const mensagem =
        getApiError(erro) ||
        'Não foi possível realizar a baixa da taxa da matrícula.'

      toast(mensagem, { type: 'error' })
    }
  }

  const baixaManualCobranca = async (
    dados: BaixaManualCobranca
  ): Promise<void> => {
    try {
      const resposta = await Api.BaixaManualCobranca(dados, itensSelecionados)

      if (!resposta) {
        throw new Error('Não foi possível realizar a baixa da(s) cobrança(s).')
      }

      await obterCenarioFinanceiro(matricula)
      toast('Baixa realizada com sucesso.', { type: 'success' })
    } catch (erro) {
      const mensagem =
        getApiError(erro) ||
        'Não foi possível realizar a baixa da(s) cobrança(s).'

      toast(mensagem, { type: 'error' })
    }
  }

  const baixarComprovanteCobranca = async (linha: TabelaDados) => {
    if (linha.pagamentos && linha.pagamentos.length) {
      linha.pagamentos
        .filter(pa => pa.pagamentoManual)
        .forEach(pagamento => {
          Api.BaixarComprovanteCobranca(
            linha.cobrancaId,
            pagamento.formaPagamento
          )
        })
    }
  }

  const exibirBaixarComprovanteCobranca = (linha: TabelaDados) => {
    if (!linha.pagamentos) return false

    return linha.pagamentos.some(pagamento => pagamento.pagamentoManual)
  }

  const exibirBotaoPagamento = (situacaoParcela: any) => {
    const situacoes = [
      SituacaoParcela.Cancelado,
      SituacaoParcela.Renegociado,
      SituacaoParcela.Pago,
      SituacaoParcela.AguardandoPagamentoAutomatico
    ]

    if (situacoes.some(x => x === SituacaoParcela[situacaoParcela])) {
      return false
    }

    return true
  }

  const exibirBotaoPagamentoTCC = (situacaoParcela: any) => {
    return exibirBotaoPagamento(situacaoParcela)
  }

  const exibirBotaoPagamentoProrrogacao = (situacaoParcela: any) => {
    return exibirBotaoPagamento(situacaoParcela)
  }

  const exibirBotaoPagamentoCurso = (
    situacaoParcela: any,
    maximoTentativasRecorrencia: any
  ) => {
    if (ehRecorrente && !maximoTentativasRecorrencia) return false

    return exibirBotaoPagamento(situacaoParcela)
  }

  const enviarRenegociacao = async (dados: any): Promise<void> => {
    try {
      const resposta = await Api.Renegociacao(
        matricula,
        dados,
        itensSelecionados
      )

      if (!resposta) {
        throw new Error('Não foi possível realizar a renegociação.')
      }

      await obterCenarioFinanceiro(matricula)
      toast('Renegociação realizada com sucesso.', { type: 'success' })
    } catch (erro) {
      const mensagem =
        getApiError(erro) ||
        'Não foi possível realizar a renegociação das cobranças.'

      toast(mensagem, { type: 'error' })
    }
  }

  const abrirModalRenegociacao = async () => {
    const cobrancasSelecionadas = obterParcelasSelecionadasCenario()

    const cobrancasComJuros = cobrancasSelecionadas
      .filter(cobranca => parcelaEstaEmAtraso(cobranca))
      .map(
        cobranca =>
          (cobranca.informacoesParcelaAposVencimento?.valorJuros ?? 0) +
          (cobranca.informacoesParcelaAposVencimento?.valorMulta ?? 0)
      )

    const valorMultaJuros =
      Array.isArray(cobrancasComJuros) && cobrancasComJuros.length
        ? cobrancasComJuros.reduce((a, b) => a + b)
        : 0

    const valorTotal = cobrancasSelecionadas
      .map(cobranca => cobranca.valor)
      .reduce((a, b) => a + b)

    const quantidade = itensSelecionados.length
    const valorTotalComMultaJuros = valorTotal + valorMultaJuros
    const valorParcela = valorTotalComMultaJuros / quantidade

    modalRenegociacaoRef.current?.abrir(
      quantidade,
      valorParcela,
      valorMultaJuros,
      valorTotalComMultaJuros
    )
  }

  const abrirModalBaixaManual = async () => {
    const cobrancasSelecionadas = obterParcelasSelecionadasCenario()

    if (parcelasCursoSelecionadas) {
      if (!validarBaixaManualParcelasCursoRecorrencia(cobrancasSelecionadas)) {
        return
      }
    }

    const valorTotal = cobrancasSelecionadas
      .map(cobranca => cobranca.valor)
      .reduce((a, b) => a + b)

    modalBaixaManualCobrancaRef.current?.abrir(valorTotal)
  }

  const abrirModalBaixaManualTaxaMatricula = async () => {
    const valorTotal = cenarioFinanceiro.detalhesMatricula.saldoDevedor
    modalBaixaManualTaxaMatriculaRef.current?.abrir(valorTotal, matricula)
  }

  const abrirModalBaixaManualTaxaReingresso = async () => {
    const valorTotal = cenarioFinanceiro.detalhesReingresso.saldoDevedor
    modalBaixaManualTaxaReingressoRef.current?.abrir(valorTotal, matricula)
  }

  const abrirModalBaixaManualTaxaTrocaCurso = async () => {
    const valorTotal =
      cenarioFinanceiro.detalhesCobrancaTransferencia.saldoDevedor

    modalBaixaManualTaxaTrocaCursoRef.current?.abrir(valorTotal, matricula)
  }

  const realizarBaixasManual = async () => {
    const resposta = Api.RealizarBaixasManual()
    return resposta
  }

  const realizarVinculoPagamentos = async (matriculaId: string) => {
    try {
      const resposta = await Api.RealizarVinculoPagamentos(matriculaId)
      if (resposta && resposta.status === 204) {
        window.location.reload()
      }

      return resposta
    } catch (error) {
      console.warn('Erro ao realizar o vínculo:', error)
    }
  }

  const abrirModalAlteracaoObservacao = async () => {
    const cobrancasSelecionadas = obterParcelasSelecionadasCenario()
    const reduceObservacoes = cobrancasSelecionadas.reduce(
      (acumulador, atual, index) => {
        acumulador[index] = atual.observacao
        return acumulador
      },
      {}
    )

    const listaObservacoes = Object.values(reduceObservacoes)
    const observacoesIguais = listaObservacoes.every(
      item => item === listaObservacoes[0]
    )

    const reduceObservacoesInternas = cobrancasSelecionadas.reduce(
      (acumulador, atual, index) => {
        acumulador[index] = atual.observacaoInterna
        return acumulador
      },
      {}
    )

    const listaObservacoesInternas = Object.values(reduceObservacoesInternas)
    const observacoesInternasIguais = listaObservacoesInternas.every(
      item => item === listaObservacoesInternas[0]
    )

    let observacao
    let observacaoInterna

    if (observacoesIguais) {
      observacao = cobrancasSelecionadas
        ?.map(cobranca => cobranca.observacao)
        ?.shift()
        ?.toString()
    } else {
      observacao = '-'
    }

    if (observacoesInternasIguais) {
      observacaoInterna = cobrancasSelecionadas
        ?.map(cobranca => cobranca.observacaoInterna)
        ?.shift()
        ?.toString()
    } else {
      observacaoInterna = '-'
    }

    modalAlterarObservacaoRef.current?.abrir(observacao, observacaoInterna)
  }

  const cancelarParcela = async (dados: AlterarCobranca) => {
    try {
      const resposta = await Api.CancelarParcelas(
        itensSelecionados,
        dados.observacao
      )

      if (!resposta) {
        throw new Error(erroAoAlterarParcelas)
      }

      await obterCenarioFinanceiro(matricula)
      toast('Parcelas alteradas com sucesso.', { type: 'success' })
    } catch (erro) {
      toast(erroAoAlterarParcelas, { type: 'error' })
    }
  }
  const reembolsoParcela = async (dados: AlterarCobranca) => {
    try {
      const resposta = await Api.ReembolsoParcela(
        itensSelecionados,
        dados.observacao
      )

      if (!resposta) {
        throw new Error(erroAoAlterarParcelas)
      }

      await obterCenarioFinanceiro(matricula)
      toast('Parcelas alteradas com sucesso.', { type: 'success' })
    } catch (erro) {
      toast(erroAoAlterarParcelas, { type: 'error' })
    }
  }

  const deParaTipoCobranca = (
    tipoCobrancaAcao: TipoCobrancaAcao
  ): TipoCobranca => {
    if (tipoCobrancaAcao === TipoCobrancaAcao.Curso) {
      return TipoCobranca.Curso
    }
    if (tipoCobrancaAcao === TipoCobrancaAcao.TCC) {
      return TipoCobranca.TCC
    }
    if (tipoCobrancaAcao === TipoCobrancaAcao.Prorrogacao) {
      return TipoCobranca.ProrrogacaoMatricula
    }
    if (tipoCobrancaAcao === TipoCobrancaAcao.Recuperacao) {
      return TipoCobranca.Recuperacao
    }

    return null
  }

  const novaCompra = async (dados: DadosNovaCompra) => {
    try {
      const resposta = await Api.NovaCompra(
        matricula,
        dados.quantidade,
        dados.valorParcela,
        dados.primeiroVencimento,
        dados.observacao,
        dados.excluirCobrancas,
        itensSelecionados,
        dados.novaRecorrencia,
        dados.permitirParcelamento,
        deParaTipoCobranca(dados.tipoCobranca),
        dados.tipoRecorrencia
      )

      if (!resposta) {
        throw new Error(erroNovaCompra)
      }

      await obterCenarioFinanceiro(matricula)
      toast('Compra criada com sucesso.', { type: 'success' })
    } catch (error) {
      toast(erroNovaCompra, { type: 'error' })
    }
  }

  const enviarCartaoRecorrencia = async (dados: DadosCartao) => {
    try {
      if (
        SituacaoFinanceira[cenarioFinanceiro.situacaoFinanceira] ===
        SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente
      ) {
        await Api.EnviarPagamentoRecorrente(matricula, dados)
      } else {
        await Api.AtualizarCartaoPagamentoRecorrente(matricula, dados)
      }
      await obterCenarioFinanceiro(matricula)
      toast('Cartão atualizado com sucesso.', { type: 'success' })
    } catch (erro) {
      toast(mensagemErroCartaoCredito, { type: 'error' })
    } finally {
      modalCartaoRecorrenciaRef?.current?.fechar()
    }
  }

  const cancelarRecorrencia = async () => {
    try {
      await Api.CancelarRecorrencia(matricula)
      toast('Recorrência cancelada com sucesso!.', { type: 'success' })
      await obterCenarioFinanceiro(matricula)
    } catch (error) {
      toast(mensagemErroCartaoCredito, { type: 'error' })
    } finally {
      modalCancelarRecorrenciaRef?.current?.fechar()
    }
  }

  const acaoClickNovaCompra = async () => {
    modalNovaCompraRef.current?.abrir(
      parcelasSelecionadas,
      ehRecorrente,
      tipoCobrancaAcao
    )
  }

  const acaoClickReciboAnual = async () => {
    modalReciboAnualRef?.current?.abrir(cenarioFinanceiro.anosReciboPagamento)
  }

  const alterarObservacao = async (dados: AlterarCobranca) => {
    try {
      const resposta = await Api.AlterarObservacao(
        itensSelecionados,
        dados.observacao,
        dados.observacaoInterna
      )

      if (!resposta) {
        throw new Error(erroAoAlterarParcelas)
      }

      await obterCenarioFinanceiro(matricula)
      toast('Parcelas alteradas com sucesso.', { type: 'success' })
    } catch (erro) {
      toast(erroAoAlterarParcelas, { type: 'error' })
    }
  }

  const recusaResponsavelFinanceiro = async (motivoSituacao?: string) => {
    try {
      const payload = {
        matriculaId: matricula,
        situacaoResponsavel: SituacaoResponsavelFinanceiro.Recusado,
        motivoSituacao: motivoSituacao
      }

      await Api.AceiteResponsavel(matricula, payload)
      history.push(RotasResponsavelFinanceiro.Dashboard)

      toast('Recusado com sucesso.', { type: 'success' })
    } catch (erro) {
      toast('Erro ao cancelar convite.', { type: 'error' })
    }
  }

  const aceiteResponsavelFinanceiro = async () => {
    try {
      const payload = {
        matriculaId: matricula,
        situacaoResponsavel: SituacaoResponsavelFinanceiro.Aceito
      }

      await Api.AceiteResponsavel(matricula, payload)

      if (
        cenarioFinanceiro?.detalhesResponsavelFinanceiro?.documentoResponsavel
      ) {
        await obterCenarioFinanceiro(matricula)
      } else {
        history.push(RotasResponsavelFinanceiro.Documentos)
      }
      toast('Aceito com sucesso.', { type: 'success' })
    } catch (erro) {
      toast('Erro ao aceitar convite.', { type: 'error' })
    }
  }

  const atalhos = [
    {
      texto: 'Financeiro',
      acao: () => history.goBack()
    },
    {
      texto: 'Painel Financeiro'
    }
  ]

  const saldoDevedorTransferencia = useMemo(() => {
    return cenarioFinanceiro?.detalhesCobrancaTransferencia?.saldoDevedor === 0
  }, [cenarioFinanceiro])

  const accordionParcelasCursoBloqueado = useMemo(() => {
    return (
      parcelasTCCSelecionadas ||
      parcelasProrrogacaoSelecionadas ||
      parcelasRecuperacaoSelecionadas
    )
  }, [
    parcelasTCCSelecionadas,
    parcelasProrrogacaoSelecionadas,
    parcelasRecuperacaoSelecionadas
  ])

  const accordionParcelasTCCBloqueado = useMemo(() => {
    return (
      parcelasCursoSelecionadas ||
      parcelasProrrogacaoSelecionadas ||
      parcelasRecuperacaoSelecionadas
    )
  }, [
    parcelasCursoSelecionadas,
    parcelasProrrogacaoSelecionadas,
    parcelasRecuperacaoSelecionadas
  ])

  const accordionParcelasProrrogacaoBloqueado = useMemo(() => {
    return (
      parcelasCursoSelecionadas ||
      parcelasTCCSelecionadas ||
      parcelasRecuperacaoSelecionadas
    )
  }, [
    parcelasCursoSelecionadas,
    parcelasTCCSelecionadas,
    parcelasRecuperacaoSelecionadas
  ])

  const accordionParcelasRecuperacaoBloqueado = useMemo(() => {
    return (
      parcelasCursoSelecionadas ||
      parcelasTCCSelecionadas ||
      parcelasProrrogacaoSelecionadas
    )
  }, [
    parcelasCursoSelecionadas,
    parcelasTCCSelecionadas,
    parcelasProrrogacaoSelecionadas
  ])

  const renegociacaoEAlteracaoDataVisiveis = useMemo(() => {
    if (parcelasTCCSelecionadas || parcelasProrrogacaoSelecionadas) {
      return true
    }

    if (parcelasCursoSelecionadas && !ehRecorrente) {
      return true
    }

    return false
  }, [
    ehRecorrente,
    parcelasCursoSelecionadas,
    parcelasTCCSelecionadas,
    parcelasProrrogacaoSelecionadas
  ])

  const verificarSituacaoParcelaParaExibirBotaoRenegociacao = (
    situacoesParcela: string[]
  ) => {
    if (
      situacoesParcela?.length <= 0 ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Cancelado) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Renegociado) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Pago) ||
      situacoesParcela.includes(
        SituacaoParcelaFiltro.AguardandoPagamentoAutomatico
      )
    ) {
      return false
    }

    return true
  }

  const verificarSeMarcouParcelaPaga = (situacoesParcela: string[]) => {
    if (situacoesParcela.includes(SituacaoParcelaFiltro.Pago)) {
      return true
    }

    return false
  }
  const liberarParcelaReembolso = (situacoesParcela: string[]) => {
    if (
      situacoesParcela.includes(SituacaoParcelaFiltro.AVencer) ||
      situacoesParcela.includes(
        SituacaoParcelaFiltro.AguardandoPagamentoAutomatico
      ) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Cancelado) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Negativado) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Vencido) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Reembolso) ||
      situacoesParcela.includes(SituacaoParcelaFiltro.Renegociado)
    ) {
      return true
    }

    return false
  }

  const bloqueioDeBotoesPorSelecionarParcelaPaga = useMemo(() => {
    if (
      (verificarSeMarcouParcelaPaga(situacaoItensCursoSelecionados) &&
        itensCursoSelecionados) ||
      (verificarSeMarcouParcelaPaga(situacaoItensRecuperacaoSelecionados) &&
        itensRecuperacaoSelecionados) ||
      (verificarSeMarcouParcelaPaga(situacaoItensTCCSelecionados) &&
        itensTCCSelecionados) ||
      (verificarSeMarcouParcelaPaga(situacaoItensProrrogacaoSelecionados) &&
        itensProrrogacaoSelecionados)
    ) {
      return true
    }
  }, [
    situacaoItensCursoSelecionados,
    situacaoItensTCCSelecionados,
    situacaoItensProrrogacaoSelecionados,
    situacaoItensRecuperacaoSelecionados
  ])
  const bloqueioDeBotoesPorSelecionarRenegociacao = useMemo(() => {
    if (
      (liberarParcelaReembolso(situacaoItensCursoSelecionados) &&
        itensCursoSelecionados) ||
      (liberarParcelaReembolso(situacaoItensRecuperacaoSelecionados) &&
        itensRecuperacaoSelecionados) ||
      (liberarParcelaReembolso(situacaoItensTCCSelecionados) &&
        itensTCCSelecionados) ||
      (liberarParcelaReembolso(situacaoItensProrrogacaoSelecionados) &&
        itensProrrogacaoSelecionados)
    ) {
      return true
    }
  }, [
    situacaoItensCursoSelecionados,
    situacaoItensTCCSelecionados,
    situacaoItensProrrogacaoSelecionados,
    situacaoItensRecuperacaoSelecionados
  ])

  const exibirBotaoRenegociacao = useMemo(() => {
    if (
      (verificarSituacaoParcelaParaExibirBotaoRenegociacao(
        situacaoItensCursoSelecionados
      ) &&
        itensCursoSelecionados) ||
      (verificarSituacaoParcelaParaExibirBotaoRenegociacao(
        situacaoItensRecuperacaoSelecionados
      ) &&
        itensRecuperacaoSelecionados) ||
      (verificarSituacaoParcelaParaExibirBotaoRenegociacao(
        situacaoItensTCCSelecionados
      ) &&
        itensTCCSelecionados) ||
      (verificarSituacaoParcelaParaExibirBotaoRenegociacao(
        situacaoItensProrrogacaoSelecionados
      ) &&
        itensProrrogacaoSelecionados)
    ) {
      return true
    }
  }, [
    situacaoItensCursoSelecionados,
    situacaoItensTCCSelecionados,
    situacaoItensProrrogacaoSelecionados,
    situacaoItensRecuperacaoSelecionados
  ])

  const semSaldoDevedorMatricula = useMemo(() => {
    return cenarioFinanceiro?.detalhesMatricula?.saldoDevedor === 0
  }, [cenarioFinanceiro])

  const opcoesReciboAnual = useMemo(
    () => cenarioFinanceiro?.anosReciboPagamento?.length > 0,
    [cenarioFinanceiro?.anosReciboPagamento]
  )

  const recorrenciaFinalizada = useMemo(() => {
    if (!cenarioFinanceiro || !cenarioFinanceiro.detalhesCurso) return false
    return (
      StatusRecorrencia[cenarioFinanceiro.detalhesCurso.statusRecorrencia] ===
      StatusRecorrencia[StatusRecorrencia.Finalizado]
    )
  }, [cenarioFinanceiro])

  useEffect(() => {
    let tipo: TipoCobrancaAcao = null

    if (parcelasCursoSelecionadas) {
      tipo = TipoCobrancaAcao.Curso
    }

    if (parcelasRecuperacaoSelecionadas) {
      tipo = TipoCobrancaAcao.Recuperacao
    }

    if (parcelasTCCSelecionadas) {
      tipo = TipoCobrancaAcao.TCC
    }

    if (parcelasProrrogacaoSelecionadas) {
      tipo = TipoCobrancaAcao.Prorrogacao
    }

    definirTipoCobrancaAcao(tipo)
  }, [
    itensCursoSelecionados,
    itensTCCSelecionados,
    itensProrrogacaoSelecionados,
    parcelasRecuperacaoSelecionadas
  ])

  useEffect(() => {
    if (accordionParcelasCursoBloqueado) {
      definirItensCursosSelecionados([])
      definirSituacaoItensCursoSelecionados([])
    }
  }, [accordionParcelasCursoBloqueado])

  useEffect(() => {
    if (accordionParcelasRecuperacaoBloqueado) {
      definirItensRecuperacaoSelecionados([])
      definirSituacaoItensRecuperacaoSelecionados([])
    }
  }, [accordionParcelasRecuperacaoBloqueado])

  useEffect(() => {
    if (accordionParcelasTCCBloqueado) {
      definirItensTCCSelecionados([])
      definirSituacaoItensTCCSelecionados([])
    }
  }, [accordionParcelasTCCBloqueado])

  useEffect(() => {
    if (accordionParcelasProrrogacaoBloqueado) {
      definirItensProrrogacaoSelecionados([])
      definirSituacaoItensProrrogacaoSelecionados([])
    }
  }, [accordionParcelasProrrogacaoBloqueado])

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR, 6)
    }
    obterCenarioFinanceiro(matricula)
  }, [match])

  if (erro) {
    return <ErroContainerLayout />
  }

  if (!pronto) {
    return <Carregando texto="Carregando dados financeiros do curso..." />
  }
  return (
    <>
      {!carregando ? (
        <>
          <ContainerTitulo>
            <Breadcrumb
              titulo={`${formatadores.curso(modalidade)} - ${nomeDoCurso}`}
              atalhos={atalhos}
            />
            <Titulo>{cenarioFinanceiro.detalhesAluno.nome}</Titulo>
            <Titulo>
              CPF {formatarCpf(cenarioFinanceiro.detalhesAluno.cpf)}
            </Titulo>
          </ContainerTitulo>
          {visaoResponsavel &&
            (!aceiteResponsavel || documentoResponsavelRecusado) && (
              <ContainerResponsavel>
                <h5>
                  Você aceita ser o Responsável Financeiro desta matrícula?
                </h5>
                <p>
                  A confirmação poderá ser feita até o dia {dataLimiteEnvio}.
                  Após essa data será automaticamente recusado.
                </p>
                {aceiteResponsavel && documentoResponsavelRecusado ? (
                  <>
                    <AvisoResponsavel>
                      Seu documento de identidade foi recusado por:{' '}
                      {
                        cenarioFinanceiro?.detalhesResponsavelFinanceiro
                          ?.documentoResponsavel?.motivoRejeicao
                      }
                      , envie seu documento novamente.
                    </AvisoResponsavel>
                    <ContainerBotoesResponsavel>
                      <Botao
                        type="button"
                        id="btn-reenviar-documento"
                        texto="Reenviar documento"
                        onClick={() => {
                          history.push(RotasResponsavelFinanceiro.Documentos)
                        }}
                      />
                    </ContainerBotoesResponsavel>
                  </>
                ) : (
                  <ContainerBotoesResponsavel>
                    <Botao
                      type="button"
                      id="btn-recusar"
                      texto="Recusar"
                      tema="Secundario"
                      onClick={() => {
                        modalRecusaResponsavelRef?.current?.abrir()
                      }}
                    />
                    <Botao
                      type="button"
                      id="btn-aceitar"
                      texto="Aceitar"
                      onClick={() => {
                        modalAceiteResponsavelRef?.current?.abrir()
                      }}
                    />
                  </ContainerBotoesResponsavel>
                )}
              </ContainerResponsavel>
              // eslint-disable-next-line
            )}
          {documentoResponsavelAnalise && !visaoIPGS && (
            <ContainerResponsavel>
              <h5>Documento em análise</h5>
              <p>Aguarde o iPGS conferir o documento.</p>
            </ContainerResponsavel>
          )}
          {documentoResponsavelAnalise && visaoIPGS && (
            <ContainerResponsavel>
              <h5>Conferir Documento de Identidade - Responsável Financeiro</h5>
              <Botao
                type="button"
                id="btn-conferir"
                texto="Conferir documento"
                tema="Link"
                onClick={() =>
                  history.push(
                    `/academico${RotasResponsavelFinanceiro.Documentos}/${cenarioFinanceiro?.detalhesResponsavelFinanceiro?.email}`
                  )
                }
              />
            </ContainerResponsavel>
          )}
          {cenarioFinanceiro?.detalhesResponsavelFinanceiro &&
            !visaoResponsavel &&
            !documentoResponsavelRecusado && (
              <CursoContainer>
                <div>
                  <h4>Responsável Financeiro</h4>
                  <div>
                    <span>
                      <p>
                        <b>Nome: </b>
                        {cenarioFinanceiro?.detalhesResponsavelFinanceiro?.nome}
                      </p>
                      {cenarioFinanceiro?.detalhesResponsavelFinanceiro?.cpf ? (
                        <p>
                          <b>CPF: </b>
                          {formatarCpf(
                            cenarioFinanceiro?.detalhesResponsavelFinanceiro
                              ?.cpf
                          )}
                        </p>
                      ) : (
                        <p>
                          <b>CNPJ: </b>
                          {formatarCNPJ(
                            cenarioFinanceiro?.detalhesResponsavelFinanceiro
                              ?.cnpj
                          )}
                        </p>
                      )}
                      <p>
                        <b>CEP: </b>
                        {formatarCEP(
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.endereco?.cep
                        )}
                      </p>
                      <p>
                        <b>Logradouro: </b>
                        {
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.endereco?.logradouro
                        }
                      </p>
                    </span>
                    <span>
                      <p>
                        <b>E-mail: </b>
                        {
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.email
                        }
                      </p>
                      {cenarioFinanceiro?.detalhesResponsavelFinanceiro?.cpf ? (
                        <p>
                          <b>Nacionalidade: </b>
                          {
                            NacionalidadeFormatada[
                              cenarioFinanceiro?.detalhesResponsavelFinanceiro
                                ?.nacionalidade
                            ]
                          }
                        </p>
                      ) : (
                        <p>
                          <b>Inscrição Estadual: </b>
                          {
                            cenarioFinanceiro?.detalhesResponsavelFinanceiro
                              ?.inscricaoEstadual
                          }
                        </p>
                      )}
                      <p>
                        <b>UF: </b>
                        {
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.endereco?.uf
                        }
                      </p>
                      <p>
                        <b>Cidade: </b>
                        {
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.endereco?.cidade
                        }
                      </p>
                    </span>
                    <span>
                      <p>
                        <b>Celular: </b>
                        {formatarTelefone(
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.celular
                        )}
                      </p>
                      {cenarioFinanceiro?.detalhesResponsavelFinanceiro?.cpf ? (
                        <p>
                          <b>País de Residência: </b>
                          {
                            PaisResidenciaFormatado[
                              cenarioFinanceiro?.detalhesResponsavelFinanceiro
                                ?.endereco?.pais
                            ]
                          }
                        </p>
                      ) : (
                        <p>
                          <b>Inscrição Municipal: </b>
                          {
                            cenarioFinanceiro?.detalhesResponsavelFinanceiro
                              ?.inscricaoMunicipal
                          }
                        </p>
                      )}
                      <p>
                        <b>Número: </b>
                        {
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.endereco?.numero
                        }
                      </p>
                      <p>
                        <b>Bairro: </b>
                        {
                          cenarioFinanceiro?.detalhesResponsavelFinanceiro
                            ?.endereco?.bairro
                        }
                      </p>
                    </span>
                  </div>
                </div>
              </CursoContainer>
              // eslint-disable-next-line
            )}
          {cenarioFinanceiro?.detalhesMatricula && (
            <CursoContainer>
              <div>
                <h4>Taxa de matrícula</h4>
                <div>
                  <span>
                    <p>
                      <b>Valor: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro.detalhesMatricula?.valor
                      )}
                    </p>
                    <p>
                      <b>Cupom de desconto: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro?.detalhesMatricula?.valorCupomDesconto
                      )}
                    </p>
                    <p>
                      <b>Saldo devedor: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro?.detalhesMatricula?.saldoDevedor
                      )}
                    </p>
                  </span>
                  <span>
                    {semSaldoDevedorMatricula ? (
                      <p>
                        <b>Data de pagamento: </b>
                        {formatadores.data(
                          cenarioFinanceiro?.detalhesMatricula?.dataPagamento
                        )}
                      </p>
                    ) : (
                      <p>
                        <b>Data de vencimento: </b>
                        {formatadores.data(
                          cenarioFinanceiro.detalhesMatricula?.dataVencimento
                        )}
                      </p>
                    )}
                    <p>
                      <b>Forma de pagamento: </b>{' '}
                      {formatadores.pagamento(
                        cenarioFinanceiro.detalhesMatricula?.formaPagamento
                      )}
                    </p>
                    <p>
                      <b>Cupom Utilizado</b>
                      {cenarioFinanceiro?.detalhesMatricula?.cupomId}
                    </p>
                  </span>
                  {cenarioFinanceiro.detalhesMatricula?.saldoDevedor === 0 ? (
                    <ImprimirReciboContainer>
                      {cenarioFinanceiro.detalhesMatricula.formaPagamento !==
                        MetodosPagamento.Isencao && (
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconeRecibo}
                              <ConteudoBotao>Imprimir Recibo</ConteudoBotao>
                            </>
                          }
                          onClick={() => {
                            obterRecibo(
                              cenarioFinanceiro.detalhesMatricula.cobrancaId
                            )
                          }}
                        />
                      )}
                    </ImprimirReciboContainer>
                  ) : (
                    <span>
                      {ehPerfilFinanceiro && (
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconeGerarBoleto}
                              <ConteudoBotao>
                                Baixa manual da cobrança
                              </ConteudoBotao>
                            </>
                          }
                          onClick={abrirModalBaixaManualTaxaMatricula}
                        />
                      )}
                      <Botao
                        type="button"
                        tema="Link"
                        elemento={
                          <>
                            {IconeGerarBoleto}
                            <ConteudoBotao>
                              {cenarioFinanceiro.detalhesMatricula
                                ?.formaPagamento === 'Boleto'
                                ? 'Gerar o Boleto'
                                : 'Gerar o Pix'}
                            </ConteudoBotao>
                          </>
                        }
                        onClick={cobrarTaxaDeMatriculaPorBoleto}
                      />
                      <p>
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconePagamentoMatricula}
                              <ConteudoBotao>
                                Pagar com cartão de crédito
                              </ConteudoBotao>
                            </>
                          }
                          onClick={() => {
                            definirCobranca(
                              cenarioFinanceiro.detalhesMatricula.cobrancaId
                            )
                            modalRef?.current?.abrir()
                          }}
                        />
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </CursoContainer>
          )}
          {cenarioFinanceiro?.detalhesReingresso && (
            <CursoContainer>
              <div>
                <h4>Taxa de Reingresso</h4>
                <div>
                  <span>
                    <p>
                      <b>Valor: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro.detalhesReingresso?.valor
                      )}
                    </p>
                    <p>
                      <b>Cupom de desconto: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro?.detalhesReingresso
                          ?.valorCupomDesconto
                      )}
                    </p>
                    <p>
                      <b>Saldo devedor: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro?.detalhesReingresso?.saldoDevedor
                      )}
                    </p>
                  </span>
                  <span>
                    <p>
                      <b>Quantidade de parcelas: </b>
                      {
                        cenarioFinanceiro?.detalhesReingresso
                          ?.quantidadeParcelas
                      }
                    </p>
                    {semSaldoDevedorMatricula ? (
                      <p>
                        <b>Data de pagamento: </b>
                        {formatadores.data(
                          cenarioFinanceiro?.detalhesReingresso?.dataPagamento
                        )}
                      </p>
                    ) : (
                      <p>
                        <b>Data de vencimento: </b>
                        {formatadores.data(
                          cenarioFinanceiro.detalhesReingresso?.dataVencimento
                        )}
                      </p>
                    )}
                    <p>
                      <b>Forma de pagamento: </b>{' '}
                      {formatadores.pagamento(
                        cenarioFinanceiro.detalhesReingresso?.formaPagamento
                      )}
                    </p>
                  </span>
                  {cenarioFinanceiro.detalhesReingresso?.saldoDevedor === 0 ? (
                    <ImprimirReciboContainer>
                      {cenarioFinanceiro.detalhesReingresso.formaPagamento !==
                        MetodosPagamento.Isencao && (
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconeRecibo}
                              <ConteudoBotao>Imprimir Recibo</ConteudoBotao>
                            </>
                          }
                          onClick={() => {
                            obterRecibo(
                              cenarioFinanceiro.detalhesReingresso.cobrancaId
                            )
                          }}
                        />
                      )}
                    </ImprimirReciboContainer>
                  ) : (
                    <span>
                      {ehPerfilFinanceiro && (
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconeGerarBoleto}
                              <ConteudoBotao>
                                Baixa manual da cobrança
                              </ConteudoBotao>
                            </>
                          }
                          onClick={abrirModalBaixaManualTaxaReingresso}
                        />
                      )}
                      <Botao
                        type="button"
                        tema="Link"
                        elemento={
                          <>
                            {IconeGerarBoleto}
                            <ConteudoBotao>Gerar o Boleto</ConteudoBotao>
                          </>
                        }
                        onClick={cobrarTaxaReingressoPorBoleto}
                      />
                      <p>
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconePagamentoMatricula}
                              <ConteudoBotao>
                                Pagar com cartão de crédito
                              </ConteudoBotao>
                            </>
                          }
                          onClick={() => {
                            definirCobranca(
                              cenarioFinanceiro.detalhesReingresso.cobrancaId
                            )
                            modalRef?.current?.abrir()
                          }}
                        />
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </CursoContainer>
          )}
          {cenarioFinanceiro?.detalhesCobrancaTransferencia && (
            <CursoContainer>
              <div>
                <h4>Taxa de troca de curso</h4>
                <div>
                  <span>
                    <p>
                      <b>Valor: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro.detalhesCobrancaTransferencia?.valor
                      )}
                    </p>
                    <p>
                      <b>Saldo devedor: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro?.detalhesCobrancaTransferencia
                          ?.saldoDevedor
                      )}
                    </p>
                  </span>
                  <span>
                    <p>
                      <b>Quantidade de parcelas: </b>
                      {
                        cenarioFinanceiro?.detalhesCobrancaTransferencia
                          ?.quantidadeParcelas
                      }
                    </p>
                    {saldoDevedorTransferencia ? (
                      <p>
                        <b>Data de pagamento: </b>
                        {formatadores.data(
                          cenarioFinanceiro?.detalhesCobrancaTransferencia
                            ?.dataPagamento
                        )}
                      </p>
                    ) : (
                      <p>
                        <b>Data de vencimento: </b>
                        {formatadores.data(
                          cenarioFinanceiro.detalhesCobrancaTransferencia
                            ?.dataVencimento
                        )}
                      </p>
                    )}
                    <p>
                      <b>Forma de pagamento: </b>{' '}
                      {formatadores.pagamento(
                        cenarioFinanceiro.detalhesCobrancaTransferencia
                          ?.formaPagamento
                      )}
                    </p>
                  </span>
                  {saldoDevedorTransferencia ? (
                    <ImprimirReciboContainer>
                      {cenarioFinanceiro.detalhesCobrancaTransferencia
                        ?.formaPagamento !==
                        MetodosPagamento[MetodosPagamento.Isencao] && (
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconeRecibo}
                              <ConteudoBotao>Imprimir Recibo</ConteudoBotao>
                            </>
                          }
                          onClick={() => {
                            obterRecibo(
                              cenarioFinanceiro.detalhesCobrancaTransferencia
                                .cobrancaId
                            )
                          }}
                        />
                      )}
                    </ImprimirReciboContainer>
                  ) : (
                    <span>
                      {ehPerfilFinanceiro && (
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconeGerarBoleto}
                              <ConteudoBotao>
                                Baixa manual da cobrança
                              </ConteudoBotao>
                            </>
                          }
                          onClick={abrirModalBaixaManualTaxaTrocaCurso}
                        />
                      )}
                      <Botao
                        type="button"
                        tema="Link"
                        elemento={
                          <>
                            {IconeGerarBoleto}
                            <ConteudoBotao>Gerar o Boleto</ConteudoBotao>
                          </>
                        }
                        onClick={cobrarTaxaTrocaDeCursoPorBoleto}
                      />
                      <p>
                        <Botao
                          type="button"
                          tema="Link"
                          elemento={
                            <>
                              {IconePagamentoMatricula}
                              <ConteudoBotao>
                                Pagar com cartão de crédito
                              </ConteudoBotao>
                            </>
                          }
                          onClick={() => {
                            definirCobranca(
                              cenarioFinanceiro.detalhesCobrancaTransferencia
                                .cobrancaId
                            )
                            modalRef?.current?.abrir()
                          }}
                        />
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </CursoContainer>
          )}
          <CursoContainer>
            <div>
              <h4>Curso</h4>
              <div>
                <span>
                  <p>
                    <b>Valor total do curso:</b>
                    {formatadores.moeda(
                      cenarioFinanceiro.detalhesCurso.valorTotalCurso
                    )}
                  </p>
                  <p>
                    <b>Valor de desconto: </b>
                    {formatadores.moeda(
                      cenarioFinanceiro.detalhesCurso.valorCupomDesconto
                    )}
                  </p>
                  <p>
                    <b>Valor com desconto: </b>
                    {formatadores.moeda(valorComDesconto)}
                  </p>
                </span>
                <span>
                  <p>
                    <b>Quantidade de parcelas: </b>
                    {totalParcelas.length}
                  </p>
                  <p>
                    <b>Quantidade de parcelas não pagas: </b>
                    {parcelasNaoPagas.length}
                  </p>
                  <p>
                    <b>Quantidade de parcelas vencidas: </b>
                    {parcelasNegativadas.length}
                  </p>
                </span>
                <span>
                  <p>
                    <b>Saldo pago: </b>
                    {formatadores.moeda(valorSomado)}
                  </p>
                  <ParagrafoDoSaldoDevedor>
                    <b>Saldo devedor S/J: </b>
                    <ParagrafoComEstilos>
                      {formatadores.moeda(SaldoDevedorSemJurosMenor)}
                    </ParagrafoComEstilos>
                  </ParagrafoDoSaldoDevedor>
                  <ParagrafoDoSaldoDevedor>
                    <b>Saldo devedor C/J: </b>
                    <ParagrafoComEstilos>
                      {formatadores.moeda(saldoDevedorComJurosMenorQue)}
                    </ParagrafoComEstilos>
                  </ParagrafoDoSaldoDevedor>
                  <p>
                    <b>Última negociação: </b>
                    {tratamentoConversao}
                  </p>
                  {cenarioFinanceiro.podeAlterarPlanoPagamento &&
                    !ehRecorrente && (
                      <p>
                        <BotaoDeAtualizacaoDoPlanoDePagamento
                          onClick={() => {
                            modalAtualizarPlanoDePagamentoRef?.current.abrir()
                          }}
                        >
                          {IconeDeAtualizacaoDePlanoDePagamento}
                          <span>Atualizar Plano de Pagamento</span>
                        </BotaoDeAtualizacaoDoPlanoDePagamento>
                      </p>
                      // eslint-disable-next-line indent
                    )}
                </span>
              </div>

              {ehRecorrente && (visaoAluno || visaoResponsavel) && (
                <>
                  {!recorrenciaFinalizada ? (
                    <ContainerBotaoCartaoRecorrencia>
                      <Botao
                        texto={
                          SituacaoFinanceira[
                            cenarioFinanceiro.situacaoFinanceira
                          ] ===
                          SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente
                            ? 'Cadastrar cartão'
                            : 'Alterar cartão'
                        }
                        type="button"
                        tema="Secundario"
                        onClick={() => {
                          modalCartaoRecorrenciaRef?.current?.abrir()
                        }}
                      />
                    </ContainerBotaoCartaoRecorrencia>
                  ) : (
                    <TextoRecorrenciaFinalizada>
                      Pagamento recorrente desativado, para quitação de
                      pendências, entrar em contato com setor financeiro pelo
                      número (51) 3062-9322
                    </TextoRecorrenciaFinalizada>
                  )}
                </>
              )}
            </div>
            {ehRecorrente && ehPerfilFinanceiro && (
              <Linha>
                {!recorrenciaFinalizada ? (
                  <ContainerBotaoCartaoRecorrencia>
                    <Botao
                      texto="Cancelar recorrência"
                      type="button"
                      tema="Secundario"
                      onClick={() => {
                        modalCancelarRecorrenciaRef?.current?.abrir()
                      }}
                    />
                  </ContainerBotaoCartaoRecorrencia>
                ) : (
                  <TextoRecorrenciaFinalizada>
                    Pagamento recorrente desativado
                  </TextoRecorrenciaFinalizada>
                )}
                <ContainerBotaoCartaoRecorrencia>
                  <Botao
                    texto="Realizar baixas manual"
                    type="button"
                    tema="Secundario"
                    onClick={() => {
                      realizarBaixasManual()
                    }}
                  />
                </ContainerBotaoCartaoRecorrencia>
                <ContainerBotaoCartaoRecorrencia>
                  <Botao
                    texto="Vincular pagamentos"
                    type="button"
                    tema="Secundario"
                    onClick={() => {
                      realizarVinculoPagamentos(matricula)
                    }}
                  />
                </ContainerBotaoCartaoRecorrencia>
              </Linha>
            )}
          </CursoContainer>

          {cenarioFinanceiro.detalhesTCC?.valorTotalTCC && naoEhLivre ? (
            <CursoContainer>
              <div>
                <h4>TCC</h4>
                <div>
                  <span>
                    <p>
                      <b>Valor total do TCC: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro.detalhesTCC?.valorTotalTCC
                      )}
                    </p>
                    <p>
                      <b>Cupom de desconto: </b>
                      {formatadores.moeda(
                        cenarioFinanceiro.detalhesTCC?.valorCupomDesconto
                      )}
                    </p>
                  </span>
                  <span>
                    <ParagrafoDoSaldoDevedor>
                      <b>Saldo devedor: </b>
                      <ParagrafoComEstilos>
                        {formatadores.moeda(
                          cenarioFinanceiro.detalhesTCC?.saldoDevedor
                        )}
                      </ParagrafoComEstilos>
                    </ParagrafoDoSaldoDevedor>
                    <p>
                      <b>Quantidade de parcelas: </b>
                      {cenarioFinanceiro.detalhesTCC?.quantidadeParcelas}
                    </p>
                  </span>
                </div>
              </div>
            </CursoContainer>
          ) : null}
          <ContainerInfo>
            <span>{IconeInformacao}</span>
            <ParagrafoDeAviso>
              Pagamentos após a data de vencimento implicarão na cobrança de
              multa de {cenarioFinanceiro.valorMulta}% e juros de{' '}
              {cenarioFinanceiro.valorJurosDia.toLocaleString()}% sobre o valor
              total da parcela.
              <br />
              Caso o pagamento fique duplicado, será necessário entrar em
              contato com o setor financeiro através do e-mail{' '}
              <a href="mailto:financeiro@ipgs.com.br">financeiro@ipgs.com.br</a>
            </ParagrafoDeAviso>
          </ContainerInfo>

          {cenarioFinanceiro?.detalheParcelas?.length > 0 && (
            <>
              <ContainerTabela>
                <Accordion
                  titulo="Parcelas Curso"
                  iniciarAberto={false}
                  desabilitado={accordionParcelasCursoBloqueado}
                >
                  <Tabela
                    Id="tabela-parcelas-curso"
                    key="tabela-parcelas-curso"
                    TemaLinha={(linha: TabelaDados) => {
                      const parcela = (linha as unknown) as DetalheDaParcela
                      switch (parcela.situacaoParcela) {
                        case SituacaoParcela.AguardandoPagamentoAutomatico.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.Negativado.toString():
                          return TemaLinha.Negativado

                        case SituacaoParcela.Pago.toString():
                          return TemaLinha.Pago

                        case SituacaoParcela.Renegociado.toString():
                          return TemaLinha.Renegociado

                        case SituacaoParcela.Vencido.toString():
                          return TemaLinha.Erro

                        default:
                          return TemaLinha.Padrao
                      }
                    }}
                    ref={tabelaRef}
                    ComMarcacao={ehPerfilFinanceiro}
                    ComMarcacaoCondicao={
                      ehPerfilFinanceiro ? linha => linha.cobrancaId : undefined
                    }
                    OnChangeMarcacao={dados => {
                      definirItensCursosSelecionados(
                        dados.map(d => d.cobrancaId)
                      )
                      definirSituacaoItensCursoSelecionados(
                        dados.map(d => d.situacaoParcela)
                      )
                    }}
                    Colunas={[
                      {
                        Chave: 'parcelaNumero',
                        Texto: 'Parcela',
                        StyleColuna: { whiteSpace: 'nowrap' }
                      },
                      {
                        Chave: 'valor',
                        Texto: 'Valor',
                        Renderizar: (linha: TabelaDados) => {
                          const parcela = (linha as unknown) as DetalheDaParcela
                          return (
                            <ContainerDoValorDaTabela>
                              <span>{formatadores.moeda(linha.valor)}</span>
                              {exibirInfoParcelaAtraso(parcela) && (
                                <TooltipParcelaEmAtrasoComEstilos
                                  id={parcela.cobrancaId}
                                  detalhes={
                                    parcela.informacoesParcelaAposVencimento
                                  }
                                />
                              )}
                              {parcela.observacao && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacao}
                                />
                              )}
                              {visaoIPGS && parcela.observacaoInterna && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacaoInterna}
                                  interna
                                />
                              )}
                            </ContainerDoValorDaTabela>
                          )
                        },
                        StyleColuna: { width: 130 }
                      },
                      {
                        Chave: 'dataVencimento',
                        Texto: 'Vencimento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(
                              linha.dataVencimento
                            )}
                          </>
                        ),
                        StyleColuna: { whiteSpace: 'nowrap' }
                      },
                      {
                        Chave: 'dataPagamento',
                        Texto: 'Data de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(linha.dataPagamento)}
                          </>
                        ),
                        StyleColuna: { width: 110 }
                      },
                      {
                        Chave: 'formaPagamento',
                        Texto: 'Forma de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>{formatadores.pagamento(linha.formaPagamento)}</>
                        ),
                        StyleColuna: { width: 130 }
                      },
                      {
                        Chave: 'SituacaoParcela',
                        Texto: 'Situação',
                        Renderizar: (linha: TabelaDados) => (
                          <>{SituacaoParcela[linha.situacaoParcela]}</>
                        )
                      },
                      {
                        Chave: 'acoes',
                        Alinhamento: 'center',
                        Renderizar: (linha: TabelaDados) => (
                          <ContainerAcoes>
                            {exibirBaixarComprovanteCobranca(linha) && (
                              <button
                                type="button"
                                data-tip="Download do comprovante"
                                data-for={`${linha.id}-tooltip`}
                                onClick={e => {
                                  e.stopPropagation()
                                  baixarComprovanteCobranca(linha)
                                }}
                              >
                                <IconeTabela>{IconeFinanceiro}</IconeTabela>
                              </button>
                            )}
                            {linha.dataPagamento !== null ? (
                              <button
                                type="button"
                                data-tip="Imprimir Recibo"
                                data-for={`${linha.id}-tooltip`}
                                onClick={() => {
                                  obterRecibo(linha.cobrancaId)
                                }}
                              >
                                <IconeTabela>{IconeRecibo}</IconeTabela>
                              </button>
                            ) : (
                              <>
                                {exibirBotaoPagamentoCurso(
                                  linha.situacaoParcela,
                                  linha.maximoTentativasRecorrencia
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Pagar com Pix"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() => {
                                      modalRefPix?.current?.abrir(
                                        linha.cobrancaId,
                                        matricula
                                      )
                                    }}
                                  >
                                    <IconeTabela>{IconePix}</IconeTabela>
                                  </button>
                                )}
                                {exibirBotaoPagamentoCurso(
                                  linha.situacaoParcela,
                                  linha.maximoTentativasRecorrencia
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Pagar com cartão de crédito"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() => {
                                      definirCobranca(linha.cobrancaId)
                                      modalRef?.current?.abrir(
                                        +linha.valor,
                                        linha.permiteParcelamento
                                      )
                                    }}
                                  >
                                    <IconeTabela>
                                      {IconePagamentoMatricula}
                                    </IconeTabela>
                                  </button>
                                )}
                                {exibirBotaoPagamentoCurso(
                                  linha.situacaoParcela,
                                  linha.maximoTentativasRecorrencia
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Gerar boleto"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() =>
                                      gerarBoleto(linha.cobrancaId)
                                    }
                                  >
                                    <IconeTabela>
                                      {IconeGerarBoleto}
                                    </IconeTabela>
                                  </button>
                                )}
                              </>
                            )}
                            <Tooltip
                              id={`${linha.id}-tooltip`}
                              offset={{ top: 10 }}
                            />
                          </ContainerAcoes>
                        )
                      }
                    ]}
                    ObterDados={obterDadosTabelaParcelasAtivas}
                    TextoSemResultados={mensagemSemResultados}
                  />
                </Accordion>
              </ContainerTabela>

              <ContainerParcelas>
                <TabelaComEstilos>
                  <thead>
                    <th align="center">Parcelas</th>
                  </thead>
                  <tbody>
                    {cenarioFinanceiro?.detalheParcelas?.map(parcela => (
                      <tr key={parcela.parcelaNumero}>
                        <td align="left" width="300px">
                          <p>
                            <b>Parcela </b>
                            {parcela.parcelaNumero}
                          </p>
                          <p>
                            <b>Valor: </b>
                            {formatadores.moeda(parcela.valor)}
                          </p>
                          <p>
                            <b>Data de Vencimento: </b>
                            {formatadores.data(parcela.dataVencimento)}
                          </p>
                          <p>
                            <b>Data de Pagamento: </b>
                            {formatadores.data(parcela.dataPagamento)}
                          </p>
                          <p>
                            <b>Forma de Pagamento: </b>
                            {formatadores.pagamento(parcela.formaPagamento)}
                          </p>
                          <p>
                            <b>Situação</b>
                            {<>{SituacaoParcela[parcela.situacaoParcela]}</>}
                          </p>
                          <ContainerItemAcoes>
                            {parcela.dataPagamento !== null ? (
                              <Botao
                                type="button"
                                tema="Link"
                                elemento={
                                  <>
                                    {IconeRecibo}
                                    <ParagrafoDoBotaoDeAcao>
                                      Imprimir Recibo
                                    </ParagrafoDoBotaoDeAcao>
                                  </>
                                }
                                onClick={() => {
                                  obterRecibo(parcela.cobrancaId)
                                }}
                              />
                            ) : (
                              exibirBotaoPagamentoCurso(
                                parcela.situacaoParcela,
                                parcela.maximoTentativasRecorrencia
                              ) && (
                                <>
                                  <Botao
                                    type="button"
                                    tema="Link"
                                    elemento={
                                      <>
                                        {IconePagamentoMatricula}
                                        <ParagrafoDoBotaoDeAcao>
                                          Pagar no Cartão
                                        </ParagrafoDoBotaoDeAcao>
                                      </>
                                    }
                                    onClick={() => {
                                      definirCobranca(parcela.cobrancaId)
                                      modalRef?.current?.abrir(
                                        +parcela.valor,
                                        parcela.permiteParcelamento
                                      )
                                    }}
                                  />
                                  <Botao
                                    type="button"
                                    tema="Link"
                                    elemento={
                                      <>
                                        {IconeGerarBoleto}
                                        <ParagrafoDoBotaoDeAcao>
                                          Gerar Boleto
                                        </ParagrafoDoBotaoDeAcao>
                                      </>
                                    }
                                    onClick={() =>
                                      gerarBoleto(parcela.cobrancaId)
                                    }
                                  />
                                </>
                              )
                            )}
                          </ContainerItemAcoes>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TabelaComEstilos>
              </ContainerParcelas>

              <Spacer padding="10px 0px" />

              <ContainerTabela>
                <Accordion
                  titulo="Histórico Financeiro"
                  iniciarAberto={false}
                  desabilitado={accordionParcelasCursoBloqueado}
                >
                  <Tabela
                    Id="tabela-historico-financeiro"
                    key="tabela-historico-financeiro"
                    TemaLinha={(linha: TabelaDados) => {
                      const parcela = (linha as unknown) as DetalheDaParcela
                      switch (parcela.situacaoParcela) {
                        case SituacaoParcela.Cancelado.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.Reembolso.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.AguardandoPagamentoAutomatico.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.Negativado.toString():
                          return TemaLinha.Negativado

                        case SituacaoParcela.Pago.toString():
                          return TemaLinha.Pago

                        case SituacaoParcela.Renegociado.toString():
                          return TemaLinha.Renegociado

                        case SituacaoParcela.Vencido.toString():
                          return TemaLinha.Erro

                        default:
                          return TemaLinha.Padrao
                      }
                    }}
                    ref={tabelaRef}
                    ComMarcacao={ehPerfilFinanceiro}
                    ComMarcacaoCondicao={
                      ehPerfilFinanceiro ? linha => linha.cobrancaId : undefined
                    }
                    OnChangeMarcacao={dados => {
                      definirItensCursosSelecionados(
                        dados.map(d => d.cobrancaId)
                      )
                      definirSituacaoItensCursoSelecionados(
                        dados.map(d => d.situacaoParcela.Renegociado)
                      )
                    }}
                    Colunas={[
                      {
                        Chave: 'parcelaNumero',
                        Texto: 'Parcela',
                        StyleColuna: { whiteSpace: 'nowrap' }
                      },
                      {
                        Chave: 'valor',
                        Texto: 'Valor',
                        Renderizar: (linha: TabelaDados) => {
                          const parcela = (linha as unknown) as DetalheDaParcela
                          return (
                            <ContainerDoValorDaTabela>
                              <span>{formatadores.moeda(linha.valor)}</span>
                              {exibirInfoParcelaAtraso(parcela) && (
                                <TooltipParcelaEmAtrasoComEstilos
                                  id={parcela.cobrancaId}
                                  detalhes={
                                    parcela.informacoesParcelaAposVencimento
                                  }
                                />
                              )}
                              {parcela.observacao && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacao}
                                />
                              )}
                              {visaoIPGS && parcela.observacaoInterna && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacaoInterna}
                                  interna
                                />
                              )}
                            </ContainerDoValorDaTabela>
                          )
                        },
                        StyleColuna: { width: 130 }
                      },
                      {
                        Chave: 'dataVencimento',
                        Texto: 'Vencimento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(
                              linha.dataVencimento
                            )}
                          </>
                        ),
                        StyleColuna: { whiteSpace: 'nowrap' }
                      },
                      {
                        Chave: 'SituacaoParcela',
                        Texto: 'Situação',
                        Renderizar: (linha: TabelaDados) => (
                          <>{SituacaoParcela[linha.situacaoParcela]}</>
                        )
                      }
                    ]}
                    ObterDados={obterDadosTabelaHistorico}
                    TextoSemResultados={mensagemSemResultados}
                  />
                </Accordion>
              </ContainerTabela>

              <ContainerParcelas>
                <TabelaComEstilos>
                  <thead>
                    <th align="center">Parcelas</th>
                  </thead>
                  <tbody>
                    {cenarioFinanceiro?.detalheParcelas?.map(parcela => (
                      <tr key={parcela.parcelaNumero}>
                        <td align="left" width="300px">
                          <p>
                            <b>Parcela </b>
                            {parcela.parcelaNumero}
                          </p>
                          <p>
                            <b>Valor: </b>
                            {formatadores.moeda(parcela.valor)}
                          </p>
                          <p>
                            <b>Data de Vencimento: </b>
                            {formatadores.data(parcela.dataVencimento)}
                          </p>
                          <p>
                            <b>Situação</b>
                            {<>{SituacaoParcela[parcela.situacaoParcela]}</>}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TabelaComEstilos>
              </ContainerParcelas>

              <Spacer padding="10px 0px" />
            </>
          )}

          {cenarioFinanceiro?.detalhesParcelasRecuperacao?.length > 0 && (
            <>
              <ContainerTabela>
                <Accordion
                  titulo="Parcelas Recuperação"
                  iniciarAberto={false}
                  desabilitado={accordionParcelasRecuperacaoBloqueado}
                >
                  <Tabela
                    Id="tabela-parcelas-recuperacao"
                    key="tabela-parcelas-recuperacao"
                    TemaLinha={(linha: TabelaDados) => {
                      const parcela = (linha as unknown) as DetalheDaParcelaDaRecuperacao
                      switch (parcela.situacaoParcela) {
                        case SituacaoParcela.Cancelado.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.AguardandoPagamentoAutomatico.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.Negativado.toString():
                          return TemaLinha.Negativado

                        case SituacaoParcela.Pago.toString():
                          return TemaLinha.Pago

                        case SituacaoParcela.Renegociado.toString():
                          return TemaLinha.Renegociado

                        case SituacaoParcela.Vencido.toString():
                          return TemaLinha.Erro

                        default:
                          return TemaLinha.Padrao
                      }
                    }}
                    ref={tabelaRecupRef}
                    ComMarcacao={ehPerfilFinanceiro}
                    ComMarcacaoCondicao={
                      ehPerfilFinanceiro ? linha => linha.cobrancaId : undefined
                    }
                    OnChangeMarcacao={dados => {
                      definirItensRecuperacaoSelecionados(
                        dados.map(d => d.cobrancaId)
                      )
                      definirSituacaoItensRecuperacaoSelecionados(
                        dados.map(d => d.situacaoParcela)
                      )
                    }}
                    Colunas={[
                      {
                        Chave: 'parcelaNumero',
                        Texto: 'Parcela'
                      },
                      {
                        Chave: 'valor',
                        Texto: 'Valor',
                        Renderizar: (linha: TabelaDados) => {
                          const parcela = (linha as unknown) as DetalheDaParcelaDaRecuperacao
                          return (
                            <ContainerDoValorDaTabela>
                              <span>{formatadores.moeda(linha.valor)}</span>
                              {exibirInfoParcelaAtraso(parcela) && (
                                <TooltipParcelaEmAtrasoComEstilos
                                  id={parcela.cobrancaId}
                                  detalhes={
                                    parcela.informacoesParcelaAposVencimento
                                  }
                                />
                              )}
                              {parcela.observacao && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacao}
                                />
                              )}
                              {visaoIPGS && parcela.observacaoInterna && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacaoInterna}
                                  interna
                                />
                              )}
                            </ContainerDoValorDaTabela>
                          )
                        }
                      },
                      {
                        Chave: 'dataVencimento',
                        Texto: 'Vencimento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(
                              linha.dataVencimento
                            )}
                          </>
                        )
                      },
                      {
                        Chave: 'dataPagamento',
                        Texto: 'Data de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(linha.dataPagamento)}
                          </>
                        ),
                        StyleColuna: { width: 110 }
                      },
                      {
                        Chave: 'formaPagamento',
                        Texto: 'Forma de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>{formatadores.pagamento(linha.formaPagamento)}</>
                        )
                      },
                      {
                        Chave: 'SituacaoParcela',
                        Texto: 'Situação',
                        Renderizar: (linha: TabelaDados) => (
                          <>{SituacaoParcela[linha.situacaoParcela]}</>
                        )
                      },
                      {
                        Chave: 'acoes',
                        Alinhamento: 'center',
                        Renderizar: (linha: TabelaDados) => (
                          <ContainerAcoes>
                            {exibirBaixarComprovanteCobranca(linha) && (
                              <button
                                type="button"
                                data-tip="Download do comprovante"
                                data-for={`${linha.id}-tooltip`}
                                onClick={e => {
                                  e.stopPropagation()
                                  baixarComprovanteCobranca(linha)
                                }}
                              >
                                <IconeTabela>{IconeFinanceiro}</IconeTabela>
                              </button>
                            )}
                            {linha.dataPagamento !== null ? (
                              <button
                                type="button"
                                data-tip="Imprimir Recibo"
                                data-for={`${linha.id}-tooltip`}
                                onClick={() => {
                                  obterRecibo(linha.cobrancaId)
                                }}
                              >
                                <IconeTabela>{IconeRecibo}</IconeTabela>
                              </button>
                            ) : (
                              <>
                                {exibirBotaoPagamento(
                                  linha.situacaoParcela
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Pagar com cartão de crédito"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() => {
                                      definirCobranca(linha.cobrancaId)
                                      modalRef?.current?.abrir(
                                        +linha.valor,
                                        linha.permiteParcelamento
                                      )
                                    }}
                                  >
                                    <IconeTabela>
                                      {IconePagamentoMatricula}
                                    </IconeTabela>
                                  </button>
                                )}
                                {exibirBotaoPagamento(
                                  linha.situacaoParcela
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Gerar boleto"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() =>
                                      gerarBoleto(linha.cobrancaId)
                                    }
                                  >
                                    <IconeTabela>
                                      {IconeGerarBoleto}
                                    </IconeTabela>
                                  </button>
                                )}
                              </>
                            )}
                            <Tooltip
                              id={`${linha.id}-tooltip`}
                              offset={{ top: 10 }}
                            />
                          </ContainerAcoes>
                        )
                      }
                    ]}
                    ObterDados={obterDadosRecuperacao}
                    TextoSemResultados={mensagemSemResultados}
                  />
                </Accordion>
              </ContainerTabela>

              <ContainerParcelas>
                <TabelaComEstilos>
                  <thead>
                    <th align="center">Parcelas</th>
                  </thead>
                  <tbody>
                    {cenarioFinanceiro?.detalhesParcelasRecuperacao?.map(
                      parcela => (
                        <tr key={parcela.parcelaNumero}>
                          <td align="left" width="300px">
                            <p>
                              <b>Parcela </b>
                              {parcela.parcelaNumero}
                            </p>
                            <p>
                              <b>Valor: </b>
                              {formatadores.moeda(parcela.valor)}
                            </p>
                            <p>
                              <b>Data de Vencimento: </b>
                              {formatadores.data(parcela.dataVencimento)}
                            </p>
                            <p>
                              <b>Data de Pagamento: </b>
                              {formatadores.data(parcela.dataPagamento)}
                            </p>
                            <p>
                              <b>Forma de Pagamento: </b>
                              {formatadores.pagamento(parcela.formaPagamento)}
                            </p>
                            <p>
                              <b>Situação</b>
                              {<>{SituacaoParcela[parcela.situacaoParcela]}</>}
                            </p>
                            <ContainerItemAcoes>
                              {parcela.dataPagamento !== null ? (
                                <Botao
                                  type="button"
                                  tema="Link"
                                  elemento={
                                    <>
                                      {IconeRecibo}
                                      <ParagrafoDoBotaoDeAcao>
                                        Imprimir Recibo
                                      </ParagrafoDoBotaoDeAcao>
                                    </>
                                  }
                                  onClick={() => {
                                    obterRecibo(parcela.cobrancaId)
                                  }}
                                />
                              ) : (
                                exibirBotaoPagamento(
                                  parcela.situacaoParcela
                                ) && (
                                  <>
                                    <Botao
                                      type="button"
                                      tema="Link"
                                      elemento={
                                        <>
                                          {IconePagamentoMatricula}
                                          <ParagrafoDoBotaoDeAcao>
                                            Pagar no Cartão
                                          </ParagrafoDoBotaoDeAcao>
                                        </>
                                      }
                                      onClick={() => {
                                        definirCobranca(parcela.cobrancaId)
                                        modalRef?.current?.abrir(
                                          +parcela.valor,
                                          parcela.permiteParcelamento
                                        )
                                      }}
                                    />
                                    <Botao
                                      type="button"
                                      tema="Link"
                                      elemento={
                                        <>
                                          {IconeGerarBoleto}
                                          <ParagrafoDoBotaoDeAcao>
                                            Gerar Boleto
                                          </ParagrafoDoBotaoDeAcao>
                                        </>
                                      }
                                      onClick={() =>
                                        gerarBoleto(parcela.cobrancaId)
                                      }
                                    />
                                  </>
                                )
                              )}
                            </ContainerItemAcoes>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </TabelaComEstilos>
              </ContainerParcelas>

              <Spacer padding="10px 0px" />
            </>
          )}

          {cenarioFinanceiro?.detalhesParcelasTCC?.length > 0 && (
            <>
              <ContainerTabela>
                <Accordion
                  titulo="Parcelas TCC"
                  iniciarAberto={false}
                  desabilitado={accordionParcelasTCCBloqueado}
                >
                  <Tabela
                    Id="tabela-parcelas-tcc"
                    key="tabela-parcelas-tcc"
                    TemaLinha={(linha: TabelaDados) => {
                      const parcela = (linha as unknown) as DetalheDaParcela
                      switch (parcela.situacaoParcela) {
                        case SituacaoParcela.Cancelado.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.AguardandoPagamentoAutomatico.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.Negativado.toString():
                          return TemaLinha.Negativado

                        case SituacaoParcela.Pago.toString():
                          return TemaLinha.Pago

                        case SituacaoParcela.Renegociado.toString():
                          return TemaLinha.Renegociado

                        case SituacaoParcela.Vencido.toString():
                          return TemaLinha.Erro

                        default:
                          return TemaLinha.Padrao
                      }
                    }}
                    ref={tabelaTCCRef}
                    ComMarcacao={ehPerfilFinanceiro}
                    ComMarcacaoCondicao={
                      ehPerfilFinanceiro ? linha => linha.cobrancaId : undefined
                    }
                    OnChangeMarcacao={dados => {
                      definirItensTCCSelecionados(dados.map(d => d.cobrancaId))
                      definirSituacaoItensTCCSelecionados(
                        dados.map(d => d.situacaoParcela)
                      )
                    }}
                    Colunas={[
                      {
                        Chave: 'parcelaNumero',
                        Texto: 'Parcela'
                      },
                      {
                        Chave: 'valor',
                        Texto: 'Valor',
                        Renderizar: (linha: TabelaDados) => {
                          const parcela = (linha as unknown) as DetalheDaParcela
                          return (
                            <ContainerDoValorDaTabela>
                              <span>{formatadores.moeda(linha.valor)}</span>
                              {exibirInfoParcelaAtraso(parcela) && (
                                <TooltipParcelaEmAtrasoComEstilos
                                  id={parcela.cobrancaId}
                                  detalhes={
                                    parcela.informacoesParcelaAposVencimento
                                  }
                                />
                              )}
                              {parcela.observacao && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacao}
                                />
                              )}
                              {visaoIPGS && parcela.observacaoInterna && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacaoInterna}
                                  interna
                                />
                              )}
                            </ContainerDoValorDaTabela>
                          )
                        }
                      },
                      {
                        Chave: 'dataVencimento',
                        Texto: 'Vencimento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(
                              linha.dataVencimento
                            )}
                          </>
                        )
                      },
                      {
                        Chave: 'dataPagamento',
                        Texto: 'Data de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(linha.dataPagamento)}
                          </>
                        ),
                        StyleColuna: { width: 110 }
                      },
                      {
                        Chave: 'formaPagamento',
                        Texto: 'Forma de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>{formatadores.pagamento(linha.formaPagamento)}</>
                        )
                      },
                      {
                        Chave: 'SituacaoParcela',
                        Texto: 'Situação',
                        Renderizar: (linha: TabelaDados) => (
                          <>{SituacaoParcela[linha.situacaoParcela]}</>
                        )
                      },
                      {
                        Chave: 'acoes',
                        Alinhamento: 'center',
                        Renderizar: (linha: TabelaDados) => (
                          <ContainerAcoes>
                            {exibirBaixarComprovanteCobranca(linha) && (
                              <button
                                type="button"
                                data-tip="Download do comprovante"
                                data-for={`${linha.id}-tooltip`}
                                onClick={e => {
                                  e.stopPropagation()
                                  baixarComprovanteCobranca(linha)
                                }}
                              >
                                <IconeTabela>{IconeFinanceiro}</IconeTabela>
                              </button>
                            )}
                            {linha.dataPagamento !== null ? (
                              <button
                                type="button"
                                data-tip="Imprimir Recibo"
                                data-for={`${linha.id}-tooltip`}
                                onClick={() => {
                                  obterRecibo(linha.cobrancaId)
                                }}
                              >
                                <IconeTabela>{IconeRecibo}</IconeTabela>
                              </button>
                            ) : (
                              <>
                                {exibirBotaoPagamentoTCC(
                                  linha.situacaoParcela
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Pagar com cartão de crédito"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() => {
                                      definirCobranca(linha.cobrancaId)
                                      modalRef?.current?.abrir(
                                        +linha.valor,
                                        linha.permiteParcelamento
                                      )
                                    }}
                                  >
                                    <IconeTabela>
                                      {IconePagamentoMatricula}
                                    </IconeTabela>
                                  </button>
                                )}
                                {exibirBotaoPagamentoTCC(
                                  linha.situacaoParcela
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Gerar boleto"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() =>
                                      gerarBoleto(linha.cobrancaId)
                                    }
                                  >
                                    <IconeTabela>
                                      {IconeGerarBoleto}
                                    </IconeTabela>
                                  </button>
                                )}
                              </>
                            )}
                            <Tooltip
                              id={`${linha.id}-tooltip`}
                              offset={{ top: 10 }}
                            />
                          </ContainerAcoes>
                        )
                      }
                    ]}
                    ObterDados={obterDadosTCC}
                  />
                </Accordion>
              </ContainerTabela>
              <ContainerParcelas>
                <TabelaComEstilos>
                  <thead>
                    <th align="center">Parcelas TCC</th>
                  </thead>
                  <tbody>
                    {cenarioFinanceiro?.detalhesParcelasTCC?.map(parcela => (
                      <tr key={parcela.parcelaNumero}>
                        <td align="left" width="300px">
                          <p>
                            <b>Parcela </b>
                            {parcela.parcelaNumero}
                          </p>
                          <p>
                            <b>Valor: </b>
                            {formatadores.moeda(parcela.valor)}
                          </p>
                          <p>
                            <b>Data de Vencimento: </b>
                            {formatadores.data(parcela.dataVencimento)}
                          </p>
                          <p>
                            <b>Data de Pagamento: </b>
                            {formatadores.data(parcela.dataPagamento)}
                          </p>
                          <p>
                            <b>Forma de Pagamento: </b>
                            {formatadores.pagamento(parcela.formaPagamento)}
                          </p>
                          <p>
                            <b>Situação</b>
                            {<>{SituacaoParcela[parcela.situacaoParcela]}</>}
                          </p>
                          <ContainerItemAcoes>
                            {parcela.dataPagamento !== null ? (
                              <Botao
                                type="button"
                                tema="Link"
                                elemento={
                                  <>
                                    {IconeRecibo}
                                    <ParagrafoDoBotaoDeAcao>
                                      Imprimir Recibo
                                    </ParagrafoDoBotaoDeAcao>
                                  </>
                                }
                                onClick={() => {
                                  obterRecibo(parcela.cobrancaId)
                                }}
                              />
                            ) : (
                              exibirBotaoPagamentoTCC(
                                parcela.situacaoParcela
                              ) && (
                                <>
                                  <Botao
                                    type="button"
                                    tema="Link"
                                    elemento={
                                      <>
                                        {IconePagamentoMatricula}
                                        <ParagrafoDoBotaoDeAcao>
                                          Pagar no Cartão
                                        </ParagrafoDoBotaoDeAcao>
                                      </>
                                    }
                                    onClick={() => {
                                      definirCobranca(parcela.cobrancaId)
                                      modalRef?.current?.abrir(
                                        +parcela.valor,
                                        parcela.permiteParcelamento
                                      )
                                    }}
                                  />
                                  <Botao
                                    type="button"
                                    tema="Link"
                                    elemento={
                                      <>
                                        {IconeGerarBoleto}
                                        <ParagrafoDoBotaoDeAcao>
                                          Gerar Boleto
                                        </ParagrafoDoBotaoDeAcao>
                                      </>
                                    }
                                    onClick={() =>
                                      gerarBoleto(parcela.cobrancaId)
                                    }
                                  />
                                </>
                              )
                            )}
                          </ContainerItemAcoes>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TabelaComEstilos>
              </ContainerParcelas>

              <Spacer padding="10px 0px" />
            </>
          )}
          {cenarioFinanceiro?.detalhesParcelasProrrogacao?.length > 0 && (
            <>
              <ContainerTabela>
                <Accordion
                  titulo="Parcelas Prorrogação"
                  iniciarAberto={false}
                  desabilitado={accordionParcelasProrrogacaoBloqueado}
                >
                  <Tabela
                    Id="tabela-parcelas-prorrogacao"
                    key="tabela-parcelas-prorrogacao"
                    TemaLinha={(linha: TabelaDados) => {
                      const parcela = (linha as unknown) as DetalheDaParcela
                      switch (parcela.situacaoParcela) {
                        case SituacaoParcela.Cancelado.toString():
                          return TemaLinha.Atencao

                        case SituacaoParcela.Negativado.toString():
                          return TemaLinha.Negativado

                        case SituacaoParcela.Renegociado.toString():
                          return TemaLinha.Renegociado

                        case SituacaoParcela.Vencido.toString():
                          return TemaLinha.Erro

                        default:
                          return TemaLinha.Padrao
                      }
                    }}
                    ref={tabelaProrrogacaoRef}
                    ComMarcacao={ehPerfilFinanceiro}
                    ComMarcacaoCondicao={
                      ehPerfilFinanceiro ? linha => linha.cobrancaId : undefined
                    }
                    OnChangeMarcacao={dados => {
                      definirItensProrrogacaoSelecionados(
                        dados.map(d => d.cobrancaId)
                      )
                      definirSituacaoItensProrrogacaoSelecionados(
                        dados.map(d => d.situacaoParcela)
                      )
                    }}
                    Colunas={[
                      {
                        Chave: 'parcelaNumero',
                        Texto: 'Parcela'
                      },
                      {
                        Chave: 'valor',
                        Texto: 'Valor',
                        Renderizar: (linha: TabelaDados) => {
                          const parcela = (linha as unknown) as DetalheDaParcela
                          return (
                            <ContainerDoValorDaTabela>
                              <span>{formatadores.moeda(linha.valor)}</span>
                              {exibirInfoParcelaAtraso(parcela) && (
                                <TooltipParcelaEmAtrasoComEstilos
                                  id={parcela.cobrancaId}
                                  detalhes={
                                    parcela.informacoesParcelaAposVencimento
                                  }
                                />
                              )}
                              {parcela.observacao && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacao}
                                />
                              )}
                              {visaoIPGS && parcela.observacaoInterna && (
                                <TooltipParcelaObservacaoComEstilos
                                  id={`observacao-${parcela.cobrancaId}`}
                                  observacao={parcela.observacaoInterna}
                                  interna
                                />
                              )}
                            </ContainerDoValorDaTabela>
                          )
                        }
                      },
                      {
                        Chave: 'dataVencimento',
                        Texto: 'Vencimento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(
                              linha.dataVencimento
                            )}
                          </>
                        )
                      },
                      {
                        Chave: 'dataPagamento',
                        Texto: 'Data de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>
                            {formatadores.dataLocaleString(linha.dataPagamento)}
                          </>
                        ),
                        StyleColuna: { width: 110 }
                      },
                      {
                        Chave: 'formaPagamento',
                        Texto: 'Forma de Pagamento',
                        Renderizar: (linha: TabelaDados) => (
                          <>{formatadores.pagamento(linha.formaPagamento)}</>
                        )
                      },
                      {
                        Chave: 'SituacaoParcela',
                        Texto: 'Situação',
                        Renderizar: (linha: TabelaDados) => (
                          <>{SituacaoParcela[linha.situacaoParcela]}</>
                        )
                      },
                      {
                        Chave: 'acoes',
                        Alinhamento: 'center',
                        Renderizar: (linha: TabelaDados) => (
                          <ContainerAcoes>
                            {exibirBaixarComprovanteCobranca(linha) && (
                              <button
                                type="button"
                                data-tip="Download do comprovante"
                                data-for={`${linha.id}-tooltip`}
                                onClick={e => {
                                  e.stopPropagation()
                                  baixarComprovanteCobranca(linha)
                                }}
                              >
                                <IconeTabela>{IconeFinanceiro}</IconeTabela>
                              </button>
                            )}
                            {linha.dataPagamento !== null ? (
                              <button
                                type="button"
                                data-tip="Imprimir Recibo"
                                data-for={`${linha.id}-tooltip`}
                                onClick={() => {
                                  obterRecibo(linha.cobrancaId)
                                }}
                              >
                                <IconeTabela>{IconeRecibo}</IconeTabela>
                              </button>
                            ) : (
                              <>
                                {exibirBotaoPagamentoProrrogacao(
                                  linha.situacaoParcela
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Pagar com cartão de crédito"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() => {
                                      definirCobranca(linha.cobrancaId)
                                      modalRef?.current?.abrir(
                                        +linha.valor,
                                        linha.permiteParcelamento
                                      )
                                    }}
                                  >
                                    <IconeTabela>
                                      {IconePagamentoMatricula}
                                    </IconeTabela>
                                  </button>
                                )}
                                {exibirBotaoPagamentoProrrogacao(
                                  linha.situacaoParcela
                                ) && (
                                  <button
                                    type="button"
                                    data-tip="Gerar boleto"
                                    data-for={`${linha.id}-tooltip`}
                                    onClick={() =>
                                      gerarBoleto(linha.cobrancaId)
                                    }
                                  >
                                    <IconeTabela>
                                      {IconeGerarBoleto}
                                    </IconeTabela>
                                  </button>
                                )}
                              </>
                            )}
                            <Tooltip
                              id={`${linha.id}-tooltip`}
                              offset={{ top: 10 }}
                            />
                          </ContainerAcoes>
                        )
                      }
                    ]}
                    ObterDados={obterDadosProrrogacao}
                  />
                </Accordion>
              </ContainerTabela>
              <ContainerParcelas>
                <TabelaComEstilos>
                  <thead>
                    <th align="center">Parcelas Prorrogação</th>
                  </thead>
                  <tbody>
                    {cenarioFinanceiro?.detalhesParcelasProrrogacao?.map(
                      parcela => (
                        <tr key={parcela.parcelaNumero}>
                          <td align="left" width="300px">
                            <p>
                              <b>Parcela </b>
                              {parcela.parcelaNumero}
                            </p>
                            <p>
                              <b>Valor: </b>
                              {formatadores.moeda(parcela.valor)}
                            </p>
                            <p>
                              <b>Data de Vencimento: </b>
                              {formatadores.data(parcela.dataVencimento)}
                            </p>
                            <p>
                              <b>Data de Pagamento: </b>
                              {formatadores.data(parcela.dataPagamento)}
                            </p>
                            <p>
                              <b>Forma de Pagamento: </b>
                              {formatadores.pagamento(parcela.formaPagamento)}
                            </p>
                            <p>
                              <b>Situação</b>
                              {<>{SituacaoParcela[parcela.situacaoParcela]}</>}
                            </p>
                            <ContainerItemAcoes>
                              {parcela.dataPagamento !== null ? (
                                <Botao
                                  type="button"
                                  tema="Link"
                                  elemento={
                                    <>
                                      {IconeRecibo}
                                      <ParagrafoDoBotaoDeAcao>
                                        Imprimir Recibo
                                      </ParagrafoDoBotaoDeAcao>
                                    </>
                                  }
                                  onClick={() => {
                                    obterRecibo(parcela.cobrancaId)
                                  }}
                                />
                              ) : (
                                exibirBotaoPagamentoProrrogacao(
                                  parcela.situacaoParcela
                                ) && (
                                  <>
                                    <Botao
                                      type="button"
                                      tema="Link"
                                      elemento={
                                        <>
                                          {IconePagamentoMatricula}
                                          <ParagrafoDoBotaoDeAcao>
                                            Pagar no Cartão
                                          </ParagrafoDoBotaoDeAcao>
                                        </>
                                      }
                                      onClick={() => {
                                        definirCobranca(parcela.cobrancaId)
                                        modalRef?.current?.abrir(
                                          +parcela.valor,
                                          parcela.permiteParcelamento
                                        )
                                      }}
                                    />
                                    <Botao
                                      type="button"
                                      tema="Link"
                                      elemento={
                                        <>
                                          {IconeGerarBoleto}
                                          <ParagrafoDoBotaoDeAcao>
                                            Gerar Boleto
                                          </ParagrafoDoBotaoDeAcao>
                                        </>
                                      }
                                      onClick={() =>
                                        gerarBoleto(parcela.cobrancaId)
                                      }
                                    />
                                  </>
                                )
                              )}
                            </ContainerItemAcoes>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </TabelaComEstilos>
              </ContainerParcelas>

              <Spacer padding="10px 0px" />
            </>
          )}

          <ContainerBotao>
            {ehPerfilFinanceiro && (
              <>
                <ContainerNovaCompra>
                  <Botao
                    type="button"
                    texto="Nova Compra"
                    tema="Secundario"
                    onClick={acaoClickNovaCompra}
                  />
                </ContainerNovaCompra>
                {parcelasSelecionadas && (
                  <ContainerBotaoAlteracaoCobranca>
                    <Botao
                      id="btn-alterar-observacao"
                      type="button"
                      texto="Alterar observação"
                      tema="Secundario"
                      onClick={() => abrirModalAlteracaoObservacao()}
                    />
                  </ContainerBotaoAlteracaoCobranca>
                )}
                {!bloqueioDeBotoesPorSelecionarParcelaPaga &&
                  parcelasSelecionadas && (
                    <>
                      <ContainerBotaoAlteracaoCobranca>
                        <Botao
                          id="btn-baixa-cobranca"
                          type="button"
                          texto="Baixa manual"
                          tema="Secundario"
                          onClick={abrirModalBaixaManual}
                        />
                      </ContainerBotaoAlteracaoCobranca>
                      {renegociacaoEAlteracaoDataVisiveis && (
                        <>
                          <ContainerBotaoAlteracaoCobranca>
                            <Botao
                              type="button"
                              texto="Alterar vencimento"
                              tema="Secundario"
                              onClick={() =>
                                modalAtualizarDataCobrancaRef.current?.abrir()
                              }
                            />
                          </ContainerBotaoAlteracaoCobranca>
                        </>
                      )}
                      {exibirBotaoRenegociacao && (
                        <ContainerBotaoAlteracaoCobranca>
                          <Botao
                            type="button"
                            texto="Renegociação"
                            tema="Secundario"
                            onClick={() => abrirModalRenegociacao()}
                          />
                        </ContainerBotaoAlteracaoCobranca>
                      )}
                      <ContainerBotaoAlteracaoCobranca>
                        <Botao
                          type="button"
                          texto="Cancelar parcela"
                          tema="Secundario"
                          onClick={() => modalCancelarCobranca.current?.abrir()}
                        />
                      </ContainerBotaoAlteracaoCobranca>
                    </>
                    // eslint-disable-next-line indent
                  )}
                {!bloqueioDeBotoesPorSelecionarRenegociacao &&
                  parcelasSelecionadas && (
                    <ContainerBotaoAlteracaoCobranca>
                      <Botao
                        type="button"
                        texto="Reembolso parcela"
                        tema="Secundario"
                        onClick={() => modalReembolsoCobranca.current?.abrir()}
                      />
                    </ContainerBotaoAlteracaoCobranca>
                    // eslint-disable-next-line indent
                  )}
              </>
            )}

            {opcoesReciboAnual && !parcelasSelecionadas && (
              <ContainerBotaoAlteracaoCobranca>
                <Botao
                  type="button"
                  texto="Imprimir recibo"
                  tema="Secundario"
                  onClick={acaoClickReciboAnual}
                />
              </ContainerBotaoAlteracaoCobranca>
            )}
            <ContainerBotaoVoltar>
              <Botao
                type="button"
                tema="Secundario"
                texto="Voltar"
                onClick={() => history.goBack()}
              />
            </ContainerBotaoVoltar>
          </ContainerBotao>
        </>
      ) : (
        <Carregando texto="Carregando dados financeiros do curso..." />
      )}
      <ModalPagamentoCartao
        ref={modalRef}
        backdrop
        id="modal-pagamento"
        acaoPrimaria={pagamentoComCartaoDeCredito}
      />
      <ModalPagamentoParaPix
        ref={modalRefPix}
        backdrop
        id="modal-pagamento-pix"
      />
      <ModalAtualizarPlanoDePagamento
        ref={modalAtualizarPlanoDePagamentoRef}
        backdrop
        id="modal-atualizar-plano-de-pagamento"
        acaoPrimaria={atualizarPlanoDePagamento}
        idMatricula={matricula}
      />
      <ModalAtualizarDataCobranca
        ref={modalAtualizarDataCobrancaRef}
        backdrop
        id="modal-atualizar-data-cobranca"
        acaoPrimaria={atualizarDataCobranca}
      />
      <ModalBaixaManualCobranca
        ref={modalBaixaManualCobrancaRef}
        backdrop
        id="modal-baixa-manual-cobranca"
        acaoPrimaria={baixaManualCobranca}
      />

      <ModalBaixaManualTaxaMatricula
        ref={modalBaixaManualTaxaMatriculaRef}
        backdrop
        id="modal-baixa-manual-taxa-matricula"
        acaoPrimaria={baixaManualTaxaMatricula}
      />
      <ModalBaixaManualTaxaReingresso
        ref={modalBaixaManualTaxaReingressoRef}
        backdrop
        id="modal-baixa-manual-taxa-reingresso"
        acaoPrimaria={baixaManualTaxaReingresso}
      />
      <ModalRenegociacao
        ref={modalRenegociacaoRef}
        backdrop
        id="modal-renegociacoes"
        acaoPrimaria={enviarRenegociacao}
      />
      <ModalCancelarCobranca
        ref={modalCancelarCobranca}
        backdrop
        id="modal-cancelar-cobranca"
        acaoPrimaria={cancelarParcela}
      />
      <ModalReembolsoCobranca
        ref={modalReembolsoCobranca}
        backdrop
        id="modal-reembolso-cobranca"
        acaoPrimaria={reembolsoParcela}
      />
      <ModalNovaCompra
        ref={modalNovaCompraRef}
        backdrop
        id="modal-nova-compra"
        acaoPrimaria={novaCompra}
      />
      <Modal
        ref={modalSucesso}
        backdrop
        id="modal-sucesso"
        icone={IconeModalSucesso}
        titulo={'Pagamento realizado.'}
        acaoPrimaria={{
          titulo: 'Ok',
          tipo: 'button',
          acao: () => modalSucesso?.current?.fechar()
        }}
      >
        <p>Aguarde a confirmação do pagamento</p>
      </Modal>
      <ModalPagamentoCartaoRecorrente
        ref={modalCartaoRecorrenciaRef}
        backdrop
        id="modal-cartao-recorrencia"
        acaoPrimaria={enviarCartaoRecorrencia}
      />
      <ModalCancelarRecorrencia
        ref={modalCancelarRecorrenciaRef}
        backdrop
        acaoPrimaria={cancelarRecorrencia}
        acaoSecundaria={() => modalCancelarRecorrenciaRef?.current?.fechar()}
      />
      <ModalBaixaManualTaxaTrocaCurso
        ref={modalBaixaManualTaxaTrocaCursoRef}
        backdrop
        id="modal-baixa-manual-taxa-troca-curso"
        acaoPrimaria={baixaManualTaxaTrocaCurso}
      />
      <ModalAlterarObservacao
        ref={modalAlterarObservacaoRef}
        backdrop
        id="modal-alterar-observacao"
        acaoPrimaria={alterarObservacao}
      />
      <ModalRecusaResponsavel
        ref={modalRecusaResponsavelRef}
        backdrop
        id="modal-recusa-responsavel"
        acaoPrimaria={recusaResponsavelFinanceiro}
      />
      <ModalAceiteResponsavel
        ref={modalAceiteResponsavelRef}
        backdrop
        id="modal-aceite-responsavel"
        acaoPrimaria={aceiteResponsavelFinanceiro}
      />
      <ModalVisualizacaoPdf
        ref={modalVisualizacaoRef}
        backdrop
        id="modal-visualizacao-documento-aluno"
      />
      <ModalReciboAnual
        ref={modalReciboAnualRef}
        backdrop
        id="modal-recibo-anual"
        acaoPrimaria={obterReciboAnual}
      ></ModalReciboAnual>
    </>
  )
}

const CursoOutros: FC<FinanceiroProps> = (props: FinanceiroProps) => (
  <ErroLayoutContainer.Provider>
    <Outros {...props} />
  </ErroLayoutContainer.Provider>
)

export default withRouter(CursoOutros)
