import { RouteComponentProps } from 'react-router-dom'

import { ControleDeRequisicao } from 'src/compartilhados'
import { ModalBaseProps, ModalRef } from 'src/componentes'
import { MatriculaAluno } from 'src/paginas/ipgs/alunos/edicao/tipos'
import { ModalidadeCurso, NovaCompra, TipoRecorrencia } from 'src/tipos'

export enum MetodosPagamento {
  CartaoCredito = 'Cartão de Crédito',
  Boleto = 'Boleto',
  CupomDesconto = 'Cupom de desconto',
  Pix = 'Pix',
  PagSeguro = 'PagSeguro',
  TED = 'TED',
  Dinheiro = 'Dinheiro',
  Deposito = 'Depósito',
  PayPal = 'PayPal',
  Cheque = 'Cheque',
  Isencao = 'Isenção',
  Recorrente = 'Recorrente'
}

export class Entity<T> {
  public id: T
}

export class Curso extends Entity<string> {
  id: string
  className?: string
  modalidade?: string
  titulo: string
  link?: string
  valorTotal?: string
  saldo?: string
  constructor(valorInicial: Partial<Curso> = {}) {
    super()
    Object.assign(this, valorInicial)
  }
}

export interface CursosProps {
  controleDeRequisicao: ControleDeRequisicao<Array<Curso>, string>
}

export type FinanceiroProps = RouteComponentProps<{
  matricula?: string
  modalidade?: ModalidadeCurso
  nomeDoCurso?: string
  usuarioId?: string
}>

export interface CursoLivre {
  id: string
  nome: string
  modalidade: keyof typeof ModalidadeCurso
  cupomDescontro: string
  saldoDevedor: number
  parcelas: number
  dataVencimento: string
  formaPagamento: MetodosPagamento
  valor: number
}

export interface CursoOutro {
  id: string
  nome: string
  modalidade: keyof typeof ModalidadeCurso
  cupomDescontro: string
  saldoDevedor: number
  parcelas: number
  contrato: string
  valor: number
}

export interface TaxaMatricula {
  valor: number
  modalidade: ModalidadeCurso
  cupomDescontro: string
  saldoDevedor: number
  parcelas: number
  dataVencimento: string
  dataPagamento: string
}

export interface ModalPagamentoRef extends Omit<ModalRef, 'abrir'> {
  abrir: (valorParcela?: number, permitirParcelamento?: boolean) => void
}

export interface ModalPagamentoProps extends ModalBaseProps {
  nome?: string
}

export interface ModalPagamentoRefPix extends Omit<ModalRef, 'abrir'> {
  abrir: (cobrancaId: string, matricula: string) => void
}

export interface ModalPagamentoPropsPix extends ModalBaseProps {
  cobrancaId?: string
  matricula?: string
}

export interface Dados {
  numeroCartao?: string
  nomeCartao?: string
  validadeCartao?: string
  codigoSeguranca?: string
}

export type FinanceiroCarrinhoCompraProps = RouteComponentProps<{
  carrinhoCompraId: string
}>

export enum TipoCobrancaAcao {
  Curso = 'Curso',
  TCC = 'TCC',
  Prorrogacao = 'Prorrogacao',
  Recuperacao = 'Recuperacao'
}
export enum TipoCobrancaAcaoPorExtenso {
  'Curso' = 'Curso',
  'TCC' = 'TCC',
  'Prorrogacao' = 'Prorrogação',
  'Recuperacao' = 'Recuperacao'
}

export interface DadosNovaCompra extends NovaCompra {
  tipoCobranca: TipoCobrancaAcao
  permitirParcelamento: boolean
  tipoRecorrencia?: TipoRecorrencia
}

export enum TipoRecorrenciaAcaoPorExtenso {
  'Padrao' = 'Padrão',
  'NegociacaoEspecial' = 'Aguardando pagamento - Negociação especial',
  'TrocaParcelamento' = 'Aguardando pagamento - Troca de parcelamento',
  'CartaoVencido' = 'Aguardando pagamento - Cartão vencido',
  'Transferencia' = 'Aguardando pagamento - Transferência'
}
