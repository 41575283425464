import React, { useState, useEffect, useMemo, useRef } from 'react'
import { withRouter, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  FormRef,
  IconeEditar,
  IconeLixeira,
  Tooltip,
  MoedaInputUnform,
  NumeroInputUnform,
  Carregando,
  IconeAtencao
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Breadcrumb } from 'src/componentes/breadcrumb'
import { Cabecalho } from 'src/componentes/cabecalho'
import { Container as ContainerErroLayout } from 'src/componentes/erro-container-layout/styles'
import {
  focarPrimeiroCampoComErro,
  formatarValorMoeda
} from 'src/componentes/funcoes'
import { IconeBack } from 'src/componentes/icones'
import { Link } from 'src/componentes/link'
import { Modal } from 'src/componentes/modal'
import { ModalRef } from 'src/componentes/modal/tipos'
import { Navegacao } from 'src/componentes/navegacao'
import { SubTitulo } from 'src/componentes/subtitulo'
import {
  CalendarioUnform,
  CheckboxUnform,
  CpfInputUnform,
  SelectOpcao
} from 'src/componentes/unform'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { SelectUnform } from 'src/componentes/unform/select'
import { PesquisaCursoDTO } from 'src/dto'
import { ContainerDadosGrande } from 'src/paginas/professor/meu-cadastro/styles'
import { RotasAcademico } from 'src/rotas'
import { Api } from 'src/servicos'
import { TipoAcao } from 'src/servicos/api'
import {
  ModoPaginaCadastro,
  FormaPagamentoPorExtenso,
  FormaPagamentoCobranca,
  PlanoPagamentoParcela,
  ModalidadeCurso,
  PlanoPagamentoParcelas
} from 'src/tipos'
import {
  PrecificacaoItem,
  TiposPrecificacao,
  TiposPrecificacaoPorExtenso
} from 'src/tipos/precificacao'

import { ModalEditarTesteRef } from '../../cursos/cadastro/componentes/tipos'
import { ModalEditarParcela } from './componentes/modal-incluir-parcela'
import { schema } from './schema'
import {
  Container,
  ContainerMain,
  ContainerLink,
  Conteudo,
  ContainerAcoes,
  ContainerLateral,
  Linha,
  ContainerPequeno,
  ContainerDados,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerBotaoIcone,
  Secao,
  Tabela,
  Padding
} from './styles'
import { PaginaCadastroProps } from './tipos'

enum Navegacoes {
  DadosPrecificacao = 'dados-precificacao',
  PlanoPagamento = 'plano-pagamento',
  ValoresCurso = 'valores-curso',
  Cursos = 'cursos'
}

interface LocationState {
  campanha?: boolean
}

const CadastroPrecificacao: React.FC<PaginaCadastroProps> = ({
  match,
  modo
}) => {
  const location = useLocation<LocationState>()
  const { campanha } = location.state || {}

  const formRef = useRef<FormRef>(null)
  const modalRef = useRef<ModalRef>(null)
  const history = useHistory()
  const [titulo] = useState(
    `${
      modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'
    } de valores do curso`
  )
  const [pronto, definirPronto] = useState(false)
  const [erro, definirErro] = useState<string | undefined>(undefined)
  const [carregando, definirCarregando] = useState(false)
  const [precificacao, definirPrecificacao] = useState<
    PrecificacaoItem | undefined
  >(undefined)
  const [
    formaPagamentoEdicao,
    definirFormaPagamentoEdicao
  ] = useState<FormaPagamentoCobranca>(null)
  const [parcelas, definirParcelas] = useState<PlanoPagamentoParcela[]>([])
  const modalEditarParcelaRef = useRef<ModalEditarTesteRef>(null)
  const [valorParcela, setValorParcela] = useState('')
  const [quantidade, setQuantidadeParcelas] = useState('')
  const [
    cursosSelecionadosAlterado,
    definirCursosSelecionadosAlterado
  ] = useState<boolean>(false)
  const [cursosSelecionados, definirCursosSelecionados] = useState<string[]>([])
  const valorFormatado = formatarValorMoeda(valorParcela)
  const [cursos, definirCursos] = useState<PesquisaCursoDTO[]>([])
  const [tipoCampanha, definirTipoCampanha] = useState<TiposPrecificacao>(
    TiposPrecificacao.Precificacao
  )
  const [ehPrecificacaoBase, definirEhPrecificacaoBase] = useState(false)
  const [mostrarExtensao, definirMostrarExtensao] = useState(false)
  const [mostrarGraduacao, definirMostrarGraduacao] = useState(false)

  const dadosIniciais = useMemo(() => {
    return precificacao
  }, [precificacao])

  const setFormaPagamentoEdicao = async selecionado => {
    if (!selecionado) {
      await definirFormaPagamentoEdicao(null)
    } else {
      const { id } = selecionado
      await definirFormaPagamentoEdicao(FormaPagamentoCobranca[id])
    }
  }

  const carregarCursosPrecificacaoExistente = async (tipo: boolean) => {
    try {
      if (!tipo) {
        const cursos = await Api.RequisitarListaCursoOutros('')
        definirCursos(cursos ?? [])
      } else {
        const todosCursos = await Api.RequisitarCursosPorModalidade()

        definirCursos(todosCursos)
      }
    } catch {
      definirCursos([])
    }
  }

  const carregarCursos = async () => {
    try {
      if (tipoCampanha === TiposPrecificacao.Precificacao) {
        const cursos = await Api.RequisitarListaCursoOutros('')
        definirCursos(cursos ?? [])
      } else {
        const todosCursos = await Api.RequisitarCursosPorModalidade()

        if (mostrarExtensao) {
          const [cursosLivres, cursosEventos] = await Promise.all([
            Api.RequisitarCursosPorModalidade(ModalidadeCurso.Livre),
            Api.RequisitarCursosPorModalidade(ModalidadeCurso.Evento)
          ])

          const cursosCombinados = [
            ...(cursosLivres ?? []),
            ...(cursosEventos ?? [])
          ]

          definirCursos(cursosCombinados)
        } else if (mostrarGraduacao) {
          const cursos = await Api.RequisitarListaCursoOutros('')
          definirCursos(cursos ?? [])
        } else if (!mostrarExtensao && !mostrarGraduacao) {
          definirCursos(todosCursos)
        }
      }
    } catch {
      definirCursos([])
    }
  }

  const carregarPrecificacao = async () => {
    try {
      if (modo === ModoPaginaCadastro.Edicao) {
        definirPronto(false)

        await carregarCursosPrecificacaoExistente(campanha)

        const precificacaoParaEditar = await Api.ObterDadosPrecificacao(
          match.params.id
        )

        const parcelasUnicas = precificacaoParaEditar.planosPagamento.reduce(
          (acc, parcela) => {
            const chave = `${parcela.valorParcela}-${parcela.quantidade}-${parcela.formaPagamento}`

            if (!acc.has(chave)) {
              acc.set(chave, {
                id: parcela.id,
                valorParcela: parcela.valorParcela?.toString() || '',
                quantidade: parcela.quantidade?.toString() || '',
                formaPagamento: parcela.formaPagamento || '',
                cursoId: [parcela.cursoId]
              })
            } else {
              const parcelaExistente = acc.get(chave)
              if (!parcelaExistente.cursoId.includes(parcela.cursoId)) {
                parcelaExistente.cursoId.push(parcela.cursoId)
              }
            }

            return acc
          },
          new Map()
        )

        const parcelasFormatadas: PlanoPagamentoParcela[] = Array.from(
          parcelasUnicas.values()
        )

        definirEhPrecificacaoBase(precificacaoParaEditar.precificacaoBase)
        definirCursosSelecionados(precificacaoParaEditar.cursoId ?? [])
        definirPrecificacao({ ...precificacaoParaEditar })
        definirParcelas(parcelasFormatadas)
        definirTipoCampanha(precificacaoParaEditar.tipo)
      }
    } catch (error) {
      definirErro('Erro ao carregar pesquisa satisfação')
    } finally {
      definirPronto(true)
    }
  }

  const criarParcelamento = async (precificacaoId: string) => {
    const planoPagamentoParaCriar = cursosSelecionados.flatMap(c => {
      return parcelas.map(p => {
        return {
          ...p,
          cursoId: c,
          precificacaoId: precificacaoId
        }
      })
    })

    try {
      const respostas = await Promise.all(
        planoPagamentoParaCriar.map(parcela =>
          Api.SalvarPlanoPagamento({
            cursoId: parcela.cursoId,
            precificacaoId: parcela.precificacaoId,
            valorParcela: parcela.valorParcela.toString(),
            quantidadeParcelas: parcela.quantidade.toString(),
            formaPagamento: parcela.formaPagamento,
            precificacaoBase: precificacao.precificacaoBase
          })
        )
      )

      if (respostas.every(resposta => resposta)) {
        toast('Todos planos de pagamentos foram criados', {
          type: 'success'
        })
      }
    } catch (ex) {
      toast('Erro ao criar parcelamento', {
        type: 'error'
      })
    }
  }

  const adicionarParcela = async () => {
    if (!formaPagamentoEdicao || !quantidade || !valorFormatado) {
      toast(
        'Informe os dados: Forma de Pagamento, Valor da Parcela e Quantidade',
        {
          type: 'info'
        }
      )
      return
    }

    const novaParcela: PlanoPagamentoParcela = {
      quantidade: Number(quantidade),
      valorParcela: Number(valorFormatado),
      formaPagamento: formaPagamentoEdicao
    }

    definirParcelas(prevParcelas => [...prevParcelas, novaParcela])
  }

  const excluirParcela = async (parcela: PlanoPagamentoParcela) => {
    if (modo === ModoPaginaCadastro.Inclusao) {
      definirParcelas(prevParcelas => prevParcelas.filter(p => p !== parcela))
    } else {
      const novasParcelas = parcelas.filter(p => p !== parcela)

      try {
        await Api.EditarPlanoPagamento(
          parcela.cursoId,
          match.params.id,
          TipoAcao.Exclusao,
          [parcela]
        )

        definirParcelas(novasParcelas)
        toast.success('Plano Pagamento excluído com sucesso!')
      } catch (error) {
        toast.error(
          `Erro ao editar o plano de pagamento para o curso ${parcela.cursoId}: ${error}`
        )
      }
    }
  }

  const editarParcela = async (parcelaEditada: PlanoPagamentoParcelas) => {
    if (modo === ModoPaginaCadastro.Inclusao) {
      definirParcelas(prevParcelas =>
        prevParcelas.map(parcela =>
          parcela.cursoId === parcelaEditada.cursoId ? parcelaEditada : parcela
        )
      )
    } else {
      const novasParcelas = parcelas.map(parcela =>
        parcela.cursoId === parcelaEditada.cursoId ? parcelaEditada : parcela
      )

      try {
        await Api.EditarPlanoPagamento(
          parcelaEditada.cursoId,
          match.params.id,
          TipoAcao.Edicao,
          [parcelaEditada]
        )

        definirParcelas(novasParcelas)
      } catch (error) {
        toast.error(
          `Erro ao editar o plano de pagamento para o curso ${parcelaEditada.cursoId}: ${error}`
        )
      }
    }
  }

  const acaoSucesso = async (dados: PrecificacaoItem) => {
    try {
      definirCarregando(true)

      let dadosEnvio

      if (dados.tipo === TiposPrecificacao.Precificacao) {
        const planoPagamentoParaCriar = cursosSelecionados.flatMap(c => {
          return parcelas.map(p => {
            return {
              ...p,
              cursoId: c
            }
          })
        })

        dadosEnvio = {
          nome: dados.nome,
          codigo: dados.codigo,
          cpf: dados.cpf,
          dataInicio: dados.dataInicio,
          dataFim: dados.dataFim,
          parcelas: planoPagamentoParaCriar,
          cursoId: cursosSelecionados,
          precificacaoBase: dados.precificacaoBase
        }
      } else {
        dadosEnvio = {
          nome: dados.nome,
          codigo: dados.codigo,
          cpf: dados.cpf,
          dataInicio: dados.dataInicio,
          dataFim: dados.dataFim,
          cursosId: dados.cursoId,
          tipo: dados.tipo,
          valorBase: dados.valorBase,
          pctDesconto: dados.pctDesconto,
          precificacaoBase: dados.precificacaoBase
        }
      }

      if (modo === ModoPaginaCadastro.Inclusao) {
        const resposta = await Api.InserirPrecificacao(dadosEnvio)
        if (resposta.success) {
          await criarParcelamento(resposta.idPrecificacao)
        }
      } else {
        await Api.EditarPrecificacao(match.params.id, dadosEnvio)
      }

      toast(
        `Precificação ${
          modo === ModoPaginaCadastro.Inclusao ? 'cadastrada' : 'editada'
        } com sucesso`,
        {
          type: 'success'
        }
      )

      history.push(RotasAcademico.Precificacao)
    } catch (erro) {
      toast('Ops! Aconteceu um erro. Tente novamente.', { type: 'error' })
    } finally {
      definirCarregando(false)
    }
  }

  const alterarTipoPrecificacao = valor => {
    if (valor !== null) {
      definirTipoCampanha(valor.id)
    }
  }

  const opcoesCursos = useMemo(() => {
    return (
      cursos?.map(x => {
        return { id: x.id, texto: x.nome } as SelectOpcao
      }) ?? []
    )
  }, [cursos])

  const cursosAlterado = (valores: SelectOpcao[]) => {
    if (valores?.length > 0) {
      const novosCursosSelecionados = valores?.map(v => v.id)
      const cursosRemovidos = cursosSelecionados.filter(
        item => !novosCursosSelecionados.includes(item)
      )
      definirCursosSelecionados(novosCursosSelecionados)
      if (cursosRemovidos?.length > 0) {
        definirCursosSelecionadosAlterado(true)
      }
    } else {
      definirCursosSelecionados([])
      definirCursosSelecionadosAlterado(true)
    }
  }

  useEffect(() => {
    carregarPrecificacao()
  }, [])

  useEffect(() => {
    if (tipoCampanha && modo === ModoPaginaCadastro.Inclusao) {
      carregarCursos()
    }
  }, [tipoCampanha, mostrarExtensao, mostrarGraduacao])

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasAcademico.Precificacao}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Financeiro'
                  },
                  {
                    texto: 'Precificação',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormUnform
                ref={formRef}
                schema={schema}
                dadosIniciais={dadosIniciais}
                acaoSucesso={acaoSucesso}
                acaoFalha={focarPrimeiroCampoComErro}
              >
                <Secao id={Navegacoes.DadosPrecificacao}>
                  <SubTitulo texto="Dados da Precificação" />
                  <Linha>
                    <ContainerDadosGrande>
                      <InputUnform
                        id="ipt_nome"
                        name="nome"
                        label="Nome da precificação"
                        obrigatorio
                      />
                    </ContainerDadosGrande>
                    <ContainerDados>
                      <CheckboxUnform
                        id="ipt_precificacao_base"
                        name="precificacaoBase"
                        texto="Precificação Base"
                        tamanho="L"
                        checked={ehPrecificacaoBase}
                        onChange={e =>
                          definirEhPrecificacaoBase(e.target.checked)
                        }
                      />
                    </ContainerDados>
                  </Linha>
                  <Linha>
                    <ContainerPequeno>
                      <InputUnform
                        id="ipt_codigo"
                        name="codigo"
                        label="Código da precificação"
                        disabled={modo === ModoPaginaCadastro.Edicao}
                        obrigatorio
                      />
                    </ContainerPequeno>
                    <ContainerPequeno>
                      <CpfInputUnform id="ipt_cpf" name="cpf" label="CPF" />
                    </ContainerPequeno>
                    {!ehPrecificacaoBase ? (
                      <>
                        <ContainerPequeno>
                          <CalendarioUnform
                            className="sm"
                            id="ipt_data_inicio"
                            name="dataInicio"
                            label="Data de Início"
                            obrigatorio
                          />
                        </ContainerPequeno>
                        <ContainerPequeno>
                          <CalendarioUnform
                            className="sm"
                            id="ipt_data_fim"
                            name="dataFim"
                            label="Data de Fim"
                            obrigatorio
                          />
                        </ContainerPequeno>
                      </>
                    ) : (
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="slct_tipoPrecificacao"
                          name="tipo"
                          label="Tipo de Precificacao"
                          placeholder="Selecione"
                          opcoes={[
                            {
                              id:
                                TiposPrecificacao[
                                  TiposPrecificacao.Precificacao
                                ],
                              texto: TiposPrecificacaoPorExtenso.Precificacao
                            },
                            {
                              id: TiposPrecificacao[TiposPrecificacao.Campanha],
                              texto: TiposPrecificacaoPorExtenso.Campanha
                            }
                          ]}
                          iconeFechar={false}
                          disabled={modo === ModoPaginaCadastro.Edicao}
                          valorAlterado={alterarTipoPrecificacao}
                        />
                      </ContainerDadosMedio>
                    )}
                  </Linha>
                </Secao>
                {tipoCampanha === TiposPrecificacao.Campanha && (
                  <Secao
                    id={Navegacoes.ValoresCurso}
                    style={{ marginTop: '0' }}
                  >
                    <SubTitulo texto="Valor dos Cursos" />
                  </Secao>
                )}
                <ContainerDados>
                  {!ehPrecificacaoBase && (
                    <ContainerDadosMedio>
                      <SelectUnform
                        id="slct_tipoPrecificacao"
                        name="tipo"
                        label="Tipo de Precificacao"
                        placeholder="Selecione"
                        opcoes={[
                          {
                            id:
                              TiposPrecificacao[TiposPrecificacao.Precificacao],
                            texto: TiposPrecificacaoPorExtenso.Precificacao
                          },
                          {
                            id: TiposPrecificacao[TiposPrecificacao.Campanha],
                            texto: TiposPrecificacaoPorExtenso.Campanha
                          }
                        ]}
                        iconeFechar={false}
                        disabled={modo === ModoPaginaCadastro.Edicao}
                        valorAlterado={alterarTipoPrecificacao}
                      />
                    </ContainerDadosMedio>
                  )}
                  {tipoCampanha === TiposPrecificacao.Campanha && (
                    <>
                      {ehPrecificacaoBase ? (
                        <ContainerDadosPequeno>
                          <MoedaInputUnform
                            id="ipt_valor_base"
                            name="valorBase"
                            label="Valor Base"
                            obrigatorio
                          />
                        </ContainerDadosPequeno>
                      ) : (
                        <ContainerDadosPequeno>
                          <InputUnform
                            id="ipt_pct_desconto"
                            name="pctDesconto"
                            label="Porcentagem de desconto"
                            obrigatorio
                          />
                        </ContainerDadosPequeno>
                      )}
                    </>
                  )}
                </ContainerDados>
                {tipoCampanha === TiposPrecificacao.Precificacao && (
                  <Secao
                    id={Navegacoes.PlanoPagamento}
                    style={{ marginTop: '0' }}
                  >
                    <SubTitulo texto="Planos de Pagamento" />
                    <ContainerDados>
                      <ContainerDadosMedio>
                        <SelectUnform
                          id="slct_formPagamentoParcela"
                          name="formaPagamento"
                          label="Forma de Pagamento"
                          placeholder="Selecione"
                          valorAlterado={setFormaPagamentoEdicao}
                          opcoes={[
                            {
                              id:
                                FormaPagamentoCobranca[
                                  FormaPagamentoCobranca.Boleto
                                ],
                              texto: 'Boleto'
                            },
                            {
                              id:
                                FormaPagamentoCobranca[
                                  FormaPagamentoCobranca.CartaoCredito
                                ],
                              texto: 'Cartão de crédito'
                            },
                            {
                              id:
                                FormaPagamentoCobranca[
                                  FormaPagamentoCobranca.Recorrente
                                ],
                              texto: 'Recorrente'
                            }
                          ]}
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosPequeno>
                        <MoedaInputUnform
                          name="valorParcela"
                          label="Valor da parcela"
                          onChange={e => setValorParcela(e.target.value)}
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosPequeno>
                        <NumeroInputUnform
                          name="quantidade"
                          max={9999}
                          maxLength={4}
                          label="Quantidade de parcelas"
                          onChange={e => setQuantidadeParcelas(e.target.value)}
                        />
                        <Botao
                          texto="Adicionar parcelamento"
                          tema="Secundario"
                          type="button"
                          style={{ margin: '0 0 0 auto' }}
                          onClick={() => adicionarParcela()}
                        />
                      </ContainerDadosPequeno>
                    </ContainerDados>
                    <Tabela>
                      <thead>
                        <tr>
                          <th>Quantidade de parcelas</th>
                          <th>Valor</th>
                          <th>Forma de Pagamento</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {parcelas?.map((parcela, index) => (
                          <tr key={index}>
                            <td align="center">{parcela.quantidade}</td>
                            <td align="center">R$ {parcela.valorParcela}</td>
                            <td align="center">
                              {FormaPagamentoPorExtenso[parcela.formaPagamento]}{' '}
                            </td>
                            <td align="center">
                              <ContainerBotaoIcone>
                                <button
                                  type="button"
                                  data-tip="Editar parcela"
                                  data-for={'tooltip-editar-parcela'}
                                  onClick={() => {
                                    modalEditarParcelaRef?.current?.abrir(
                                      parcela
                                    )
                                  }}
                                >
                                  {IconeEditar}
                                </button>
                                <Tooltip
                                  id={'tooltip-editar-parcela'}
                                  place="bottom"
                                  offset={{ top: 10 }}
                                />
                              </ContainerBotaoIcone>
                            </td>
                            <td align="center">
                              <ContainerBotaoIcone>
                                <button
                                  type="button"
                                  data-tip="Excluir parcela"
                                  data-for={'tooltip-lixeira-parcela'}
                                  onClick={() => excluirParcela(parcela)}
                                >
                                  {IconeLixeira}
                                </button>
                                <Tooltip
                                  id={'tooltip-lixeira-parcela'}
                                  place="bottom"
                                  offset={{ top: 10 }}
                                />
                              </ContainerBotaoIcone>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Tabela>
                  </Secao>
                )}
                <Secao id={Navegacoes.Cursos}>
                  <SelectUnform
                    id="cursoId"
                    name="cursoId"
                    label="Cursos"
                    placeholder="Selecione"
                    opcoes={opcoesCursos}
                    multiplo={true}
                    valorAlteradoMulti={cursosAlterado}
                  />
                  <Padding />
                  {tipoCampanha === TiposPrecificacao.Campanha && (
                    <Linha>
                      <CheckboxUnform
                        id="ipt_somente_extensao"
                        name="somenteExtensao"
                        texto="Mostrar cursos de Extensão"
                        tamanho="L"
                        checked={mostrarExtensao}
                        onChange={e => {
                          definirMostrarExtensao(e.target.checked)
                          definirMostrarGraduacao(false)
                        }}
                      />

                      <CheckboxUnform
                        id="ipt_somente_pos"
                        name="somentePos"
                        texto="Mostrar cursos de Pós-graduação"
                        tamanho="L"
                        checked={mostrarGraduacao}
                        onChange={e => {
                          definirMostrarGraduacao(e.target.checked)
                          definirMostrarExtensao(false)
                        }}
                      />
                    </Linha>
                  )}
                  <Padding />
                </Secao>
                <ContainerAcoes>
                  <Botao
                    texto="Cancelar"
                    tema="Secundario"
                    type="button"
                    disabled={carregando}
                    onClick={() => modalRef?.current?.abrir()}
                  />
                  <Botao type="submit" texto="Salvar" carregando={carregando} />
                </ContainerAcoes>
              </FormUnform>
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosPrecificacao,
                  nome: 'Dados da Precificação'
                },
                ...(tipoCampanha !== TiposPrecificacao.Campanha
                  ? [
                      {
                        link: Navegacoes.PlanoPagamento,
                        nome: 'Planos de Pagamento'
                      }
                    ]
                  : []),
                ...(tipoCampanha === TiposPrecificacao.Campanha
                  ? [
                      {
                        link: Navegacoes.ValoresCurso,
                        nome: 'Valor dos Cursos'
                      }
                    ]
                  : []),
                {
                  link: Navegacoes.Cursos,
                  nome: 'Cursos'
                }
              ]}
            />
          </ContainerLateral>
          <Modal
            ref={modalRef}
            backdrop
            id="modal-confirmacao-cancelamento"
            icone={<></>}
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
          <ModalEditarParcela
            ref={modalEditarParcelaRef}
            backdrop
            id="modal-inclur-parcela"
            acaoPrimaria={editarParcela}
          />
        </Container>
      ) : !pronto && !erro ? (
        <Carregando texto="Carregando Precificação ..." />
      ) : (
        <Conteudo>
          <ContainerErroLayout>
            <div>
              {IconeAtencao}
              <p>{erro}</p>
              <div>
                <Botao
                  tema="Padrao"
                  texto="Tentar novamente"
                  onClick={carregarPrecificacao}
                  tamanho="S"
                />
              </div>
            </div>
          </ContainerErroLayout>
        </Conteudo>
      )}
    </>
  )
}

export default withRouter(CadastroPrecificacao)
