import styled, { css } from 'styled-components'

import { Cores } from '../styles/cores'
import { BarraProps, RadioTamanhos, RadioTema } from './tipos'

export interface ContainerProps {
  tema: RadioTema
  tamanho: RadioTamanhos | string
  estilizarTexto?: boolean
  readOnly: boolean
}

export interface ContainerExplicacaoProps {
  tema: RadioTema
}

const Padrao = (tema: RadioTema) => css`
  & input:disabled ~ label .seleciona-radio {
    background: ${Cores.CINZA_3_CLARO};
    border: 1px solid ${Cores.CINZA_1_CLARO};
    opacity: 0.36;
    cursor: not-allowed;
  }

  & input:checked ~ label {
    .seleciona-radio {
      background: ${Cores.BRANCO};
      border: 1px solid ${Cores.CINZA_1_CLARO};
      transition: 0.15s;

      & > .icone-radio {
        background-color: ${tema === 'Padrao'
          ? Cores.SECUNDARIA_3_ESCURO
          : Cores.PERIGO_ERRO};
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
      }

      &:hover {
        background: ${Cores.CINZA_3_MAIS_CLARO};
      }
    }
  }

  .seleciona-radio:hover {
    background: ${Cores.CINZA_3_MAIS_CLARO};
    transition: 0.15s;

    .icone-radio {
      background: ${Cores.CINZA_3_MAIS_CLARO};
    }
  }
`

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
    `}

  & input {
    margin: 0;
    display: none;
  }

  label {
    position: relative;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: -0.02em;

    color: ${props =>
      props.estilizarTexto
        ? props.tema === 'Padrao'
          ? Cores.SECUNDARIA_3_ESCURO
          : Cores.PERIGO_ERRO
        : Cores.CINZA_2};
  }

  ${props => Padrao(props.tema)}

  .seleciona-radio {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px ${Cores.CINZA_1_CLARO};
    border-radius: 50%;
    margin-right: 8px;
    background: ${Cores.BRANCO};
    transition: 0.15s;

    .icone-radio {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: ${Cores.BRANCO};
    }

    ${({ tamanho }) => {
      switch (tamanho) {
        case 'S':
          return css`
            width: 24px;
            height: 24px;

            .icone-radio {
              width: 13px;
              height: 13px;
            }
          `
        case 'M':
          return css`
            width: 27px;
            height: 27px;

            .icone-radio {
              width: 15px;
              height: 15px;
            }
          `
        default:
          return css`
            width: 30px;
            height: 30px;

            .icone-radio {
              width: 17px;
              height: 17px;
            }
          `
      }
    }}
  }
`
