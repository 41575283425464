import { Cores } from 'src/componentes/styles'
import { FormUnform } from 'src/componentes/unform/form'
import styled from 'styled-components'

export const Form = styled(FormUnform)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

export const Campo = styled.div`
  flex: 1;
  margin-right: 10px;
`

export const Dados = styled.div`
  margin-top: 25px;
`

export const Tabela = styled.table`
  margin: 20px 0 27px 0;
  font-family: 'Saira', sans-serif;
  font-size: 12px;
  color: #191414;
  border-collapse: collapse;
  border: 1px solid ${Cores.CINZA_3_CLARO};
  width: 100%;

  th {
    background: ${Cores.CINZA_HEAD_TABLE};
    color: ${Cores.PRETO};
    font-family: 'Saira-Semi-Bold', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    border-bottom: none;
    text-transform: uppercase;
  }

  th:first-child {
    border-top-left-radius: 8px;
  }

  th:last-child {
    border-top-right-radius: 8px;
  }

  tbody {
    line-height: 125%;

    tr:first-child {
      border-top: 2px solid ${Cores.CINZA_3_CLARO};
    }

    tr {
      height: 48px;
      border: 1px solid ${Cores.FUNDO};
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid ${Cores.FUNDO};
      }

      &.par {
        background: ${Cores.FUNDO};
      }

      &.marcado {
        background: #afc2ed;
        border: 1px solid ${Cores.PRIMARIA_2_ESCURO};
      }

      &.comMarcacao {
        cursor: pointer;
      }

      td,
      th {
        padding: 0 12px;
      }

      &:hover {
        border: 1px solid ${Cores.SECUNDARIA_1};
      }
    }
  }
`
